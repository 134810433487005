import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Container,
  Badge,
  Grid,
  Text,
  Heading,
  Stack,
  Avatar,
  Button,
  useColorModeValue,
  Input,
  Select,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
  Textarea,
  Spinner,
  Flex,
} from "@chakra-ui/react";
import { BsStar, BsStarFill, BsStarHalf } from "react-icons/bs";
import { FiShoppingCart } from "react-icons/fi";
import { getNotifications } from "../../services/api.service";
import { applyTask } from "../../services/auth.service";
import { currencyFormat, dateFormat } from "../../utils/helper";
import { MdNotifications } from "react-icons/md";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import styled from "styled-components";
import { useLocation } from "react-router-dom";




// ts
interface TimeDisplayValuesType {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
  }
  
  interface CounterType {
    displayValue: number;
    label: string;
  }
  
  // styled components
  document.documentElement.style.setProperty("--color-bg", "#22262E");
  document.documentElement.style.setProperty("--color-heading", "#798EB0");
  document.documentElement.style.setProperty("--color-counter", "#B7791F");
  document.documentElement.style.setProperty("--font-family-heading", "Mukta");

  
  const CounterStyled = styled.div`
    background: rgba(255, 255, 255, 0.025);
    border-radius: 1rem;
    color: var(--color-counter);
    display: flex;
    flex-direction: column;
    font-family: "JetBrains Mono", mono;
    font-size: clamp(1rem, 8vw, 99rem);
    font-weight: 100;
    line-height: 1;
    padding: 2vw
    text-align: center;

    h2 {
      color: var(--color-heading);
      font-family: var(--font-family-heading);
      font-size: clamp(1rem, 2vw, 99rem);
      font-weight: 300;
      letter-spacing: .1875em;
      margin: 1.25rem 0 0;
      order: 1;
      overflow: hidden
      text-overflow: ellipsis;
      text-transform: uppercase;
      white-space: nowrap;
      width: 100%;
    }
  `;
  


export default function TaskTimer() {
  const toast = useToast();
  const location = useLocation()
  const { jobDetails } = location.state


  const [activeJob, setActiveJob] = useState<{id: number; artisan_id: string;  title: string; brief: string; owner_name: string; contact: string; address: string; task_date: string; task_time: string; contract_start_date: string; contract_start_time: string; contract_end_time: string; status: string}>({
    id: 0,
    artisan_id: '',
    title: '',
    brief: '',
    owner_name: '',
    contact: '',
    address: '',
    task_date: '',
    task_time: '',
    contract_start_date: '',
    contract_start_time: '',
    contract_end_time: '',
    status: '',
  });


  useEffect(()=>{
    setActiveJob(jobDetails);
  },[activeJob])


  // timing
  const year = new Date().getFullYear();
//   const year = new Date().getFullYear() + 1;
  const targetDate = new Date(`${dateFormat(jobDetails.task_date)} ${jobDetails.contract_end_time}`).getTime();
//   const targetDate = new Date(`May 10, ${nextYear} ${activeJob.contract_start_time.split("P")[0]}`).getTime();
  


  const [contractEnd, setContractEnd] = useState(false);


  const generateTimeDisplay = (): TimeDisplayValuesType => {
    const rightJustNow = new Date().getTime();
    const runway = targetDate - rightJustNow;
    if(rightJustNow>targetDate){
      setContractEnd(true);
    }
    const stateObj = {
      days: Math.floor(runway / (1000 * 60 * 60 * 24)),
      hours: Math.floor((runway % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
      minutes: Math.floor((runway % (1000 * 60 * 60)) / (1000 * 60)),
      seconds: Math.floor((runway % (1000 * 60)) / 1000)
    };

    if(stateObj.days.toString() ==  'NaN'){
      setContractEnd(true);
    }
    return stateObj;
  };
  
  // components
  const Counter = ({ displayValue, label }: CounterType) => (
    <CounterStyled>
      <h2>{label}</h2>
      {displayValue}
    </CounterStyled>
  );


  const [timeDisplay, setTimeDisplay] = React.useState<TimeDisplayValuesType>(
    generateTimeDisplay
  );

  const updateCounters = () => setTimeDisplay(generateTimeDisplay);

  React.useEffect(() => {
    setInterval(() => setTimeDisplay(generateTimeDisplay), 1000);
  }, []);
  

  return (
    <Container maxW={"12xl"}>
      <Box>
        <Stack spacing={0} align={"center"} mb={10}>
          <Heading textAlign={"center"}>Task Timer: {activeJob.title}</Heading>
          { !contractEnd ?
          <Grid
          w="sm"
          alignItems="center"
          justifyContent="center"
          templateColumns={{
            base: "1fr 1fr 1fr",
            md: "1fr 1fr 1fr",
            // xl: "1fr 1fr 1fr 1fr",
          }}
          gap={6}
          m={6}
          mt={20}
        >
                <Counter displayValue={timeDisplay.hours} label={"Hours"} />
                <Counter displayValue={timeDisplay.minutes} label={"Minutes"} />
                <Counter displayValue={timeDisplay.seconds} label={"Seconds"} />
          </Grid>
          :
          <Heading
          color={'yellow.600'}
          m={10}
          >Task Contract Ended</Heading>
          }
          <Box
                  bg="white"
                  _dark={{ bg: "gray.800" }}
                  rounded="lg"
                  shadow="lg"
                  position="relative"
                  m={1}
                  p={6}
                >
                <Text m={2} ml={5} fontWeight={'bold'}>Task Details</Text>
                <Text m={2} ml={5}>{activeJob.brief}</Text>
                <Text m={2} ml={5}>OWNER: {activeJob.owner_name}</Text>
                <Text m={2} ml={5}>ADDRESS: {activeJob.address}</Text>
                <Text m={2} ml={5}>PHONE NUMBER: {activeJob.contact}</Text>
                <Text m={2} ml={5}>TASK DATE: {dateFormat(activeJob.task_date)}</Text>
                <Text m={2} ml={5}>TASK TIME: {activeJob.task_time} GMT+1</Text>
                <Text m={2} ml={5}>TASK DURATION: {activeJob['approx_duration']} Hours</Text>
                <Text m={2} ml={5}>CONTRACT ACCEPTED: {activeJob.contract_start_time} {dateFormat(activeJob.contract_start_date)}</Text>
                
          </Box>
        </Stack>
      </Box>
    </Container>
  );
}
