import {
    Container,
    SimpleGrid,
    Image,
    Flex,
    Heading,
    Text,
    Stack,
    StackDivider,
    Icon,
    useColorModeValue,
    Box,
    Grid,
    GridItem,
  } from "@chakra-ui/react";
  import { IoMdAnalytics, IoIosPerson, IoIosBusiness } from "react-icons/io";
  import { ReactElement } from "react";
  
  import Assem from '../../assets/assembly.png';
  
  interface FeatureProps {
    text: string;
    iconBg: string;
    icon?: ReactElement;
    detail: string;
  }
  
  const Feature = ({ text, icon, iconBg, detail }: FeatureProps) => {
    return (
      <Box>
        <Stack direction={"row"} align={"center"}>
          <Flex
            w={8}
            h={8}
            align={"center"}
            justify={"center"}
            rounded={"full"}
            bg={iconBg}
          >
            {icon}
          </Flex>
          <Text fontWeight={600}>{text}</Text>
        </Stack>
        <Text color={"gray.500"} mt={2} align={"left"} fontSize={"lg"}>
         {detail}
        </Text>
      </Box>
    );
  };
  
  export default function Standards() {
    return (
    
    <Box
          bg={useColorModeValue("yellow.50", "black")}
          mt={20}
          p={5}
          >
      <Container maxW={"7xl"}>
      <Box m={{ md: 20 }}>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
          <Stack align={"flex-start"} spacing={4}>
            <Text
              textTransform={"uppercase"}
              color={useColorModeValue("black", "yellow.400")}
              fontWeight={600}
              fontSize={"sm"}
              bg={useColorModeValue("yellow.100", "yellow.900")}
              p={2}
              alignSelf={"flex-start"}
              rounded={"md"}
            >
              Our Standards
            </Text>
            <Heading>Verified and Insured Artisans</Heading>
            <Text color={"gray.500"} align={"left"} fontSize={"lg"}>
            Choosing verified and insured artisans is a smart investment for clients, as it not only guarantees quality work but also safeguards against potential liabilities.
            </Text>
            <Stack
              spacing={4}
              divider={
                <StackDivider
                  borderColor={useColorModeValue("gray.100", "gray.700")}
                />
              }
            >
              <Feature
                icon={
                  <Icon as={IoIosBusiness} color={"yellow.500"} w={5} h={5} />
                }
                iconBg={useColorModeValue("yellow.100", "yellow.900")}
                text={"Professional"}
                detail={"Crafting excellence with precision, our professionals bring skill, reliability, and peace of mind to every job. "}
              />
              <Feature
                icon={<Icon as={IoIosPerson} color={"green.500"} w={5} h={5} />}
                iconBg={useColorModeValue("green.100", "green.900")}
                text={"ID Verified"}
                detail={"Elevate your projects with our verified and insured artisans."}
              />
              <Feature
                icon={
                  <Icon as={IoMdAnalytics} color={"purple.500"} w={5} h={5} />
                }
                iconBg={useColorModeValue("purple.100", "purple.900")}
                text={"Customer First"}
                detail={"Your satisfaction is our priority. Experience service with a personal touch, tailored to exceed your expectations."}
              />
            </Stack>
          </Stack>
          <Flex>
            <Image
              rounded={"md"}
              alt={"feature image"}
              src={Assem}
              objectFit={"cover"}
            />
          </Flex>
        </SimpleGrid>
      </Box>
      <Container py={5} mt={30} maxW={'container.lg'}>
        <Grid
          templateColumns={{
            base: 'repeat(1, 1fr)',
            sm: 'repeat(2, 1fr)',
            md: 'repeat(4, 1fr)',
          }}
          gap={6}>
          <GridItem w="100%" colSpan={{ base: 1, sm: 2, md: 2 }}>
            <Heading as={'h2'}>Our Customer Base</Heading>
          </GridItem>
          <GridItem w="100%">
            <Flex flexDirection={'column'}>
              <Text fontSize={'4xl'} fontWeight={'bold'}>
                2000+
              </Text>
              <Text fontSize={'2xl'} fontWeight={'bold'}>
                Customers
              </Text>
              <Box fontSize={'sm'}>
                Description for the number. People always pay attention to numbers.
              </Box>
            </Flex>
          </GridItem>
          <GridItem w="100%">
            <Flex flexDirection={'column'}>
              <Text fontSize={'4xl'} fontWeight={'bold'}>
                100%
              </Text>
              <Text fontSize={'2xl'} fontWeight={'bold'}>
                Satistfaction
              </Text>
              <Box fontSize={'sm'}>
                Description for the number. People always pay attention to numbers.
              </Box>
            </Flex>
          </GridItem>
        </Grid>
      </Container>
      </Container>
      </Box>
    );
  }
  