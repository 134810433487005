import {
  Box,
  Button,
  Card,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Progress,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Textarea,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useDisclosure,
  Spinner,
  useToast
} from "@chakra-ui/react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
// Custom components
// import Card from 'components/card/Card';
// import Menu from 'components/menu/MainMenu';
import * as React from "react";
import { useEffect, useState } from "react";
// Assets
import { MdCancel, MdCheckCircle, MdErrorOutline, MdInfoOutline } from "react-icons/md";
import { getApplication } from "../../services/api.service";

import { acceptOffer, rejectOffer } from "../../services/auth.service";
import { dateFormat } from "../../utils/helper";

type RowObj = {
  title: string;
  appid: string;
  date: string;
  applystatus: string;
};

const columnHelper = createColumnHelper<RowObj>();

// const columns = columnsDataCheck;
export default function ComplexTable(props: { tableData: any }) {

  const toast = useToast();
  
  const { tableData } = props;
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const [loading, setLoading] = useState(false);
  let defaultData = tableData;
  const columns = [
    columnHelper.accessor("title", {
      id: "name",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          TITLE
        </Text>
      ),
      cell: (info: any) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor("applystatus", {
      id: "applystatus",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
          display={{ base: "none", md: "block" }}
        >
          STATUS
        </Text>
      ),
      cell: (info) => (
        <Flex align="center" display={{ base: "none", md: "block" }}>
          <Icon
            w="24px"
            h="24px"
            me="5px"
            color={
              info.getValue() === "Completed"
                ? "green.500"
                : info.getValue() === "New"
                ? "green.500"
                : info.getValue() === "Rejected"
                ? "red.500"
                : "orange.500"
            }
            as={
              info.getValue() === "Completed"
                ? MdCheckCircle
                : info.getValue() === "New"
                ? MdInfoOutline
                : info.getValue() === "Rejected"
                ? MdCancel
                : MdErrorOutline
            }
          />
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info.getValue() === "Rejected" ? "Not Approved" : info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor("date", {
      id: "date",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
          display={{ base: "block", md: "block" }}
        >
          DATE
        </Text>
      ),
      cell: (info) => (
        <Text
          color={textColor}
          fontSize="sm"
          fontWeight="700"
          display={{ base: "block", md: "block" }}
        >
          {dateFormat(info.getValue())}
        </Text>
      ),
    }),
    // columnHelper.accessor("progress", {
    //   id: "progress",
    //   header: () => (
    //     <Text
    //       justifyContent="space-between"
    //       align="center"
    //       fontSize={{ sm: "10px", lg: "12px" }}
    //       color="gray.400"
    //     >
    //       PROGRESS
    //     </Text>
    //   ),
    //   cell: (info) => (
    //     <Flex align="center">
    //       <Progress
    //         hasStripe
    //         variant="table"
    //         h="8px"
    //         w="108px"
    //         value={info.getValue()}
    //       />
    //     </Flex>
    //   ),
    // }),
    columnHelper.accessor("appid", {
      id: "appid",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          ACTION
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Button
            fontSize={"sm"}
            rounded={"full"}
            bg={"yellow.600"}
            color={"white"}
            _hover={{
              bg: "yellow.500",
            }}
            _focus={{
              bg: "yellow.500",
            }}
            onClick={() => {
              setActiveApplication(info.getValue());
              getApplication(info.getValue()).then(
                (response) => {
                  setApplication(response.data["application"]);
                },
                (error) => {
                  console.log(error.message);
                }
              );
              onOpen();
            }}
          >
            { application.applystatus == 'Contract' ? 'Sign Contract' : 'View' }
          </Button>
        </Flex>
      ),
    }),
  ];
  const [data, setData] = React.useState(() => [...defaultData]);
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [activeApplication, setActiveApplication] = useState("");

  const [application, setApplication] = useState<{
    id: number;
    address: string;
    approx_duration: string;
    a_id: string;
    artisan_name: string;
    artisan_message: string;
    artisan_status: string;
    brief: string;
    city: string;
    contact: string;
    contract_end_date: string;
    contract_end_time: string;
    contract_start_date: string;
    contract_start_time: string;
    date: string;
    date_closed: string;
    date_posted: string;
    decision: string;
    job_id: string;
    media: string;
    owner_id: string;
    owner_name: string;
    rate_p_hr: string;
    rate_per_hr: string;
    service_type: string;
    state: string;
    applystatus: string;
    status: string;
    task_date: string;
    task_time: string;
    time: string;
    title: string;
  }>({
    address: "",
    approx_duration: "",
    a_id: "",
    artisan_name: "",
    artisan_message: "",
    artisan_status: "",
    brief: "",
    city: "",
    contact: "",
    contract_end_date: "",
    contract_end_time: "",
    contract_start_date: "",
    contract_start_time: "",
    date: "",
    date_closed: "",
    date_posted: "",
    decision: "",
    id: 0,
    job_id: "",
    media: "",
    owner_id: "",
    owner_name: "",
    rate_p_hr: "",
    rate_per_hr: "",
    service_type: "",
    state: "",
    applystatus: "",
    status: "",
    task_date: "",
    task_time: "",
    time: "",
    title: "",
  });


  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);

  const [openContract, setOpenContract] = useState(false);
  const [openDispute, setOpenDispute] = useState(false);
  const [scrollBehavior, setScrollBehavior] = useState("inside");

  const handleAccept = (job_id: number, artisan_id: string) => {
    setLoading(true);
    acceptOffer(job_id, artisan_id).then(
      (response) => {
        if(response.success){
          setLoading(false);
        toast({
          title: 'Submitted!',
          description: `${response.message}`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        setTimeout(function () {
          window.location.href = "/jobs";
      }, 3000);
      }else{
        setLoading(false);
        toast({
          title: 'Unable to Submit Contract',
          description: `${response.message}`,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
      },
      (error) => {
        setLoading(false);
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  };


  const handleReject = (job_id: number, artisan_id: string) => {
    setLoading(true);
    rejectOffer(job_id, artisan_id).then(
      (response) => {
        if(response.success){
          setLoading(false);
        toast({
          title: 'Submitted!',
          description: `${response.message}`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        setTimeout(function () {
          window.location.href = "/jobs";
      }, 3000);
      }else{
        setLoading(false);
        toast({
          title: 'Unable to Submit Contract',
          description: `${response.message}`,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
      },
      (error) => {
        setLoading(false);
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  };

  return (
    <>
      {/* Contract Modal */}
      <Modal
        onClose={onClose}
        isOpen={openContract}
        // scrollBehavior={scrollBehavior}
        size={"xl"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader mt={10}>Contract for {application.title}</ModalHeader>
          <ModalCloseButton
            onClick={() => {
              onClose();
              setOpenContract(false);
            }}
          />
          <ModalBody>
            <Box textAlign={"center"}>
              <Text my={4}>This contract is between</Text>
              <Text my={4} fontWeight={600}>
                {application.owner_name}
              </Text>
              <Text my={4}>and Sapioartisan with ID:</Text>
              <Text my={4} fontWeight={600}>
                {application.artisan_name}
              </Text>
              <Text my={10}>
                This Agreement ("Agreement") is entered into as of [{dateFormat(Date())}], by
                and between [{application.owner_name}], hereinafter referred to as the
                "Client," and [{application.artisan_name}], hereinafter referred
                to as the "Artisan."
              </Text>
            </Box>
            <Box>
              <Text my={3} fontWeight={600}>1. Scope of Work</Text>

              <Text my={2}>
                The Artisan agrees to provide the following services to the
                Client:
              </Text>

              <Text my={3} fontWeight={600}>2. Payment Terms</Text>

              <Text my={2}>
                a. The Client agrees to be deducted the Artisan fee for
                the services rendered from their wallet.
              </Text>


              <Text my={3} fontWeight={600}>3. Timeline</Text>

              <Text my={2}>
                a. Any delays caused by the Artisan shall be communicated
                promptly to the Client.
              </Text>

              <Text my={3} fontWeight={600}>4. Intellectual Property</Text>

              <Text my={2}>
                a. The Artisan retains the right to showcase the work in their
                portfolio, unless otherwise agreed upon in writing.
              </Text>

              <Text my={3} fontWeight={600}>5. Confidentiality</Text>

              <Text my={2}>
                a. The Artisan agrees to keep confidential any proprietary or
                sensitive information obtained during the course of the project.
              </Text>

              <Text my={2}>
                b. Both parties agree not to disclose any confidential
                information to third parties.
              </Text>

              <Text my={3} fontWeight={600}>6. Dispute Resolution</Text>

              <Text my={2}>
                Any disputes arising out of or relating to this Agreement shall
                be resolved through arbitration in accordance with the rules of
                [SapioWorks Inc.].
              </Text>

              <Text my={3} fontWeight={600}>7. Entire Agreement</Text>

              <Text my={2}>
                This Agreement constitutes the entire understanding between the
                parties and supersedes all prior discussions and agreements.
              </Text>

              <Text my={2}>
                IN WITNESS WHEREOF, the parties hereto have executed this
                Agreement as of the Effective Date.
              </Text>

              <Text my={2}>[{application.owner_name}]</Text>

              <Text my={2}>[{application.artisan_name}]</Text>

              <Text my={2}>Date: This contract is dated:{" "}
                {dateFormat(application.contract_start_date)}{" "}
                and due to end by{" "}
                {dateFormat(application.contract_end_date)}{" "}
              </Text>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={() => {
                onClose();
                setOpenContract(false);
              }}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Dispure Modal */}
      <Modal
            initialFocusRef={initialRef}
            finalFocusRef={finalRef}
            isOpen={openDispute}
            onClose={onClose}
          >
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Report Dispute</ModalHeader>
              <ModalCloseButton onClick={()=>{
                  onClose();
                  setOpenDispute(false);
                  }} />
              <ModalBody pb={6}>
                <FormControl>
                  <FormLabel>Brief Title</FormLabel>
                  <Input ref={initialRef} placeholder='Brief Title' />
                </FormControl>

                <FormControl mt={4}>
                  <FormLabel>Comments in Detail </FormLabel>
                  <Textarea placeholder='Write your comments (Decision is subject to our dispute resolution team review)'  />
                </FormControl>
              </ModalBody>

              <ModalFooter>
                <Button colorScheme='yellow' mr={3}>
                  Submit
                </Button>
                <Button onClick={
                  ()=>{
                  onClose();
                  setOpenDispute(false);
                  }
                }>Cancel</Button>
              </ModalFooter>
            </ModalContent>
          </Modal>

      {/* Application Modal */}
      <Modal onClose={onClose} size={"xl"} isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{application.title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontSize={16} fontWeight={600}>
              Job Information
            </Text>
            <Text my={2}>Brief: {application.brief}</Text>
            <Text my={2}>Rate Offer: ₦{application.rate_p_hr} / Hour</Text>
            {application.decision != "Accept" ? <></> :
            <Box>
            <Text my={2}>
              Location: {application.address}, {application.state}.
            </Text>
            <Stack direction={["row"]} spacing={6}>
              <Text my={2}>
                Date: {dateFormat(application.task_date)}
              </Text>
              <Text my={2}>Time: {application.task_time} GMT+1</Text>
            </Stack>
            </Box>}
            <Stack direction={["row"]} spacing={6}>
              <Box>
                <Text fontSize={16} fontWeight={600} mt={3}>
                  Status
                </Text>
                <Flex align="center">
                  <Icon
                    w="24px"
                    h="24px"
                    me="5px"
                    color={
                      application.applystatus === "Completed"
                        ? "green.500"
                        : application.applystatus === "Rejected"
                        ? "red.500"
                        : "orange.500"
                    }
                    as={
                      application.applystatus === "Completed"
                        ? MdCheckCircle
                        : application.applystatus === "Rejected"
                        ? MdCancel
                        : MdErrorOutline
                    }
                  />
                  <Text color={textColor} fontSize="sm">
                    {application.applystatus === "Rejected" ? "Not Approved" : application.applystatus}
                  </Text>
                </Flex>
              </Box>
              <Box>
                <Text fontSize={16} fontWeight={600} mt={3}>
                  Decision
                </Text>
                <Text>{application.decision === "Rejected" ? "Not Approved" : application.decision}</Text>
              </Box>
            </Stack>
            <Text fontSize={16} fontWeight={600} mt={10}>
              Your Proposal
            </Text>
            <Text my={2}>Cover Message: {application.artisan_message}</Text>
            <Text my={2}>Rate: ₦{application.rate_per_hr} / Hour</Text>
            <Text my={2}>
              Date Applied: {application.applystatus} {dateFormat(application.date)}
            </Text>
          </ModalBody>
         { application.applystatus == 'Contract' ? <ModalFooter>
            <Button
              fontSize={"sm"}
              rounded={"full"}
              mr={2}
              onClick={() => {
                onClose();
                setOpenContract(true);
                
              }}
            >
              Read Contract
            </Button>
            <Button
              fontSize={"sm"}
              rounded={"full"}
              bg={"red.600"}
              color={"white"}
              _hover={{
                bg: "red.500",
              }}
              _focus={{
                bg: "red.500",
              }}
              mr={2}
              onClick={() => {
                handleReject(parseInt(application.job_id), application.a_id);
              }}
            >
            { loading ? <Spinner color='white' /> :  'Reject Contract' }
            </Button>
            <Button
              fontSize={"sm"}
              rounded={"full"}
              bg={"yellow.600"}
              color={"white"}
              _hover={{
                bg: "yellow.500",
              }}
              _focus={{
                bg: "yellow.500",
              }}
              onClick={() => {
                handleAccept(parseInt(application.job_id), application.a_id);
              }}
            >
            { loading ? <Spinner color='white' /> :  'Accept Contract' }
              
            </Button>
          </ModalFooter> : <></> }
        </ModalContent>
      </Modal>
      <Card
        flexDirection="column"
        boxShadow={"none"}
        p={4}
        w="100%"
        mt={6}
        borderRadius={20}
      >
        <Flex px="25px" mb="8px" justifyContent="space-between" align="center">
          <Text
            color={textColor}
            fontSize="22px"
            fontWeight="700"
            lineHeight="100%"
            mt={5}
          >
            Job Applications
          </Text>
          {/* <Menu /> */}
        </Flex>
        <Box>
          <Table variant="simple" color="gray.500" mb="24px" mt="12px">
            <Thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <Th
                        key={header.id}
                        colSpan={header.colSpan}
                        pe="10px"
                        borderColor={borderColor}
                        cursor="pointer"
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        <Flex
                          justifyContent="space-between"
                          align="center"
                          fontSize={{ sm: "10px", lg: "12px" }}
                          color="gray.400"
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: "",
                            desc: "",
                          }[header.column.getIsSorted() as string] ?? null}
                        </Flex>
                      </Th>
                    );
                  })}
                </Tr>
              ))}
            </Thead>
            <Tbody>
              {table
                .getRowModel()
                .rows.slice(0, 5)
                .map((row) => {
                  return (
                    <Tr key={row.id}>
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <Td
                            key={cell.id}
                            fontSize={{ sm: "14px" }}
                            // minW={{ sm: "150px", md: "200px", lg: "auto" }}
                            borderColor="transparent"
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </Td>
                        );
                      })}
                    </Tr>
                  );
                })}
            </Tbody>
          </Table>
        </Box>
      </Card>
    </>
  );
}
