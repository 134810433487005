import { Navigate, Route, Routes } from 'react-router-dom';
import Home from '../views/home';
import UserDashboard from '../views/dashboard/userdashboard';
import { Help, Policy } from '@mui/icons-material';
import Terms from '../views/terms';

export const PrivateRoutes = () => {
    return (
        <Routes>
            {/* <Route path='/artisan/*' element={<UserDashboard />} />
            <Route path='*' element={<Navigate to='/artisan/dashboard' replace />} /> */}

            <Route path='/*' element={<UserDashboard />} />
            <Route path='/help-center' element={<Help />} />
            <Route path='/terms-of-service' element={<Terms />} />
            <Route path='/privacy-policy' element={<Policy />} />
            <Route path='*' element={<Navigate to='/dashboard' replace />} />
        </Routes>
    );
};