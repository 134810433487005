import {
  Box,
  Text,
  Heading,
  Stack,
  Card,
  Flex,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Progress,
  Button,
  Image,
  Grid,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  useDisclosure,
  useToast,
  FormControl,
  FormLabel,
  Input,
  ModalFooter,
  Textarea,
  Avatar,
} from "@chakra-ui/react";
import { flexRender } from "@tanstack/react-table";
import { table } from "console";
import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import {
  getArtisanDashboard,
  getArtisanJobs,
  getChatMessages,
  getDisputes,
  getReviews,
  getUserDashboard,
} from "../../services/api.service";
import ComplexTable from "../../components/tables/ComplexTable";
import {
  addDispute,
  addReview,
  getCurrentUser,
  sendMessage,
  updateTask,
} from "../../services/auth.service";
import moment from "moment";
import { dateFormat } from "../../utils/helper";

const ArtisanJobs = () => {
  const toast = useToast();

  const userData = getCurrentUser().user;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

  const [jobs, setJobs] = useState([]);

  const [applications, setApplications] = useState([]);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isJobOpen, setJobOpen] = useState(false);
  const [isChatOpen, setChatOpen] = useState(false);

  const initialRef = useRef(null);
  const finalRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const [openDispute, setOpenDispute] = useState(false);

  const [activeJob, setActiveJob] = useState<{
    id: number;
    artisan_id: string;
    title: string;
    brief: string;
    owner_name: string;
    contact: string;
    address: string;
    task_date: string;
    task_time: string;
    contract_start_date: string;
    contract_start_time: string;
    contract_end_time: string;
    status: string;
  }>({
    id: 0,
    artisan_id: "",
    title: "",
    brief: "",
    owner_name: "",
    contact: "",
    address: "",
    task_date: "",
    task_time: "",
    contract_start_date: "",
    contract_start_time: "",
    contract_end_time: "",
    status: "",
  });

  useEffect(() => {
    getArtisanDashboard().then(
      (response) => {
        setApplications(response.data["applications"]);
      },
      (error) => {
        console.log(error.message);
      }
    );
    getArtisanJobs().then(
      (response) => {
        setJobs(response.data["data"]);
      },
      (error) => {
        const _jobs =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();

        setJobs(_jobs);
      }
    );
  }, []);

  const [reviews, setReviews] = useState([]);

  const getJobReviews = async (job_id: number) => {
    await getReviews(job_id).then(
      (response) => {
        setReviews(response.data["data"]);
        console.log(reviews);
      },
      (error) => {
        console.log(error.message);
      }
    );
  };

  const [message, setMessage] = useState("");

  const submitReview = async () => {
    await addReview(activeJob.id, message).then(
      (response) => {
        setMessage("");
        toast({
          title: "Submitted!",
          description: `${response.message}`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        getJobReviews(activeJob.id);
      },
      (error) => {
        console.log(error.message);
      }
    );
  };

  const [disputes, setDisputes] = useState([]);

  const getJobDisputes = async (job_id: number) => {
    await getDisputes(job_id).then(
      (response) => {
        setDisputes(response.data["data"]);
        console.log(reviews);
      },
      (error) => {
        console.log(error.message);
      }
    );
  };

  const submitDispute = async () => {
    setLoading(true);
    await addDispute(activeJob.id, message).then(
      (response) => {
        setMessage("");
        toast({
          title: "Submitted!",
          description: `${response.message}`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        setLoading(false);
        getJobDisputes(activeJob.id);
      },
      (error) => {
        setLoading(false);
        console.log(error.message);
      }
    );
  };

  const handleUpdate = (
    job_id: number,
    application_id: string,
    status: string
  ) => {
    setLoading(true);
    updateTask(job_id, application_id, 0, status).then(
      (response) => {
        if (response.success) {
          toast({
            title: "Job status updated successfully!",
            description: `${response.message}`,
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          setTimeout(function () {
            setLoading(false);
            window.location.href = "/my-jobs";
          }, 3000);
        } else {
          setLoading(false);
          toast({
            title: "Unable to Submit!",
            description: `${response.message}`,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      },
      (error) => {
        setLoading(false);
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  };

  const [chatMessages, setMessages] = useState([]);

  const getMessages = async (job_id: number) => {
    await getChatMessages(job_id).then(
      (response) => {
        setMessages(response.data["data"]);
        console.log(reviews);
      },
      (error) => {
        console.log(error.message);
      }
    );
    scrollToBottom();
  };

  const sendChatMessage = async () => {
    setLoading(true);
    await sendMessage(activeJob.id, message).then(
      (response) => {
        setMessage("");
        toast({
          title: "Message sent!",
          // description: `${response.message}`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        setLoading(false);
        getMessages(activeJob.id);
      },
      (error) => {
        setLoading(false);
        console.log(error.message);
      }
    );
  };

  const messagesEndRef = useRef<null | HTMLDivElement>(null)

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
  }


  useEffect(() => {
    const intervalId = setInterval(() => {  //assign interval to a variable to clear it. 
      getMessages(activeJob.id);
      scrollToBottom();
    }, 5000)
  
    return () => clearInterval(intervalId); //This is important
   
  }, [chatMessages, useState])

  return (
    <Box>
      {/* <Flex pb={10} justifyContent="space-between" align="center"> */}

      {/* Dispure Modal */}
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={openDispute}
        onClose={onClose}
        size={"4xl"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Disputes</ModalHeader>
          <ModalCloseButton
            onClick={() => {
              onClose();
              setOpenDispute(false);
            }}
          />
          <ModalBody pb={6}>
            {disputes.map((dispute, i) => {
              return (
                <>
                  <Text m={4} mb={8} style={{ fontStyle: "italic" }}>
                    {dispute["message"]} - {dispute["fname"]} {dispute["lname"]}{" "}
                    ({dispute["date"]}){" "}
                  </Text>
                </>
              );
            })}
            <FormControl mt={4}>
              <FormLabel>Dispute Comment in Details </FormLabel>
              <Textarea
                value={message}
                onChange={(event) => setMessage(event.target.value)}
                placeholder="Write your dispute comments (Decision is subject to our dispute resolution team review)"
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button
              mt={4}
              rounded={"full"}
              bg={"yellow.600"}
              color={"white"}
              _hover={{
                bg: "yellow.500",
              }}
              _focus={{
                bg: "yellow.500",
              }}
              mr={3}
              onClick={() => {
                // setOpenDispute(false);
                submitDispute();
              }}
            >
              {loading ? <Spinner color="white" /> : "Submit"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Modal Start */}

      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isJobOpen}
        onClose={onClose}
        size={"4xl"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{activeJob.title}</ModalHeader>
          <Text m={2} ml={5}>
            {activeJob.brief}
          </Text>
          <Text m={2} ml={5}>
            OWNER: {activeJob.owner_name}
          </Text>
          <Text m={2} ml={5}>
            ADDRESS: {activeJob.address}
          </Text>
          <Text m={2} ml={5}>
            PHONE NUMBER: {activeJob.contact}
          </Text>
          <Text m={2} ml={5}>
            TASK DATE: {dateFormat(activeJob.task_date)}
          </Text>
          <Text m={2} ml={5}>
            TASK TIME: {activeJob.task_time} GMT+1
          </Text>
          <Text m={2} ml={5}>
            TASK DURATION: {activeJob["approx_duration"]} Hours
          </Text>
          <Text m={2} ml={5}>
            CONTRACT ACCEPTED: {activeJob.contract_start_time}{" "}
            {dateFormat(activeJob.contract_start_date)}
          </Text>
          <ModalCloseButton 
          onClick={()=>{
            onClose();
            setJobOpen(false);
          }}
          />
          <ModalBody pb={6}>
            <Box>
              {/* <Button
                                flex={1}
                                fontSize={"sm"}
                                width={200}
                                rounded={"full"}
                                bg={"green.600"}
                                color={"white"}
                                _hover={{
                                  bg: "green.500",
                                }}
                                _focus={{
                                  bg: "green.500",
                                }}
                                onClick={()=>{
                                  handleUpdate(activeJob.id, activeJob.artisan_id , 'Completed');
                                }}
                                mt={2}
                                mr={4}
                              >
                               { loading ? <Spinner color='white' /> :  'Task Completed' }
                              </Button> */}

              <Button
                fontSize={"sm"}
                rounded={"full"}
                bg={"yellow.600"}
                color={"white"}
                _hover={{
                  bg: "yellow.500",
                }}
                mr={5}
                _focus={{
                  bg: "yellow.500",
                }}
                onClick={() => {
                  setJobOpen(false);
                  getMessages(activeJob.id);
                  setChatOpen(true);
                }}
                mt={2}
              >
                Messages
              </Button>
              <Link to={"/timer"} state={{ jobDetails: activeJob }}>
                <Button
                  fontSize={"sm"}
                  rounded={"full"}
                  bg={"yellow.600"}
                  color={"white"}
                  _hover={{
                    bg: "yellow.500",
                  }}
                  mr={5}
                  _focus={{
                    bg: "yellow.500",
                  }}
                  onClick={() => {}}
                  mt={2}
                >
                  Task Timer
                </Button>
              </Link>
              <Button
                flex={1}
                fontSize={"sm"}
                width={200}
                rounded={"full"}
                bg={"red.600"}
                color={"white"}
                _hover={{
                  bg: "red.500",
                }}
                _focus={{
                  bg: "red.500",
                }}
                onClick={() => {
                  setJobOpen(false);
                  getJobDisputes(activeJob.id);
                  setOpenDispute(true);
                }}
                mt={2}
              >
                Disputes
              </Button>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* Modal End */}

      {/* Chat Modal Start */}

      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isChatOpen}
        onClose={onClose}
        size={"4xl"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Flex>
              <Avatar
                src={
                  "https://static.vecteezy.com/system/resources/previews/019/896/008/original/male-user-avatar-icon-in-flat-design-style-person-signs-illustration-png.png"
                }
              />
              <Text textAlign={"left"} px={3}>
                {chatMessages.length != 0
                  ? activeJob.owner_name
                  : "No Messages"}
              </Text>
            </Flex>
          </ModalHeader>
          <ModalCloseButton
            onClick={() => {
              onClose();
              setChatOpen(false);
            }}
          />
          <ModalBody pb={6}>
            <Box
              maxH={"40vh"}
              width={"100%"}
              style={{ overflow: "hidden", overflowY: "scroll" }}
            >
              {chatMessages.map((chat, i) => {
                return (
                  <>
                    {chat["user_id"] != userData.user_id ? (
                      <Flex align={"flex-start"} mb={2}>
                        <Avatar
                          src={
                            "https://static.vecteezy.com/system/resources/previews/019/896/008/original/male-user-avatar-icon-in-flat-design-style-person-signs-illustration-png.png"
                          }
                          width={7}
                          height={7}
                        />
                        <Text
                          textAlign={"left"}
                          px={3}
                          fontSize={13}
                          style={{
                            maxWidth: "50%",
                            backgroundColor: "#a2eb8e4a",
                            margin: 4,
                            padding: 8,
                            borderTopRightRadius: 20,
                            borderBottomRightRadius: 20,
                            borderBottomLeftRadius: 20,
                          }}
                        >
                          {chat["message"]}
                          <br />
                          <span style={{ fontSize: 8 }}>
                            {chat["date"]} - {chat["time"]}
                          </span>
                          <br />
                        </Text>
                      </Flex>
                    ) : (
                      <Flex
                        align={"flex-start"}
                        justifyContent={"flex-end"}
                        mb={2}
                      >
                        <Text
                          textAlign={"right"}
                          px={3}
                          fontSize={13}
                          style={{
                            maxWidth: "50%",
                            backgroundColor: "#ebeb8e4a",
                            margin: 4,
                            padding: 8,
                            borderTopLeftRadius: 20,
                            borderBottomRightRadius: 20,
                            borderBottomLeftRadius: 20,
                          }}
                        >
                          {chat["message"]}
                          <br />
                          <span style={{ fontSize: 10 }}>
                            {chat["date"]} - {chat["time"]}
                          </span>
                          <br />
                        </Text>
                        <Avatar
                          src={
                            "https://static.vecteezy.com/system/resources/previews/019/896/008/original/male-user-avatar-icon-in-flat-design-style-person-signs-illustration-png.png"
                          }
                          width={8}
                          height={8}
                        />
                      </Flex>
                    )}
                  </>
                );
              })}
              <div ref={messagesEndRef} />
            </Box>
            <FormControl mt={4}>
              {/* <FormLabel>Send Message</FormLabel> */}
              <Textarea
                value={message}
                onChange={(event) => setMessage(event.target.value)}
                placeholder="Wrtie your message"
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button
              rounded={"full"}
              bg={"yellow.600"}
              color={"white"}
              _hover={{
                bg: "yellow.500",
              }}
              _focus={{
                bg: "yellow.500",
              }}
              mr={3}
              onClick={() => {
                // setOpenDispute(false);
                sendChatMessage();
              }}
            >
              {loading ? <Spinner color="white" /> : "Send Message"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Chat Modal End */}

      <Grid
        w="full"
        alignItems="center"
        justifyContent="center"
        templateColumns={{ base: "1fr", md: "1fr 1fr" }}
        gap={6}
      >
        <Box>
          <Heading>Your Jobs</Heading>
          <Text mb={4} mt={4}>
            List of Jobs you have completed and current jobs.
          </Text>
        </Box>
        {/* <Box>
          <Link to={'/explore'}>
              <Button
                rounded={"full"}
                size={"lg"}
                fontWeight={"normal"}
                px={6}
                colorScheme={"red"}
                bg={"yellow.600"}
                _hover={{ bg: "black" }}
                float={'right'}
              >
                Explore Jobs
              </Button>
              </Link>
        </Box> */}
      </Grid>
      {applications.length == 0 ? (
        <Stack m={"10%"}>
          <Heading textAlign={"center"} color={"yellow.600"}>
            No Active Applications
          </Heading>
          <Text textAlign={"center"} mb={4} mt={4}>
            Go to explore and start applying for jobs!
          </Text>
        </Stack>
      ) : (
        <ComplexTable tableData={applications} />
      )}
      {jobs.length == 0 ? (
        <>
          <Stack m={"5%"}>
            {/* <Image alignItems={'center'} width={400} src='https://qph.cf2.quoracdn.net/main-qimg-1334ec7bc09de71a2b40337f29c19f9f-lq' alt='No Jobs' mb={4} /> */}
            <Heading textAlign={"center"} color={"yellow.600"}>
              No Active Jobs
            </Heading>
            <Text textAlign={"center"} mb={4} mt={4}>
              Go to explore and start applying for jobs!
            </Text>
          </Stack>
        </>
      ) : (
        <></>
      )}
      {jobs.map((job, i) => {
        return (
          <Card
            flexDirection="column"
            boxShadow={"none"}
            p={4}
            w="100%"
            mt={6}
            borderRadius={20}
          >
            <Grid
              w="full"
              alignItems="center"
              justifyContent="center"
              templateColumns={{
                base: "1fr",
                md: "2fr 1fr 1fr",
              }}
              gap={6}
            >
              <Box>
                <Text
                  color={textColor}
                  fontSize="20px"
                  fontWeight="600"
                  lineHeight="100%"
                  mb={4}
                >
                  {job["title"]}
                </Text>
                <Text
                  fontSize={{ sm: "16px" }}
                  minW={{ sm: "150px", md: "200px", lg: "auto" }}
                  border={"none"}
                >
                  {" "}
                  {job["brief"]}.{" "}
                  <Text mt={4} fontStyle={"italic"}>
                    {job["owner_name"]}
                  </Text>
                </Text>
              </Box>
              <Box>
                <Text
                  fontSize={{ sm: "16px" }}
                  minW={{ sm: "150px", md: "200px", lg: "auto" }}
                  border={"none"}
                  style={{ textTransform: "uppercase" }}
                >
                  {/* {job['rate_p_hr']}/hr */}
                  STATUS: {job["status"]}
                </Text>
                <Progress
                  hasStripe
                  variant="table"
                  h="8px"
                  w="108px"
                  value={10}
                  display={{ base: "none", md: "block" }}
                />
              </Box>
              <Box>
                <Button
                  flex={1}
                  fontSize={"sm"}
                  rounded={"full"}
                  bg={"yellow.600"}
                  color={"white"}
                  _hover={{
                    bg: "yellow.500",
                  }}
                  _focus={{
                    bg: "yellow.500",
                  }}
                  onClick={() => {
                    setActiveJob(job);
                    // onOpen();
                    setJobOpen(true);
                    // console.log(activeJob);
                  }}
                >
                  View Job
                </Button>
                {/* <Button
                  flex={1}
                  fontSize={"sm"}
                  rounded={"full"}
                  bg={"red.600"}
                  color={"white"}
                  ml={4}
                  _hover={{
                    bg: "red.300",
                  }}
                >
                  Remove
                </Button> */}
              </Box>
            </Grid>
          </Card>
        );
      })}
    </Box>
  );
};

export default ArtisanJobs;
