import axios from "axios";
import { useNavigate } from "react-router-dom";
import authHeader, { uploadHeader } from "./auth-header";
import { baseUrl } from "../utils/constants";

export const checkEmail = (email: string) => {
  return axios
    .post(baseUrl + "check-email", {
      email,
    })
    .then((response) => {
      return response.data;
    });
};


export const checkPhone = (phone: string) => {
  return axios
    .post(baseUrl + "check-phone", {
      phone,
    })
    .then((response) => {
      return response.data;
    });
};

export const sendOtp = (email: string, telephone: string) => {
  return axios
    .post(baseUrl + "otp", {
      email,
      telephone,
    })
    .then((response) => {
      return response.data;
    });
};



export const verifyOtp = (email: string, token: string) => {
  return axios
    .post(baseUrl + "verify-otp", {
      email,
      token,
    })
    .then((response) => {
      return response.data;
    });
};

export const registerUser = (fname: string, lname: string, referral: string, user_level: number, email: string, sex: string, phone: string, state: string, address: string, password: string) => {
  return axios.post(baseUrl + "register", {
    fname,
    lname,
    referral,
    user_level,
    email,
    sex,
    phone,
    state,
    address,
    password,
  })
  .then((response) => {
    if (response.data.token) {
      localStorage.setItem("user", JSON.stringify(response.data));
    }
    return response.data;
  });
};


export const registerArtisan = (fname: string, lname: string, referral: string, user_level: number, service: string, email: string, sex: string, age: string, phone: string, state: string, address: string, password: string) => {
  return axios.post(baseUrl + "register", {
    fname,
    lname,
    referral,
    user_level,
    service,
    email,
    sex,
    age,
    phone,
    state,
    address,
    password,
  })
  .then((response) => {
    if (response.data.token) {
      localStorage.setItem("user", JSON.stringify(response.data));
    }
    return response.data;
  });
};


export const uploadAvatar = (file: any) => {
  return axios
    .post(baseUrl + "upload-avatar", {
      image: file,
    }, { headers: uploadHeader() })
    .then((response) => {
      return response.data;
    });
};

export const uploadImage = (file: any) => {
  return axios
    .post(baseUrl + "upload-image", {
      image: file,
    }, { headers: uploadHeader() })
    .then((response) => {
      return response.data;
    });
};

export const uploadFile = (file: any) => {
  return axios
    .post(baseUrl + "upload-file", {
      file: file,
    }, { headers: uploadHeader() })
    .then((response) => {
      return response.data;
    });
};

export const signinUser = (email: string, password: string) => {
  return axios
    .post(baseUrl + "login", {
      email,
      password,
    })
    .then((response) => {
      if (response.data.token) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }
      return response.data;
    });
};

export const sendReset = (email: string) => {
  return axios
    .post(baseUrl + "reset", {
      email
    })
    .then((response) => {
      return response.data;
    });
};


export const deleteRequest = (email: string) => {
  return axios
    .post(baseUrl + "delete-my-account", {
      email
    })
    .then((response) => {
      return response.data;
    });
};

export const resetPassword = (email: any, password: any) => {
  return axios
    .post(baseUrl + "reset-password", {
      email,
      password,
    })
    .then((response) => {
      return response.data;
    });
};

export const logout = () => {
  localStorage.removeItem("user");
  window.location.href = "/";
};

export const getCurrentUser = () => {
  const userStr = localStorage.getItem("user");
  if (userStr != null)
    {
      // console.log(JSON.parse(userStr));
      return JSON.parse(userStr);
    }
    else{
      return null;
    }
};



export const createTask = (title: string, brief: string, task_date: string, task_time: string, service_type: string, contact: string, state: string, address: string, rate_p_hr: string, approx_duration: string) => {
  return axios.post(baseUrl + "new-task", {
    title,
    brief,
    task_date,
    task_time,
    service_type,
    contact,
    state,
    address,
    rate_p_hr,
    approx_duration
  }, { headers: authHeader() })
  .then((response) => {
    return response.data;
  });
};



export const applyTask = (job_id: number, rate_per_hr: string, artisan_message: string) => {
  return axios.post(baseUrl + "apply", {
    job_id,
    rate_per_hr,
    artisan_message
  }, { headers: authHeader() })
  .then((response) => {
    return response.data;
  });
};

export const acceptOffer = (job_id: number, artisan_id: string) => {
  return axios.post(baseUrl + "accept-contract", {
    job_id,
    artisan_id
  }, { headers: authHeader() })
  .then((response) => {
    return response.data;
  });
};


export const rejectOffer = (job_id: number, artisan_id: string) => {
  return axios.post(baseUrl + "reject-contract", {
    job_id,
    artisan_id
  }, { headers: authHeader() })
  .then((response) => {
    return response.data;
  });
};

export const updateTask = (job_id: number, application_id: string, tip: number, status: string) => {
  return axios.post(baseUrl + "update", {
    job_id,
    application_id,
    tip,
    status
  }, { headers: authHeader() })
  .then((response) => {
    return response.data;
  });
};


export const sendOfferToArtisan = (job_id: number, artisan_id: string) => {
  return axios.post(baseUrl + "offer", {
    job_id,
    artisan_id
  }, { headers: authHeader() })
  .then((response) => {
    return response.data;
  });
};


export const removeTask = (job_id: number) => {
  return axios.post(baseUrl + "remove", {
    job_id
  }, { headers: authHeader() })
  .then((response) => {
    return response.data;
  });
};


export const terminateTask = (job_id: number) => {
  return axios.post(baseUrl + "terminate", {
    job_id
  }, { headers: authHeader() })
  .then((response) => {
    return response.data;
  });
};


export const fundWallet = (amount: string) => {
  return axios.post(baseUrl + "fund", {
    amount
  }, { headers: authHeader() })
  .then((response) => {
    return response.data;
  });
};


export const requestWithdrawal = (amount: string) => {
  return axios.post(baseUrl + "withdraw", {
    amount
  }, { headers: authHeader() })
  .then((response) => {
    return response.data;
  });
};



export const verifyUser = (g1_name: string, g1_contact: string, g1_address: string, g2_name: string, g2_contact: string, g2_address: string, id_img: string, img1: string, facebook: string, twitter: string, instagram: string, tiktok: string) => {
  return axios.post(baseUrl + "verify", {
    g1_name,
    g1_contact,
    g1_address,
    g2_name,
    g2_contact,
    g2_address,
    id_img,
    img1,
    // img2,
    // img3
    facebook,
    twitter,
    instagram,
    tiktok
  }, { headers: authHeader() })
  .then((response) => {
    return response.data;
  });
};



export const updateProfile = (fname: string, lname: string, phone: string, country: string, service_two: string, service_three: string, state: string, city: string, address: string, facebook: string, twitter: string, instagram: string, tiktok: string) => {
  return axios.post(baseUrl + "update-profile", {
    fname,
    lname,
    phone,
    country,
    service_two,
    service_three,
    state,
    city,
    address,
    facebook,
    twitter,
    instagram,
    tiktok
  }, { headers: authHeader() })
  .then((response) => {
    return response.data;
  });
};

export const updateAcount = (bank_name: string, acc_name: string, acc_no: string) => {
  return axios.post(baseUrl + "update-bank", {
    bank_name,
    acc_name,
    acc_no
  }, { headers: authHeader() })
  .then((response) => {
    return response.data;
  });
};


export const updatePassword = (password: string) => {
  return axios.post(baseUrl + "change-password", {
    password,
  }, { headers: authHeader() })
  .then((response) => {
    return response.data;
  });
};


export const addReview = (job_id: number, message: string) => {
  return axios.post(baseUrl + "add-review", {
    job_id,
    message
  }, { headers: authHeader() })
  .then((response) => {
    return response.data;
  });
};


export const addDispute = (job_id: number, message: string) => {
  return axios.post(baseUrl + "add-dispute", {
    job_id,
    message
  }, { headers: authHeader() })
  .then((response) => {
    return response.data;
  });
};


export const sendMessage = (job_id: number, message: string, ) => {
  return axios.post(baseUrl + "add-message", {
    job_id,
    message
  }, { headers: authHeader() })
  .then((response) => {
    return response.data;
  });
};