import {
  Box,
  Container,
  Heading,
  Text,
  Stack,
  Highlight,
  Flex,
  Grid,
  StackDivider,
  useColorModeValue,
  Image,
  Button,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Textarea,
  VStack,
  Center,
  useToast,
} from "@chakra-ui/react";
import Navbar from "../components/navbar/navbar";
import Hero from "../components/hero/hero-section";
import HowitWorks from "../components/howitworks/how";
import Top from "../components/top/top";
import Footer from "../components/footer/footer";
import Testimonials from "../components/testimonials/testimonials";
import Standards from "../components/standards/standards";
import { BsPerson } from "react-icons/bs";
import { MdEmail } from 'react-icons/md';
import { FaWhatsapp } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useState } from "react";
import { deleteRequest } from "../services/auth.service";

export default function DeleteAccount() {

    const toast = useToast();
    const [filter, setFilter] = useState("");
    const [email, setEmail] = useState("");


    const cardBg = useColorModeValue("white", "gray.900");
    const inputBg = useColorModeValue("white", "gray.900");
    const inputColor = useColorModeValue("gray.900", "white");

  const deleteAccount = () => {
    if(email != ""){
      deleteRequest(email).then(
        (response) => {
          if(response.success){ 
          toast({
            title: 'Success',
            description: `${response.message}`,
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
          setTimeout(function () {
            window.location.href = "/";
        }, 3000);
        }else{
          toast({
            title: 'Error Occured',
            description: `${response.message}`,
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
        }
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    }else{
        toast({
            title: 'Error!',
            description: `Please enter a valid email`,
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
    }

  };


  return (
    <Box textAlign="justify">
      <Navbar />
      <Box mt={{ md: 20 }}>
        <Stack spacing={0} align={"center"} mb={20}>

          <Box p={10} textAlign={'center'}>
          <Heading>Delete Your Account</Heading>
          <Text mt={4}>Please provide your account email in the field and<br/>if account exists, we will delete all account data within 72Hours.</Text>
       
                <VStack spacing={5}>

                  <FormControl mt={20} mb={10} isRequired>
                    <FormLabel>Email</FormLabel>

                    <InputGroup>
                      <InputLeftElement>
                        <MdEmail />
                      </InputLeftElement>
                        <Input
                        type="email"
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                        bg={inputBg}
                        color={inputColor}
                        pattern="\S+@\S+\.\S+"
                        />
                    </InputGroup>
                  </FormControl>

                  <Button
                    colorScheme="blue"
                    bg="yellow.600"
                    color="white"
                    _hover={{
                      bg: 'yellow.500',
                    }}
                    width="full"
                      onClick={()=>{
                        deleteAccount();
                      }}>
                    Delete Account
                  </Button>
                </VStack>
          </Box>
        </Stack>
        <HowitWorks />
        <Testimonials />
      </Box>
      <Footer />
    </Box>
  );
}
