import Navbar from "../../components/navbar/navbar";
import { useToast } from '@chakra-ui/react'
import Top from "../../components/top/top";
import Footer from "../../components/footer/footer";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  useColorModeValue,
  Container,
  FormErrorMessage,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { url } from "inspector";
import { sendReset } from "../../services/auth.service";
import { Link } from "react-router-dom";

/**
 * Props for the ForgotPassword component.
 * @interface ForgotPasswordProps
 */
/**
 * Props for the ForgotPassword component.
 */
interface ForgotPasswordProps {
  /**
   * Function to be called when the form is submitted.
   * @param {string} email - The email entered by the user.
   * @returns {void}
   */
  onSubmit: (email: string) => void;
}

const ForgotPassword = () => {
  const toast = useToast();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const validateEmail = () => {
    if (!email) {
      setEmailError("Email is required");
      return false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError("Invalid email address");
      return false;
    } else {
      setEmailError("");
      return true;
    }
  };


  const cardBg = useColorModeValue("white", "gray.900");
  const inputBg = useColorModeValue("white", "gray.900");
  const inputColor = useColorModeValue("gray.900", "white");


  const [successful, setSuccessful] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");

  const handleSubmit = () => {

    sendReset(email).then(
      (response) => {
        if(response.success){ 
        toast({
          title: 'Success',
          description: `${response.message}`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        setSuccessful(response.success);
        setTimeout(function () {
          window.location.href = "/";
      }, 3000);
      }else{
        toast({
          title: 'Error Occured',
          description: `${response.message}`,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        setSuccessful(response.success);
      }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setMessage(resMessage);
        setSuccessful(false);
      }
    );
  };


  return (
    <Box textAlign="center" backgroundImage="url('https://www.thespruce.com/thmb/vvaeencI8Ej0NgoYi_nQlOVfwbk=/fit-in/1500x4198/filters:no_upscale():max_bytes(150000):strip_icc()/spr-steam-mops-test-black-decker-7-1-amazon-dburreson-0278-056747f26ee54b5ea6c1280fbcba415e.jpg')" fontSize="xl"
    backgroundSize="cover"
  backgroundPosition="center"
    >
      <Navbar />
      <Box>
        <Container maxW={"7xl"} mt={10}>
          <Box m={{ md: 20}} mb={{ sm: 20}}>
            <Box maxW={"md"} mx={"auto"} mb={10} px={6} bgColor={cardBg} p={10} borderRadius={10}>
            <Stack spacing={0} align={"center"} mb={10}>
              <Heading color={"yellow.600"}>Forgot Password</Heading>
            </Stack>
            
                <Stack spacing={4}>
                  <FormControl id="email" isInvalid={!!emailError}>
                    <FormLabel>Email address</FormLabel>
                    <Input
                      type="email"
                      value={email}
                      onChange={(event) => setEmail(event.target.value)}
                      bg={inputBg}
                      color={inputColor}
                      pattern="\S+@\S+\.\S+"
                    />
                    <FormErrorMessage>{emailError}</FormErrorMessage>
                  </FormControl>
                  <Button
                    type="submit"
                    mt={5}
                    mb={2}
                    fontSize={"sm"}
                    fontWeight={600}
                    color={"white"}
                    bg={"yellow.600"}
                    _hover={{
                      bg: "black",
                    }}
                    onClick={()=>handleSubmit()}
                  >
                    Send Reset Link
                  </Button>
                </Stack>
            </Box>

            <Link
            to={"/signin"}
              color="black.500"
            >
              Go Back
            </Link>
          </Box>
        </Container>
      </Box>
      <Footer />
    </Box>
  );
};

export default ForgotPassword;
