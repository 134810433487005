import {
  Box,
  Text,
  Heading,
  Stack,
  Card,
  Avatar,
  AvatarBadge,
  IconButton,
  Tr,
  useColorModeValue,
  Progress,
  Button,
  Grid,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
  useDisclosure,
  useToast,
  Spinner,
  Center,
  Flex,
} from "@chakra-ui/react";
import { flexRender } from "@tanstack/react-table";
import { table } from "console";
import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import {
  getArtisan,
  getChatMessages,
  getDisputes,
  getReviews,
  getUserJobs,
} from "../../services/api.service";

import {
  addDispute,
  addReview,
  applyTask,
  getCurrentUser,
  removeTask,
  sendMessage,
  sendOfferToArtisan,
  terminateTask,
  updateTask,
} from "../../services/auth.service";
import { FaFacebook, FaInstagram, FaTv, FaTwitter } from "react-icons/fa";
import { currencyFormat, dateFormat } from "../../utils/helper";

const UserJobs = () => {
  const toast = useToast();

  const userData = getCurrentUser().user;
  
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

  const { isOpen, onOpen, onClose } = useDisclosure();

  const initialRef = useRef(null);
  const finalRef = useRef(null);

  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(false);

  const [activeJob, setActiveJob] = useState<{
    id: number;
    title: string;
    brief: string;
    rate_p_hr: string;
    owner_name: string;
    applications: [];
  }>({
    id: 0,
    title: "",
    brief: "",
    rate_p_hr: "",
    owner_name: "",
    applications: [],
  });

  const [activeApplication, setActiveApplication] = useState<{
    artisan_id: string;
    artisan_name: string;
  }>({
    artisan_id: "",
    artisan_name: "",
  });

  useEffect(() => {
    getUserJobs().then(
      (response) => {
        setJobs(response.data["data"]);
      },
      (error) => {
        const _jobs =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();

        setJobs(_jobs);
      }
    );
  }, []);

  const [openContract, setOpenContract] = useState(false);
  const [openDispute, setOpenDispute] = useState(false);
  const [openArtisan, setOpenArtisan] = useState(false);
  const [openTerminate, setOpenTerminate] = useState(false);
  const [openCompleted, setOpenCompleted] = useState(false);
  const [tip, setTip] = useState(0);

  const handleUpdate = (
    job_id: number,
    application_id: string,
    tip: number,
    status: string
  ) => {
    setLoading(true);
    updateTask(job_id, application_id, tip, status).then(
      (response) => {
        if (response.success) {
          toast({
            title: "Task Marked Completed!",
            description: `${response.message}`,
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          setTimeout(function () {
            setLoading(false);
            window.location.href = "/dashboard";
          }, 3000);
        } else {
          setLoading(false);
          toast({
            title: "Unable to Submit!",
            description: `${response.message}`,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      },
      (error) => {
        setLoading(false);
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  };

  const sendOffer = () => {
    setLoading(true);
    sendOfferToArtisan(activeJob.id, activeApplication.artisan_id).then(
      (response) => {
        if (response.success) {
          toast({
            title: "Job offer sent successfully!",
            description: `${response.message}`,
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          setTimeout(function () {
            setLoading(false);
            window.location.href = "/dashboard";
          }, 3000);
        } else {
          setLoading(false);
          toast({
            title: "Unable to Submit!",
            description: `${response.message}`,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      },
      (error) => {
        setLoading(false);
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  };

  const [reviews, setReviews] = useState([]);

  const getJobReviews = async (job_id: number) => {
    await getReviews(job_id).then(
      (response) => {
        setReviews(response.data["data"]);
        console.log(reviews);
      },
      (error) => {
        console.log(error.message);
      }
    );
  };

  const [message, setMessage] = useState("");

  const submitReview = async () => {
    await addReview(activeJob.id, message).then(
      (response) => {
        setMessage("");
        toast({
          title: "Submitted!",
          description: `${response.message}`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        getJobReviews(activeJob.id);
      },
      (error) => {
        console.log(error.message);
      }
    );
  };

  const [disputes, setDisputes] = useState([]);

  const getJobDisputes = async (job_id: number) => {
    await getDisputes(job_id).then(
      (response) => {
        setDisputes(response.data["data"]);
        console.log(reviews);
      },
      (error) => {
        console.log(error.message);
      }
    );
  };

  const submitDispute = async () => {
    await addDispute(activeJob.id, message).then(
      (response) => {
        setMessage("");
        toast({
          title: "Submitted!",
          description: `${response.message}`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        // getJobDisputes(activeJob.id);
        setTimeout(function () {
          setLoading(false);
          window.location.href = "/my-jobs";
        }, 3000);
      },
      (error) => {
        console.log(error.message);
      }
    );
  };

  const removeJob = (job_id: number) => {
    setLoading(true);
    removeTask(job_id).then(
      (response) => {
        if (response.success) {
          toast({
            title: "Job status updated successfully!",
            description: `${response.message}`,
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          setTimeout(function () {
            setLoading(false);
            window.location.href = "/my-jobs";
          }, 3000);
        } else {
          setLoading(false);
          toast({
            title: "An error occured!",
            description: `${response.message}`,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      },
      (error) => {
        setLoading(false);
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  };

  const terminateJob = (job_id: number) => {
    setLoading(true);
    terminateTask(job_id).then(
      (response) => {
        if (response.success) {
          toast({
            title: "Job terminated successfully!",
            description: `${response.message}`,
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          setTimeout(function () {
            setLoading(false);
            window.location.href = "/my-jobs";
          }, 3000);
        } else {
          setLoading(false);
          toast({
            title: "An error occured!",
            description: `${response.message}`,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      },
      (error) => {
        setLoading(false);
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
    setOpenTerminate(false);
  };

  const [artisanData, setArtisanData] = useState({});
  const [artisanJobCount, setArtisanJobCount] = useState(0);
  const [artisanReviews, setArtisanReviews] = useState([]);

  const fetchArtisan = (id: string) => {
    getArtisan(id).then(
      (response) => {
        if (response.success) {
          // console.log(response.data);
          setArtisanData(response.data);
          setArtisanJobCount(response.completed_jobs);
          setArtisanReviews(response.reviews);
          setOpenArtisan(true);
        } else {
          toast({
            title: "Could not fetch Artisan!",
            description: `${response.message}`,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  };

  const fetchArtisanNoModal = (id: string) => {
    getArtisan(id).then(
      (response) => {
        if (response.success) {
          // console.log(response.data);
          setArtisanData(response.data);
        } else {
          toast({
            title: "Could not fetch Artisan!",
            description: `${response.message}`,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  };


  const [isChatOpen, setChatOpen] = useState(false);
  const [chatMessages, setMessages] = useState([]);

  const getMessages = async (job_id: number) => {
    await getChatMessages(job_id).then(
      (response) => {
        setMessages(response.data["data"]);
        console.log(reviews);
      },
      (error) => {
        console.log(error.message);
      }
    );
    scrollToBottom();
  };

  const sendChatMessage = async () => {
    setLoading(true);
    await sendMessage(activeJob.id, message).then(
      (response) => {
        setMessage("");
        toast({
          title: "Message sent!",
          // description: `${response.message}`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        setLoading(false);
        getMessages(activeJob.id);
      },
      (error) => {
        setLoading(false);
        console.log(error.message);
      }
    );
  };

  const messagesEndRef = useRef<null | HTMLDivElement>(null)

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
  }


  useEffect(() => {
    const intervalId = setInterval(() => {  //assign interval to a variable to clear it. 
      getMessages(activeJob.id);
      scrollToBottom();
    }, 5000)
  
    return () => clearInterval(intervalId); //This is important
   
  }, [chatMessages, useState])
  

  return (
    <Box>
      {/* <Flex pb={10} justifyContent="space-between" align="center"> */}

      {/* Are you sure modal */}
      <Modal finalFocusRef={finalRef} isOpen={openCompleted} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Task Completed?</ModalHeader>
          <ModalCloseButton
          onClick={()=>{
            onClose();
            setOpenCompleted(false);
          }}
          />
          <ModalBody>
            <Text>
              Are you sure Job is completed?, this is not reversible
              and payment will be released to Artisan.
            </Text>
            <FormControl py={3} id="service">
              <FormLabel>Tip Artisan (optional)</FormLabel>
              <Input
                placeholder="Your Service"
                value={tip}
                _placeholder={{ color: "gray.500" }}
                type="text"
                onChange={(event) => setTip(parseInt(event.target.value))}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button
              bg={"red.600"}
              color={"white"}
              _hover={{
                bg: "red.500",
              }}
              _focus={{
                bg: "red.500",
              }}
              onClick={() => {
                handleUpdate(
                  activeJob.id,
                  activeApplication.artisan_id,
                  tip,
                  "Completed"
                );
              }}
              mr={3}
            >
              {loading ? <Spinner color="white" /> : "Confirm"}
            </Button>
            <Button variant="ghost" onClick={()=>{
              onClose();
              setOpenCompleted(false);
            }}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Are you sure modal */}
      <Modal finalFocusRef={finalRef} isOpen={openTerminate} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Action</ModalHeader>
          <ModalCloseButton onClick={()=>{
              onClose();
              setOpenTerminate(false);
            }} />
          <ModalBody>
            <Text>
              Are you sure you want to terminate Job?, this is not reversible
              and your refund will only be processed upon confirmation from the
              artisan and dipute resolution team. This usually takes up to 2
              working days.
            </Text>
          </ModalBody>

          <ModalFooter>
            <Button
              bg={"red.600"}
              color={"white"}
              _hover={{
                bg: "red.500",
              }}
              _focus={{
                bg: "red.500",
              }}
              onClick={() => {
                terminateJob(activeJob.id);
              }}
              mr={3}
            >
              {loading ? <Spinner color="white" /> : "Yes, Terminate"}
            </Button>
            <Button variant="ghost" onClick={()=>{
              onClose();
              setOpenTerminate(false);
            }}>Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* View Artisan Modal */}
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={openArtisan}
        onClose={onClose}
        // size={"xl"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader alignSelf={"center"}>Artisan Details</ModalHeader>
          {/* <ModalCloseButton onClick={()=>{
                  // onClose();
                  setOpenArtisan(false);
                  }} /> */}
          <ModalBody pb={6}>
            <Center>
              <Avatar
                size="xl"
                src={
                  artisanData["avatar"] ??
                  "https://static.vecteezy.com/system/resources/previews/019/896/008/original/male-user-avatar-icon-in-flat-design-style-person-signs-illustration-png.png"
                }
              >
                <AvatarBadge
                  as={IconButton}
                  size="sm"
                  rounded="full"
                  top="-10px"
                  colorScheme="green"
                  aria-label="remove Image"
                />
              </Avatar>
            </Center>
            <Text m={3}>
              Name: {artisanData["fname"]} {artisanData["lname"]}
            </Text>
            <Text m={3}>Gender: {artisanData["sex"]}</Text>
            <Text m={3}>Age: {artisanData["age"]}</Text>
            <Text m={3}>Service Type: {artisanData["title"]}</Text>
            {/* <Text m={3}>L=']}</Text> */}
            <Text m={3}>Completed Jobs: {artisanJobCount}</Text>
            <Text m={3}>Socials:</Text>
            <Box style={{display: 'inline-flex', marginLeft: 10}}>
              <Link to={"https://instagram.com/"+artisanData["instagram"]}><FaInstagram style={{marginRight: 20}}/></Link>
              <Link to={"https://twitter.com/"+artisanData["twitter"]}><FaTwitter style={{marginRight: 20}}/></Link>
              <Link to={"https://facebook.com/"+artisanData["facebook"]}><FaFacebook style={{marginRight: 20}}/></Link>
              {/* <Link to={"https://tiktok.com/"+artisanData["tiktok"]}><FaTv style={{marginRight: 20}}/></Link> */}
            </Box>
            <Text m={3} fontWeight={"bold"}>
              Top Reviews:
            </Text>
            {artisanReviews.map((r, i) => {
              return (
                <>
                  <Text m={4} mb={8} style={{ fontStyle: "italic" }}>
                    {r["message"]} - {r["fname"]} {r["lname"]} ({r["date"]}){" "}
                  </Text>
                </>
              );
            })}
          </ModalBody>
          <ModalFooter>
            <Button
              rounded={"full"}
              bg={"red.600"}
              color={"white"}
              _hover={{
                bg: "red.500",
              }}
              _focus={{
                bg: "red.500",
              }}
              mr={3}
              onClick={() => {
                setOpenArtisan(false);
                // submitDispute();
              }}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Dispure Modal */}
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={openDispute}
        onClose={onClose}
        size={"4xl"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Disputes</ModalHeader>
          <ModalCloseButton
            onClick={() => {
              onClose();
              setOpenDispute(false);
            }}
          />
          <ModalBody pb={6}>
            {disputes.map((dispute, i) => {
              return (
                <>
                  <Text m={4} mb={8} style={{ fontStyle: "italic" }}>
                    {dispute["message"]} - {dispute["fname"]} {dispute["lname"]}{" "}
                    ({dispute["date"]}){" "}
                  </Text>
                </>
              );
            })}
            <FormControl mt={4}>
              <FormLabel>Dispute Comment in Details </FormLabel>
              <Textarea
                value={message}
                onChange={(event) => setMessage(event.target.value)}
                placeholder="Write your dispute comments (Decision is subject to our dispute resolution team review)"
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button
              mt={4}
              rounded={"full"}
              bg={"yellow.600"}
              color={"white"}
              _hover={{
                bg: "yellow.500",
              }}
              _focus={{
                bg: "yellow.500",
              }}
              mr={3}
              onClick={() => {
                // setOpenDispute(false);
                submitDispute();
              }}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Contract Modal */}
      <Modal
        onClose={onClose}
        isOpen={openContract}
        // scrollBehavior={scrollBehavior}
        size={"4xl"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader mt={10}>Contract for {activeJob.title}</ModalHeader>
          <ModalCloseButton
            onClick={() => {
              onClose();
              setOpenContract(false);
            }}
          />
          <ModalBody>
            <Box textAlign={"center"}>
              <Text my={4}>This contract is between</Text>
              <Text my={4} fontWeight={600}>
                {activeJob.owner_name}
              </Text>
              <Text my={4}>and Sapioartisan with ID:</Text>
              <Text my={4} fontWeight={600}>
                {activeApplication.artisan_name}
              </Text>
              <Text my={10}>
                This Agreement ("Agreement") is entered into as of [
                {dateFormat(Date())}], by and between [
                {activeJob.owner_name}], hereinafter referred to as the
                "Client," and [{activeApplication.artisan_name}], hereinafter
                referred to as the "Artisan."
              </Text>
            </Box>
            <Box>
              <Text my={3} fontWeight={600}>
                1. Scope of Work
              </Text>

              <Text my={2}>
                The Artisan agrees to provide the following services to the
                Client:
              </Text>

              <Text my={3} fontWeight={600}>
                2. Payment Terms
              </Text>

              <Text my={2}>
                a. The Client agrees to pay the Artisan [Agreed-upon Fee] for
                the services rendered.
              </Text>

              <Text my={2}>
                b. Payment shall be made according to the platform standard.
              </Text>

              <Text my={3} fontWeight={600}>
                3. Timeline
              </Text>

              <Text my={2}>
                {/* a. The Artisan shall commence work on [{dateFormat(application.contract_start_date)}] and complete
                  the project by [{dateFormat(application.contract_end_date)}]. */}
              </Text>

              <Text my={2}>
                b. Any delays caused by the Artisan shall be communicated
                promptly to the Client.
              </Text>

              <Text my={3} fontWeight={600}>
                4. Intellectual Property
              </Text>

              <Text my={2}>
                a. All intellectual property rights related to the work produced
                by the Artisan shall belong to the Client upon full payment.
              </Text>

              <Text my={2}>
                b. The Artisan retains the right to showcase the work in their
                portfolio, unless otherwise agreed upon in writing.
              </Text>

              <Text my={3} fontWeight={600}>
                5. Confidentiality
              </Text>

              <Text my={2}>
                a. The Artisan agrees to keep confidential any proprietary or
                sensitive information obtained during the course of the project.
              </Text>

              <Text my={2}>
                b. Both parties agree not to disclose any confidential
                information to third parties.
              </Text>

              <Text my={3} fontWeight={600}>
                6. Dispute Resolution
              </Text>

              <Text my={2}>
                Any disputes arising out of or relating to this Agreement shall
                be resolved through arbitration in accordance with the rules of
                [SapioWorks Inc.].
              </Text>

              <Text my={3} fontWeight={600}>
                7. Entire Agreement
              </Text>

              <Text my={2}>
                This Agreement constitutes the entire understanding between the
                parties and supersedes all prior discussions and agreements.
              </Text>

              <Text my={2}>
                IN WITNESS WHEREOF, the parties hereto have executed this
                Agreement as of the Effective Date.
              </Text>

              <Text my={2}>[{activeJob.owner_name}]</Text>

              <Text my={2}>[{activeApplication.artisan_name}]</Text>

              <Text my={2}>
                Date: This contract is dated:{" "}
                {dateFormat(Date())}{" "}
                {/* and due to end by{" "} */}
                {/* {dateFormat(application.contract_end_date)}{" "} */}
              </Text>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={() => {
                onClose();
                setOpenContract(false);
              }}
            >
              Cancel
            </Button>
            <Button
              flex={1}
              fontSize={"sm"}
              rounded={"sm"}
              ml={10}
              bg={"yellow.600"}
              color={"white"}
              _hover={{
                bg: "yellow.500",
              }}
              _focus={{
                bg: "yellow.500",
              }}
              onClick={() => {
                sendOffer();
              }}
            >
              {loading ? <Spinner color="white" /> : "Agree, Send Offer"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Modal Start */}

      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
        size={"4xl"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{activeJob.title}</ModalHeader>
          <Text ml={5}>{activeJob.brief}</Text>
          <Text ml={5}>{currencyFormat(parseInt(activeJob.rate_p_hr))}/hr</Text>
          <ModalCloseButton />
          <ModalHeader as="b">Applications</ModalHeader>
          {activeJob.applications.length != 0 ? (
            <ModalBody pb={6}>
              <Box mt={4} />
              <Grid
                w="full"
                alignItems="center"
                justifyContent="center"
                templateColumns={{
                  base: "1fr 1fr 1fr",
                  md: "1fr 1fr 1fr",
                }}
                gap={6}
                mb={2}
              >
                <Text as="b">Name</Text>
                <Text as="b">Message</Text>
                {/* <Text as='b'>Date</Text> */}
                <Text as="b">Action</Text>
              </Grid>
              {activeJob.applications.map((application, i) => {
                return (
                  <>
                    {activeJob["status"].toString().toLowerCase() == "new" ? (
                      <Grid
                        w="full"
                        alignItems="center"
                        justifyContent="center"
                        templateColumns={{
                          base: "1fr 1fr 1fr",
                          md: "1fr 1fr 1fr",
                        }}
                        gap={6}
                        mt={2}
                        mb={8}
                      >
                        <Text>{application["artisan_name"]}</Text>
                        <Text>{application["artisan_message"]}</Text>
                        {/* <Text>{dateFormat(application['date'])}</Text> */}
                        <Box>
                          <Text
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              fetchArtisan(application["artisan_id"])
                            }
                            mb={3}
                            color={"yellow.600"}
                          >
                            Artisan Details & Reviews
                          </Text>
                          <Button
                            flex={1}
                            fontSize={"sm"}
                            rounded={"full"}
                            bg={"yellow.600"}
                            color={"white"}
                            _hover={{
                              bg: "yellow.500",
                            }}
                            _focus={{
                              bg: "yellow.500",
                            }}
                            onClick={() => {
                              onClose();
                              setActiveApplication(application);
                              setOpenContract(true);
                              // console.log(activeApplication);
                            }}
                          >
                            Send Offer
                          </Button>
                        </Box>{" "}
                      </Grid>
                    ) : activeJob["status"] == "ongoing" ? (
                      application["decision"] == "Accept" ? (
                        <Grid
                        w="full"
                        alignItems="center"
                        justifyContent="center"
                        templateColumns={{
                          base: "1fr 1fr 1fr",
                          md: "1fr 1fr 1fr",
                        }}
                        gap={6}
                        mt={2}
                        mb={8}
                      >
                        <Text>{application["artisan_name"]}</Text>
                        <Text>{application["artisan_message"]}</Text>
                        {/* <Text>{dateFormat(application['date'])}</Text> */}
                        <Box>
                          <Text
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              fetchArtisan(application["artisan_id"])
                            }
                            mb={3}
                            color={"yellow.600"}
                          >
                            Artisan Details & Reviews
                          </Text>
                        <Button
                          fontSize={"sm"}
                          rounded={"full"}
                          bg={"yellow.600"}
                          width={200}
                          color={"white"}
                          _hover={{
                            bg: "yellow.500",
                          }}
                          mr={5}
                          _focus={{
                            bg: "yellow.500",
                          }}
                          onClick={() => {
                            // setJobOpen(false);
                            fetchArtisanNoModal(application["artisan_id"]);
                            onClose();
                            getMessages(activeJob.id);
                            setChatOpen(true);
                          }}
                          mt={2}
                        >
                          Messages
                        </Button>
                          <Button
                            flex={1}
                            fontSize={"sm"}
                            width={200}
                            rounded={"full"}
                            bg={"green.600"}
                            color={"white"}
                            _hover={{
                              bg: "green.500",
                            }}
                            _focus={{
                              bg: "green.500",
                            }}
                            onClick={() => {
                              // handleUpdate(
                              //   activeJob.id,
                              //   activeApplication.artisan_id,
                              //   "Completed"
                              // );
                              onClose();
                              setOpenCompleted(true);
                            }}
                            mt={2}
                            mr={4}
                          >
                            {loading ? (
                              <Spinner color="white" />
                            ) : (
                              "Task Completed"
                            )}
                          </Button>
                          <Button
                            flex={1}
                            fontSize={"sm"}
                            width={200}
                            rounded={"full"}
                            bg={"red.600"}
                            color={"white"}
                            _hover={{
                              bg: "red.500",
                            }}
                            _focus={{
                              bg: "red.500",
                            }}
                            onClick={() => {
                              onClose();
                              getJobDisputes(activeJob.id);
                              setOpenDispute(true);
                            }}
                            mt={2}
                          >
                            Disputes
                          </Button>
                          <Button
                            flex={1}
                            fontSize={"sm"}
                            width={200}
                            rounded={"full"}
                            bg={"gray.600"}
                            color={"white"}
                            _hover={{
                              bg: "gray.500",
                            }}
                            _focus={{
                              bg: "gray.500",
                            }}
                            onClick={() => {
                              onClose();
                              setOpenTerminate(true);
                            }}
                            mt={2}
                          >
                            Terminate Job
                          </Button>
                        </Box>{" "}
                      </Grid>
                      ) : <></>
                    ) :  activeJob["status"] == "terminated" ? (
                      application["decision"] == "terminated" ? (
                      <Grid
                        w="full"
                        alignItems="center"
                        justifyContent="center"
                        templateColumns={{
                          base: "1fr 1fr 1fr",
                          md: "1fr 1fr 1fr",
                        }}
                        gap={6}
                        mt={2}
                        mb={8}
                      >
                        <Text>{application["artisan_name"]}</Text>
                        <Text>{application["artisan_message"]}</Text>
                        {/* <Text>{dateFormat(application['date'])}</Text> */}
                        <Box>
                          <Text>TERMINATED</Text>
                          <Text style={{cursor: 'pointer'}} onClick={() =>fetchArtisan(application['artisan_id'])} mb={3} color={"yellow.600"}>Artisan Details & Reviews</Text>
                            <Button
                              flex={1}
                              fontSize={"sm"}
                              width={200}
                              rounded={"full"}
                              bg={"red.600"}
                              color={"white"}
                              _hover={{
                                bg: "red.500",
                              }}
                              _focus={{
                                bg: "red.500",
                              }}
                              onClick={() => {
                                getJobDisputes(activeJob.id);
                                setOpenDispute(true);
                              }}
                              mt={2}
                            >
                              View Disputes
                            </Button>
                        </Box>{" "}
                      </Grid>
                    ) : <></>
                    ) : activeJob["status"] == "Completed" ? (
                      application["decision"] == "Completed" ? (
                        <>
                      <Grid
                      w="full"
                      alignItems="center"
                      justifyContent="center"
                      templateColumns={{
                        base: "1fr 1fr 1fr",
                        md: "1fr 1fr 1fr",
                      }}
                      gap={6}
                      mt={2}
                      mb={8}
                    >
                      <Text>
                        {application["artisan_name"]}
                      </Text>
                      <Text>{application["artisan_message"]}</Text>
                      <Box>
                      <Text>TASK COMPLETED</Text>
                      <Text style={{cursor: 'pointer'}} onClick={() =>fetchArtisan(application['artisan_id'])} mb={3} color={"yellow.600"}>Artisan Details & Reviews</Text>
                      <Button
                        fontSize={"sm"}
                        rounded={"full"}
                        bg={"yellow.600"}
                        color={"white"}
                        _hover={{
                          bg: "yellow.500",
                        }}
                        mr={5}
                        _focus={{
                          bg: "yellow.500",
                        }}
                        onClick={() => {
                          // setJobOpen(false);
                          onClose();
                          getMessages(activeJob.id);
                          setChatOpen(true);
                        }}
                        mt={2}
                      >
                        Messages
                      </Button>
                      </Box>
                    </Grid>
                    {application["status"] == "Completed" ? (
                      <Box mt={5}>
                        <FormLabel>Reviews</FormLabel>
                        {reviews.map((review, i) => {
                          return (
                            <>
                              <Text
                                m={4}
                                mb={8}
                                style={{ fontStyle: "italic" }}
                              >
                                {review["message"]} - {review["fname"]}{" "}
                                {review["lname"]} ({review["date"]}){" "}
                              </Text>
                            </>
                          );
                        })}
                        <FormControl mb={4}>
                          <Textarea
                            value={message}
                            onChange={(event) => setMessage(event.target.value)}
                            placeholder="Write your review here..."
                          />
                          <Button
                            mt={4}
                            flex={1}
                            float={"right"}
                            fontSize={"sm"}
                            width={200}
                            rounded={"full"}
                            bg={"yellow.600"}
                            color={"white"}
                            _hover={{
                              bg: "yellow.500",
                            }}
                            _focus={{
                              bg: "yellow.500",
                            }}
                            onClick={() => {
                              submitReview();
                            }}
                          >
                            Submit Review
                          </Button>
                        </FormControl>
                      </Box>
                    ) : (
                      <></>
                    )}
                    </>
                    ) : <></>
                    ) :activeJob["status"] == "Dispute" ? (
                      application["status"] == "Dispute" ? (
                        <>
                      <Grid
                      w="full"
                      alignItems="center"
                      justifyContent="center"
                      templateColumns={{
                        base: "1fr 1fr 1fr",
                        md: "1fr 1fr 1fr",
                      }}
                      gap={6}
                      mt={2}
                      mb={8}
                    >
                      <Text>
                        {application["artisan_name"]}
                      </Text>
                      <Text>{application["artisan_message"]}</Text>
                      <Box>
                        <Text style={{cursor: 'pointer'}} onClick={() =>fetchArtisan(application['artisan_id'])} mb={3} color={"yellow.600"}>Artisan Details & Reviews</Text>
                        <Text>
                            {loading ? (
                              <Spinner color="white" />
                            ) : (
                              "IN DISPUTE"
                            )}
                        </Text>
                          <Button
                            flex={1}
                            fontSize={"sm"}
                            width={200}
                            rounded={"full"}
                            bg={"red.600"}
                            color={"white"}
                            _hover={{
                              bg: "red.500",
                            }}
                            _focus={{
                              bg: "red.500",
                            }}
                            onClick={() => {
                              setOpenTerminate(true);
                            }}
                            mt={2}
                          >
                            Terminate Job
                          </Button>
                          <Button
                            flex={1}
                            fontSize={"sm"}
                            width={200}
                            rounded={"full"}
                            bg={"green.600"}
                            color={"white"}
                            _hover={{
                              bg: "green.500",
                            }}
                            _focus={{
                              bg: "green.500",
                            }}
                            onClick={() => {
                              // handleUpdate(
                              //   activeJob.id,
                              //   activeApplication.artisan_id,
                              //   "Completed"
                              // );
                              onClose();
                              setOpenCompleted(true);
                            }}
                            mt={2}
                            mr={4}
                          >
                            {loading ? (
                              <Spinner color="white" />
                            ) : (
                              "Task Completed"
                            )}
                          </Button>
                      </Box>
                    </Grid>
                    {application["status"] == "Completed" ? (
                      <Box mt={5}>
                        <FormLabel>Disputes</FormLabel>
                        {disputes.map((dispute, i) => {
                          return (
                            <>
                              <Text
                                m={4}
                                mb={8}
                                style={{ fontStyle: "italic" }}
                              >
                                {dispute["message"]} - {dispute["fname"]}{" "}
                                {dispute["lname"]} ({dispute["date"]}){" "}
                              </Text>
                            </>
                          );
                        })}
                        <FormControl mb={4}>
                          <Textarea
                            value={message}
                            onChange={(event) => setMessage(event.target.value)}
                            placeholder="Write your dispute comments (Decision is subject to our dispute resolution team review)"
                          />
                          <Button
                            mt={4}
                            flex={1}
                            float={"right"}
                            fontSize={"sm"}
                            width={200}
                            rounded={"full"}
                            bg={"yellow.600"}
                            color={"white"}
                            _hover={{
                              bg: "yellow.500",
                            }}
                            _focus={{
                              bg: "yellow.500",
                            }}
                            onClick={() => {
                              submitDispute();
                            }}
                          >
                            Submit Review
                          </Button>
                        </FormControl>
                      </Box>
                    ) : (
                      <></>
                    )}
                    </>
                    ) : <></>
                    ) :(
                      <></>
                    )}
                  </>
                );
              })}
            </ModalBody>
          ) : (
            <>
              <ModalBody>
                <Text>No Artisan Applications yet.</Text>
              </ModalBody>
            </>
          )}
        </ModalContent>
      </Modal>

      {/* Modal End */}


       {/* Chat Modal Start */}

       <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isChatOpen}
        onClose={onClose}
        size={"4xl"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Flex>
              <Avatar
                src={
                  "https://static.vecteezy.com/system/resources/previews/019/896/008/original/male-user-avatar-icon-in-flat-design-style-person-signs-illustration-png.png"
                }
              />
              <Text textAlign={"left"} px={3}>
                {chatMessages.length != 0
                  ? `${artisanData["fname"]} ${artisanData["lname"]}`
                  : "No Messages"}
              </Text>
            </Flex>
          </ModalHeader>
          <ModalCloseButton
            onClick={() => {
              onClose();
              setChatOpen(false);
            }}
          />
          <ModalBody pb={6}>
            <Box
              maxH={"40vh"}
              width={"100%"}
              style={{ overflow: "hidden", overflowY: "scroll" }}
            >
              {chatMessages.map((chat, i) => {
                return (
                  <>
                    {chat["user_id"] != userData.user_id ? (
                      <Flex align={"flex-start"} mb={2}>
                        <Avatar
                          src={
                            "https://static.vecteezy.com/system/resources/previews/019/896/008/original/male-user-avatar-icon-in-flat-design-style-person-signs-illustration-png.png"
                          }
                          width={7}
                          height={7}
                        />
                        <Text
                          textAlign={"left"}
                          px={3}
                          fontSize={13}
                          style={{
                            maxWidth: "50%",
                            backgroundColor: "#a2eb8e4a",
                            margin: 4,
                            padding: 8,
                            borderTopRightRadius: 20,
                            borderBottomRightRadius: 20,
                            borderBottomLeftRadius: 20,
                          }}
                        >
                          {chat["message"]}
                          <br />
                          <span style={{ fontSize: 8 }}>
                            {chat["date"]} - {chat["time"]}
                          </span>
                          <br />
                        </Text>
                      </Flex>
                    ) : (
                      <Flex
                        align={"flex-start"}
                        justifyContent={"flex-end"}
                        mb={2}
                      >
                        <Text
                          textAlign={"right"}
                          px={3}
                          fontSize={13}
                          style={{
                            maxWidth: "50%",
                            backgroundColor: "#ebeb8e4a",
                            margin: 4,
                            padding: 8,
                            borderTopLeftRadius: 20,
                            borderBottomRightRadius: 20,
                            borderBottomLeftRadius: 20,
                          }}
                        >
                          {chat["message"]}
                          <br />
                          <span style={{ fontSize: 10 }}>
                            {chat["date"]} - {chat["time"]}
                          </span>
                          <br />
                        </Text>
                        <Avatar
                          src={
                            "https://static.vecteezy.com/system/resources/previews/019/896/008/original/male-user-avatar-icon-in-flat-design-style-person-signs-illustration-png.png"
                          }
                          width={8}
                          height={8}
                        />
                      </Flex>
                    )}
                  </>
                );
              })}
              <div ref={messagesEndRef} />
            </Box>
            <FormControl mt={4}>
              {/* <FormLabel>Send Message</FormLabel> */}
              <Textarea
                value={message}
                onChange={(event) => setMessage(event.target.value)}
                placeholder="Wrtie your message"
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button
              rounded={"full"}
              bg={"yellow.600"}
              color={"white"}
              _hover={{
                bg: "yellow.500",
              }}
              _focus={{
                bg: "yellow.500",
              }}
              mr={3}
              onClick={() => {
                // setOpenDispute(false);
                sendChatMessage();
              }}
            >
              {loading ? <Spinner color="white" /> : "Send Message"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Chat Modal End */}

      <Grid
        w="full"
        alignItems="center"
        justifyContent="center"
        templateColumns={{ base: "1fr", md: "1fr 1fr" }}
        gap={6}
      >
        <Box>
          <Heading>Your Jobs</Heading>
          <Text mb={4} mt={4}>
            List of Jobs you have completed and current jobs.
          </Text>
        </Box>
        <Box>
          <Link to={"/new-task"}>
            <Button
              rounded={"full"}
              size={"lg"}
              fontWeight={"normal"}
              px={6}
              colorScheme={"red"}
              bg={"yellow.600"}
              _hover={{ bg: "black" }}
              float={"right"}
            >
              Create New Task
            </Button>
          </Link>
        </Box>
      </Grid>

      {jobs.length == 0 ? (
        <>
          <Stack m={"5%"}>
            {/* <Image alignItems={'center'} width={400} src='https://qph.cf2.quoracdn.net/main-qimg-1334ec7bc09de71a2b40337f29c19f9f-lq' alt='No Jobs' mb={4} /> */}
            <Heading textAlign={"center"} color={"yellow.600"}>
              No Tasks
            </Heading>
            <Text textAlign={"center"} mb={4} mt={4}>
              Create New Task and get Professionals!
            </Text>
          </Stack>
        </>
      ) : (
        <></>
      )}
      {jobs.map((job, i) => {
        return (
          <Card
            flexDirection="column"
            boxShadow={"none"}
            p={4}
            w="100%"
            mt={6}
            borderRadius={20}
            key={job["id"]}
          >
            <Grid
              w="full"
              alignItems="center"
              justifyContent="center"
              templateColumns={{
                base: "1fr",
                md: "2fr 1fr 1fr",
              }}
              gap={6}
            >
              <Box>
                <Text
                  color={textColor}
                  fontSize="20px"
                  fontWeight="600"
                  lineHeight="100%"
                  mb={4}
                >
                  {job["title"]}
                </Text>
                <Text
                  fontSize={{ sm: "16px" }}
                  minW={{ sm: "150px", md: "200px", lg: "auto" }}
                  border={"none"}
                >
                  {" "}
                  {job["brief"]}.{" "}
                  <Text
                    mt={4}
                    style={{ textTransform: "uppercase" }}
                    fontStyle={"italic"}
                  >
                    STATUS: {job["status"]}
                  </Text>
                </Text>
              </Box>
              <Box>
                <Text
                  fontSize={{ sm: "16px" }}
                  minW={{ sm: "150px", md: "200px", lg: "auto" }}
                  border={"none"}
                >
                  {dateFormat(job["date_posted"])}
                  {/* {job["rate_p_hr"]}/hr */}
                </Text>
                {/* <Progress
                  hasStripe
                  variant="table"
                  h="8px"
                  w="108px"
                  value={10}
                  display={{ base: "none", md: "block" }}
                /> */}
              </Box>
              <Box>
                <Button
                  flex={1}
                  fontSize={"sm"}
                  rounded={"full"}
                  bg={"yellow.600"}
                  color={"white"}
                  _hover={{
                    bg: "yellow.500",
                  }}
                  _focus={{
                    bg: "yellow.500",
                  }}
                  m={1}
                  onClick={() => {
                    if (job["status"] != "Contract") {
                      setActiveJob(job);
                      onOpen();
                      getJobReviews(job["id"]);
                      // console.log(activeJob);
                    }
                  }}
                >
                  {job["artisan_status"] == "closed"
                    ? job["status"] == "Completed"
                      ? "View Details"
                      : job["status"] == "Contract"
                      ? "Awaiting Contract"
                      : "View Details"
                    : "View Applications"}
                </Button>
                {job["artisan_status"] == "open" ? (
                  <Button
                    flex={1}
                    fontSize={"sm"}
                    rounded={"full"}
                    bg={"red.600"}
                    color={"white"}
                    _hover={{
                      bg: "red.500",
                    }}
                    _focus={{
                      bg: "red.500",
                    }}
                    m={1}
                    onClick={() => {
                      removeJob(job["id"]);
                    }}
                  >
                    {loading ? <Spinner color="white" /> : "Remove Job"}
                  </Button>
                ) : (
                  <></>
                )}

                {/* {job["status"] == "Completed" ? (
                  <Button
                    flex={1}
                    fontSize={"sm"}
                    rounded={"full"}
                    bg={"red.600"}
                    color={"white"}
                    _hover={{
                      bg: "red.500",
                    }}
                    _focus={{
                      bg: "red.500",
                    }}
                    m={1}
                    onClick={() => {
                      setOpenDispute(true);
                    }}
                  >
                    Dispute
                  </Button>
                ) : (
                  <></>
                )} */}
                {/* <Button
                  flex={1}
                  fontSize={"sm"}
                  rounded={"full"}
                  bg={"red.600"}
                  color={"white"}
                  ml={4}
                  _hover={{
                    bg: "red.300",
                  }}
                >
                  Remove
                </Button> */}
              </Box>
            </Grid>
          </Card>
        );
      })}
    </Box>
  );
};

export default UserJobs;
