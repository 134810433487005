import {
  Box,
  Container,
  Heading,
  Text,
  Stack,
  Highlight,
  Flex,
  Grid,
  StackDivider,
  useColorModeValue,
  Image,
  VStack,
} from "@chakra-ui/react";
import Navbar from "../components/navbar/navbar";
import Hero from "../components/hero/hero-section";
import HowitWorks from "../components/howitworks/how";
import Top from "../components/top/top";
import Footer from "../components/footer/footer";
import Testimonials from "../components/testimonials/testimonials";
import Standards from "../components/standards/standards";
export default function Terms() {
  return (
    <Box fontSize="xl">
      <Navbar />
      <Box mt={{ md: 10 }}>
        <Box p={10} m={{ md: 10 }}>
          <VStack paddingTop="40px" spacing="2" alignItems="flex-start">
            <Heading>Privacy Policy</Heading>
            <Text as="b" fontSize="lg">
              Last Updated: 11th March, 2024
            </Text>
            <Text as="p" fontSize="lg">
              Welcome to Sapioworks!
            </Text>
            <Text as="p" fontSize="lg">
            This Privacy Policy explains how we collect, use, disclose, and protect your information when you use our platform. By accessing or using Sapioworks, you consent to the practices described in this Privacy Policy.
            </Text>
            <Text as="b" fontSize="lg">
              1. Information We Collect
            </Text>
            <Text as="p" fontSize="lg">
              By accessing or using Sapioworks, you agree to be bound by these
              Terms of Service. If you do not agree to all the terms and
              conditions, you may not access or use the services.
            </Text>
            <Text as="b" fontSize="lg">
              2. How We Use Your Information
            </Text>
            <Text as="p" fontSize="lg">
              2.1 To use certain features of the platform, you may need to
              register and create an account. You are responsible for
              maintaining the confidentiality of your account information and
              for all activities that occur under your account.
            </Text>
            <Text as="p" fontSize="lg">
              2.2 You must provide accurate and complete information during the
              registration process. You agree to update your information to keep
              it accurate and current.
            </Text>
            <Text as="b" fontSize="lg">
              3. Information Sharing
            </Text>
            <Text as="p" fontSize="lg">
              3.1 You agree not to use Sapioworks for any unlawful or prohibited
              purpose. You are solely responsible for your interactions with
              other users.
            </Text>
            <Text as="p" fontSize="lg">
              3.2 You agree not to post content that is defamatory, abusive,
              harassing, threatening, or otherwise violates any law or right of
              any third party.
            </Text>
            <Text as="b" fontSize="lg">
              4. Security Measures
            </Text>
            <Text as="p" fontSize="lg">
              4.1 Sapioworks connects artisans with clients seeking their
              services. Artisans are responsible for the quality and completion
              of their work.
            </Text>
            <Text as="p" fontSize="lg">
              4.2 Artisans must provide accurate information about their skills
              and qualifications. Sapioworks may verify this information.
            </Text>
            <Text as="b" fontSize="lg">
              5. User Controls
            </Text>
            <Text as="p" fontSize="lg">
              5.1 Clients are responsible for providing accurate project details
              and ensuring a safe working environment for artisans.
            </Text>
            <Text as="p" fontSize="lg">
              5.2 Clients must make payments for services through the platform's
              designated payment system.
            </Text>
            <Text as="b" fontSize="lg">
              6. Cookies and Tracking Technologies
            </Text>
            <Text as="p" fontSize="lg">
              6.1 Sapioworks provides a messaging system for communication
              between artisans and clients. Users are encouraged to communicate
              through the platform for transparency.
            </Text>
            <Text as="p" fontSize="lg">
              6.2 Any attempt to circumvent the platform's communication system
              may result in the suspension or termination of your account.
            </Text>
            <Text as="p" fontSize="lg">
              7. Children's Privacy
            </Text>
            <Text as="b" fontSize="lg">
              7.1 Users are encouraged to leave honest and constructive reviews
              after the completion of a project.
            </Text>
            <Text as="p" fontSize="lg">
              7.2 Sapioworks reserves the right to moderate and remove reviews
              that violate our guidelines.
            </Text>
            <Text as="b" fontSize="lg">
              8. Changes to the Privacy Policy
            </Text>
            <Text as="p" fontSize="lg">
              8.1 Artisans on Sapioworks are covered by insurance, providing
              protection for both artisans and clients in case of accidents or
              damages during a project.
            </Text>
            <Text as="b" fontSize="lg">
              9. Contact Us
            </Text>
            <Text as="p" fontSize="lg">
              Thank you for using Sapioworks! If you have any questions or
              concerns, please contact us at admin@sapioworks.com
            </Text>
          </VStack>
        </Box>

        <HowitWorks />
        <Testimonials />
      </Box>
      <Footer />
    </Box>
  );
}
