import {
  Box,
  Container,
  Heading,
  Text,
  Stack,
  Highlight,
  Flex,
  Grid,
  StackDivider,
  useColorModeValue,
  Image,
} from "@chakra-ui/react";
import Navbar from "../components/navbar/navbar";
import Hero from "../components/hero/hero-section";
import HowitWorks from "../components/howitworks/how";
import Top from "../components/top/top";
import Footer from "../components/footer/footer";
import Testimonials from "../components/testimonials/testimonials";
import Standards from "../components/standards/standards";
export default function About() {
  return (
    <Box textAlign="justify">
      <Navbar />
      <Box mt={{ md: 20 }}>
        <Stack spacing={0} align={"center"} mb={20}>
          <Heading>About SapioWorks</Heading>

          <Heading lineHeight="tall" color={"grey"}>
            {/* <Highlight
              query="craftsmanship meets opportunity"
              styles={{ px: "2", py: "1", rounded: "full", bg: "red.100" }}
            >
              Welcome to Sapioworks – where craftsmanship meets opportunity.
            </Highlight> */}
          </Heading>

          <Box p={10}>
            <Box m={{ md: 20 }}>
              <Stack align={"flex-start"} spacing={4}>
                <Text
                  textTransform={"uppercase"}
                  color={useColorModeValue("black", "yellow.400")}
                  fontWeight={600}
                  fontSize={"sm"}
                  bg={useColorModeValue("yellow.100", "yellow.900")}
                  p={2}
                  alignSelf={"flex-start"}
                  rounded={"md"}
                >
                  Our Story
                </Text>
                <Stack
                  spacing={4}
                  divider={
                    <StackDivider
                      borderColor={useColorModeValue("gray.100", "gray.700")}
                    />
                  }
                >
                  <Text>
                    At Sapioworks, we believe in the power of skilled artisans
                    to transform ideas into reality. Our journey began with a
                    simple yet profound vision - to bridge the gap between
                    talented artisans seeking new opportunities and individuals
                    in need of their exceptional skills. We recognized the
                    challenges artisans face in finding steady work and the
                    frustration clients experience in locating reliable and
                    skilled craftsmen. Sapioworks was born out of this
                    recognition, with a mission to revolutionize the way
                    artisans and clients connect, collaborate, and create.
                  </Text>
                </Stack>
              </Stack>

              <Stack mt={20} align={"flex-start"} spacing={4}>
                <Text
                  textTransform={"uppercase"}
                  color={useColorModeValue("black", "yellow.400")}
                  fontWeight={600}
                  fontSize={"sm"}
                  bg={useColorModeValue("yellow.100", "yellow.900")}
                  p={2}
                  alignSelf={"flex-start"}
                  rounded={"md"}
                >
                  Our Mission
                </Text>
                <Stack
                  spacing={4}
                  divider={
                    <StackDivider
                      borderColor={useColorModeValue("gray.100", "gray.700")}
                    />
                  }
                >
                  <Text>
                    Our primary mission is to empower artisans and elevate the overall experience for clients seeking quality craftsmanship. We are dedicated to providing a platform that not only connects artisans to new job opportunities but also ensures that clients can easily find and engage with skilled professionals for their projects. We envision a world where every artisan has access to a diverse range of projects and where clients can confidently rely on a curated pool of talented craftsmen.
                  </Text>
                </Stack>
              </Stack>

              <Stack mt={20} align={"flex-start"} spacing={4}>
                <Text
                  textTransform={"uppercase"}
                  color={useColorModeValue("black", "yellow.400")}
                  fontWeight={600}
                  fontSize={"sm"}
                  bg={useColorModeValue("yellow.100", "yellow.900")}
                  p={2}
                  alignSelf={"flex-start"}
                  rounded={"md"}
                >
                  Our CSR
                </Text>
                <Stack
                  spacing={4}
                  divider={
                    <StackDivider
                      borderColor={useColorModeValue("gray.100", "gray.700")}
                    />
                  }
                >
                  <Text>
                    Every time a service is delivered, 5%  of your service charge goes into the eco system. The goal is to keep creating opportunities for every human to thrive.
                  </Text>
                </Stack>
              </Stack>
            </Box>
          </Box>

          
        </Stack>
        <HowitWorks />
        <Testimonials />
      </Box>
      <Footer />
    </Box>
  );
}
