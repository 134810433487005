import {
    Box,
    Flex,
    Heading,
    Text,
    Stack,
    Container,
    Avatar,
    useColorModeValue,
  } from '@chakra-ui/react'
  
  interface Props {
    children: React.ReactNode
  }
  
  const Testimonial = (props: Props) => {
    const { children } = props
  
    return <Box>{children}</Box>
  }
  
  const TestimonialContent = (props: Props) => {
    const { children } = props
  
    return (
      <Stack
        bg={useColorModeValue('white', 'gray.800')}
        boxShadow={'lg'}
        p={8}
        rounded={'xl'}
        align={'center'}
        pos={'relative'}
        _after={{
          content: `""`,
          w: 0,
          h: 0,
          borderLeft: 'solid transparent',
          borderLeftWidth: 16,
          borderRight: 'solid transparent',
          borderRightWidth: 16,
          borderTop: 'solid',
          borderTopWidth: 16,
          borderTopColor: useColorModeValue('white', 'gray.800'),
          pos: 'absolute',
          bottom: '-16px',
          left: '50%',
          transform: 'translateX(-50%)',
        }}>
        {children}
      </Stack>
    )
  }
  
  const TestimonialHeading = (props: Props) => {
    const { children } = props
  
    return (
      <Heading as={'h3'} fontSize={'xl'}>
        {children}
      </Heading>
    )
  }
  
  const TestimonialText = (props: Props) => {
    const { children } = props
  
    return (
      <Text
        textAlign={'center'}
        color={useColorModeValue('gray.600', 'gray.400')}
        fontSize={'sm'}>
        {children}
      </Text>
    )
  }
  
  const TestimonialAvatar = ({
    src,
    name,
    title,
  }: {
    src: string
    name: string
    title: string
  }) => {
    return (
      <Flex align={'center'} mt={8} direction={'column'}>
        <Avatar src={src} mb={2} />
        <Stack spacing={-1} align={'center'}>
          <Text fontWeight={600}>{name}</Text>
          <Text fontSize={'sm'} color={useColorModeValue('gray.600', 'gray.400')}>
            {title}
          </Text>
        </Stack>
      </Flex>
    )
  }
  
  
  
  export default function Testimonials() {
    return (
      <Box bg={useColorModeValue('gray.100', 'gray.700')}>
        <Container maxW={'7xl'} py={16} as={Stack} spacing={12}>
          <Stack spacing={0} align={'center'}>
            <Heading>Our Happy Customers</Heading>
            <Text>We have carefully gathered responses from our users across different cities</Text>
          </Stack>
          <Stack
            direction={{ base: 'column', md: 'row' }}
            spacing={{ base: 10, md: 4, lg: 10 }}>
            <Testimonial>
              <TestimonialContent>
                <TestimonialHeading>Efficient Artisan</TestimonialHeading>
                <TestimonialText>
                Exceptional efficiency! The artisan exceeded expectations. Timely, precise work. Grateful for the seamless experience. Highly recommend for top-notch service.
                </TestimonialText>
              </TestimonialContent>
              <TestimonialAvatar
                src={
                  'https://media.istockphoto.com/id/1289461335/photo/portrait-of-a-handsome-black-man.jpg?s=612x612&w=0&k=20&c=gDibbpmkeV04ta3ociwAgpqcjdeU5sI1nnd78wrnz-g='
                }
                name={'Wale'}
                title={'Wuse 2, Abuja'}
              />
            </Testimonial>
            <Testimonial>
              <TestimonialContent>
                <TestimonialHeading>Satisfactory Job</TestimonialHeading>
                <TestimonialText>
                  Prompt and satisfactory delivery. Impressed with the service. Reliable and efficient. Will definitely choose again for future needs. Thank you!
                </TestimonialText>
              </TestimonialContent>
              <TestimonialAvatar
                src={
                  'https://img.freepik.com/free-photo/portrait-ethnic-curly-young-woman-colored-background_23-2148183326.jpg'
                }
                name={'Ijeoma Okowa'}
                title={'Ikoyi, Lagos'}
              />
            </Testimonial>
            <Testimonial>
              <TestimonialContent>
                <TestimonialHeading>Patient and Professional</TestimonialHeading>
                <TestimonialText>
                Incredibly patient and professional artisan. Exceeded expectations with expertise. Appreciated the meticulous work. Highly recommend for exceptional craftsmanship and service.
                </TestimonialText>
              </TestimonialContent>
              <TestimonialAvatar
                src={
                  'https://img.freepik.com/premium-photo/front-view-person-with-short-hair_23-2148749599.jpg'
                }
                name={'Terry'}
                title={'Ikeja, Lagos'}
              />
            </Testimonial>
          </Stack>
        </Container>
      </Box>
    );
  }
  
  