import { ReactNode } from 'react'

import {
  Box,
  Container,
  SimpleGrid,
  Stack,
  Text,
  useColorModeValue,
  Image,
  position
} from '@chakra-ui/react';


import Logo from '../../assets/svg/logo.svg';
import WLogo from '../../assets/svg/logo-white.svg';
import { FaWhatsapp } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import cards from '../../assets/cards.png';


const ListHeader = ({ children }: { children: ReactNode }) => {
  return (
    <Text fontWeight={'500'} fontSize={'lg'} mb={2}>
      {children}
    </Text>
  )
}
  
  
  export default function Footer() {

    const wStyle = {
          position: 'fixed',
          bottom: '10px',
          right: '10px',
          width: '80px',
    };

    return (
        <Box
        bg={useColorModeValue('gray.50', 'gray.900')}
        color={useColorModeValue('gray.700', 'gray.200')}>
        <Container as={Stack} maxW={'6xl'} py={10}>
          <SimpleGrid
            templateColumns={{ base: '1fr 1fr', md: '2fr 1fr 1fr 1fr 1fr' }}
            spacing={8}>
            <Stack spacing={6} align={'flex-start'}>
              <Box>
                <Image
                    alt={"Logo"}
                    align={"center"}
                    src={useColorModeValue(Logo, WLogo)}
                />
              </Box>
              <Text fontSize={'sm'}>© 2024 Sapio Inc.<br/>All rights reserved</Text>
            </Stack>
            <Stack align={'flex-start'}>
              <ListHeader>Company</ListHeader>
              <Box as="a" href={'about'} fontSize={'md'}>
                About
              </Box>
              <Box as="a" href={'contact'} fontSize={'md'}>
                Contact
              </Box>
              {/* <Box as="a" href={'partners'} fontSize={'md'}>
                Partners
              </Box> */}
            </Stack>
            <Stack align={'flex-start'}>
              <ListHeader>Support</ListHeader>
              <Box as="a" href={'help-center'} fontSize={'md'}>
                Help Center
              </Box>
              <Box as="a" href={'terms-of-service'} fontSize={'md'}>
                Terms of Service
              </Box>
              <Box as="a" href={'privacy-policy'} fontSize={'md'}>
                Privacy Policy
              </Box>
            </Stack>
            <Stack align={'flex-start'}>
              <ListHeader>Follow Us</ListHeader>
              {/* <Box as="a" href={'https://www.facebook.com/sapioworks'} fontSize={'md'}>
                Facebook
              </Box>
              <Box as="a" href={'https://www.twitter.com/sapioworks'} fontSize={'md'}>
                Twitter
              </Box> */}
              <Box as="a" href={'https://www.instagram.com/sapio_works?igsh=MWFhM3I2em1ubHU1Ng%3D%3D&utm_source=qr'} fontSize={'md'}>
                Instagram
              </Box>
              <Image mt={4} src={cards} />
            </Stack>
          </SimpleGrid>
            <Link to={"https://wa.me/+2349155212648"} target='_blank' style={{position:'fixed', bottom: '40px', right: '30px'}}>
              <Stack justifyContent={'center'} ml={5}>
                  <FaWhatsapp color='green' size={'46px'}/>
              </Stack>
                  <Text fontSize={14}>Contact us</Text>
            </Link>
        </Container>
      </Box>
    );
  }
  
  