import {
  IconButton,
  Avatar,
  Box,
  CloseButton,
  Flex,
  HStack,
  VStack,
  Icon,
  useColorModeValue,
  Text,
  Drawer,
  DrawerContent,
  useDisclosure,
  BoxProps,
  FlexProps,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  SimpleGrid,
  FormLabel,
  Select,
  Card,
  StatLabel,
  Stat,
  StatNumber,
  Button,
  Checkbox,
  Thead,
  Td,
  Tbody,
  Table,
  Tr,
  Th,
  Image,
  Switch,
} from "@chakra-ui/react";
import {
  FiHome,
  FiTrendingUp,
  FiCompass,
  FiStar,
  FiSettings,
  FiMenu,
  FiBell,
  FiChevronDown,
  FiBarChart,
  FiArrowUp,
  FiCheckCircle,
  FiCreditCard,
} from "react-icons/fi";
import { IconType } from "react-icons";
import { BsPerson } from "react-icons/bs";
import {
  FaUsers,
  FaShoppingCart,
  FaMoneyBillAlt,
  FaWallet,
} from "react-icons/fa";
// import { BarChart } from "@mui/icons-material";
import BarChart from "../../components/charts/BarChart";
import {
  barChartDataConsumption,
  barChartOptionsConsumption,
  lineChartDataTotalSpent,
  lineChartOptionsTotalSpent,
} from "../../components/variables/charts";
import LineChart from "../../components/charts/LineChart";

import Logo from "../../assets/svg/logo.svg";
import WLogo from "../../assets/svg/logo-white.svg";
import { Link, Route, Routes } from "react-router-dom";

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { useState } from "react";
import tableDataCheck from "../../components/variables/tableDataCheck";
import tableDataComplex from "../../components/variables/tableDataComplex";
import ComplexTable from "../../components/tables/ComplexTable";
import { ColorModeSwitcher } from "../../ColorModeSwitcher";
import ArtisanSidebar from "../../components/sidebar/artisan_sidebar";
import ArtisanNavbar from "../../components/navbar/artisan_navbar";
import AdminFooter from "../../components/footer/footerAdmin";
import routes from "../../routes/routes";

import { SidebarContext } from "../../contexts/SidebarContext";

type RowObj = {
  name: [string, boolean];
  progress: string;
  quantity: number;
  date: string;
  info: boolean;
};

const columnHelper = createColumnHelper<RowObj>();

// functions for changing the states from components
const getRoute = () => {
  return window.location.pathname === "/artisan";
};

// const getActiveRoute = (routes: RoutesType[]): string => {
//   let activeRoute = 'Default Brand Text';
//   for (let i = 0; i < routes.length; i++) {
//     if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
//       return routes[i].name;
//     }
//   }
//   return activeRoute;
// };

// const getActiveNavbar = (routes: RoutesType[]): boolean => {
//   let activeNavbar = false;
//   for (let i = 0; i < routes.length; i++) {
//     if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
//       return routes[i].secondary;
//     }
//   }
//   return activeNavbar;
// };

// const getActiveNavbarText = (routes: RoutesType[]): string | boolean => {
//   let activeNavbar = false;
//   for (let i = 0; i < routes.length; i++) {
//     if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
//       return routes[i].name;
//     }
//   }
//   return activeNavbar;
// };

const getRoutes = (routes: RoutesType[]): any => {
  return routes.map((route: RoutesType, key: any) => {
    return <Route path={route.path} Component={route.component} key={key} />;
  });
};

const UserDashboard = () => {
  const [toggleSidebar, setToggleSidebar] = useState(false);

  return (
    <Box minH="100vh" bg={useColorModeValue("gray.100", "gray.900")}>
      <SidebarContext.Provider
        value={{
          toggleSidebar,
          setToggleSidebar,
        }}
      >
        {/* sidebar */}
        <ArtisanSidebar routes={routes} />

        {/* navbar */}
        <ArtisanNavbar />

        <Box ml={{ base: 0, md: 60 }}>
          {/* Content */}

          {/* {getRoute() ? (
          <Box
            mx="auto"
            p={{ base: "20px", md: "30px" }}
            pe="20px"
            minH="100vh"
            pt="50px"
          >
          <Routes>
              {getRoutes(routes)}
          </Routes>
          </Box>
        ) : <Text>Cannot Find Page</Text>} */}

          <Box
            mx="auto"
            p={{ base: "20px", md: "30px" }}
            pe="20px"
            minH="100vh"
            pt="50px"
          >
            <Routes>{getRoutes(routes)}</Routes>
          </Box>

          <AdminFooter />
        </Box>
      </SidebarContext.Provider>
    </Box>
  );
};

export default UserDashboard;
