import {
  Box,
  Flex,
  Icon,
  useColorModeValue,
  Text,
  useDisclosure,
  SimpleGrid,
  Card,
  StatLabel,
  Stat,
  StatNumber,
  Button,
  Checkbox,
  FormControl,
  Avatar,
  AvatarBadge,
  Center,
  FormLabel,
  Heading,
  IconButton,
  Input,
  Stack,
  useToast,
  Spinner,
  Select,
} from "@chakra-ui/react";
import { FiBarChart, FiArrowUp, FiCheckCircle } from "react-icons/fi";
import { FaUsers, FaWallet } from "react-icons/fa";
import BarChart from "../../components/charts/BarChart";
import {
  barChartDataConsumption,
  barChartOptionsConsumption,
  lineChartDataTotalSpent,
  lineChartOptionsTotalSpent,
} from "../../components/variables/charts";
import LineChart from "../../components/charts/LineChart";

import Logo from "../../assets/svg/logo.svg";
import WLogo from "../../assets/svg/logo-white.svg";
import { Link } from "react-router-dom";

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { useEffect, useRef, useState } from "react";
import tableDataCheck from "../../components/variables/tableDataCheck";
import tableDataComplex from "../../components/variables/tableDataComplex";
import ComplexTable from "../../components/tables/ComplexTable";
import { SmallCloseIcon } from "@chakra-ui/icons";
import {
  getCurrentUser,
  updateAcount,
  updatePassword,
  updateProfile,
  uploadAvatar,
} from "../../services/auth.service";
import {
  fetchUserData,
  getServices,
  getUser,
} from "../../services/api.service";

type RowObj = {
  name: [string, boolean];
  progress: string;
  quantity: number;
  date: string;
  info: boolean;
};

const columnHelper = createColumnHelper<RowObj>();

const ArtisanSettings = () => {
  const toast = useToast();

  const { isOpen, onOpen, onClose } = useDisclosure();
  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  // total spent
  const textColorSecondary = useColorModeValue("secondaryGray.600", "white");

  // card
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const iconColor = useColorModeValue("brand.500", "white");
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const bgHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );
  const bgFocus = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.100" }
  );

  const userData = getCurrentUser().user;

  const [bank_name, setBankName] = useState(userData.bank_name);
  const [acc_name, setAccName] = useState(userData.acc_name);
  const [acc_no, setAccNo] = useState(userData.acc_no);

  const [fname, setFname] = useState(userData.fname);
  const [lname, setLname] = useState(userData.lname);
  const [phone, setPhone] = useState(userData.phone);
  const [country, setCountry] = useState(userData.country);
  const [service_two, setService2] = useState(userData.service_two);
  const [service_three, setService3] = useState(userData.service_three);
  const [state, setState] = useState(userData.state);
  const [city, setCity] = useState(userData.city);
  const [sex, setSex] = useState(userData.sex);
  const [age, setAge] = useState(userData.age);
  const [address, setAddress] = useState(userData.address);

  const [facebook, setFacebook] = useState(userData.facebook);
  const [twitter, setTwitter] = useState(userData.twitter);
  const [instagram, setInstagram] = useState(userData.instagram);
  const [tiktok, setTiktok] = useState(userData.tiktok);

  const [npass, setNpass] = useState("");
  const [cpass, setCpass] = useState("");

  const [prloading, setPrLoading] = useState(false);
  const [bloading, setBLoading] = useState(false);
  const [ploading, setPLoading] = useState(false);

  useEffect(() => {
    getUser().then(
      (response) => {
        localStorage.setItem("user", JSON.stringify(response.data));
      },
      (error) => {
        console.log(error.message);
      }
    );
  }, []);

  const updateUserDetails = () => {
    setPrLoading(true);
    updateProfile(
      fname,
      lname,
      phone,
      country,
      service_two,
      service_three,
      state,
      city,
      address,
      facebook,
      twitter,
      instagram,
      tiktok
    ).then(
      (response) => {
        if (response.success) {
          setPrLoading(false);
          toast({
            title: "User Details Updated!",
            description: `${response.message}`,
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          // window.location.href = "/settings"

          setTimeout(function () {
            getUser().then(
              (response) => {
                localStorage.setItem("user", JSON.stringify(response.data));
              },
              (error) => {
                console.log(error.message);
              }
            );
          }, 3000);
        } else {
          setPrLoading(false);
          toast({
            title: "Unable to Update User Details",
            description: `${response.message}`,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      },
      (error) => {
        setPrLoading(false);
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  };

  const updateAccountDetails = () => {
    setBLoading(true);

    updateAcount(bank_name, acc_name, acc_no).then(
      (response) => {
        if (response.success) {
          setBLoading(false);
          toast({
            title: "Bank Details Updated!",
            description: `${response.message}`,
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          // window.location.href = "/settings"

          setTimeout(function () {
            getUser().then(
              (response) => {
                localStorage.setItem("user", JSON.stringify(response.data));
              },
              (error) => {
                console.log(error.message);
              }
            );
          }, 3000);
        } else {
          setBLoading(false);
          toast({
            title: "Unable to Update Bank Details",
            description: `${response.message}`,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      },
      (error) => {
        setBLoading(false);
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  };

  const changePassword = () => {
    setBLoading(true);

    updatePassword(npass).then(
      (response) => {
        if (response.success) {
          setBLoading(false);
          toast({
            title: "Password Updated!",
            description: `${response.message}`,
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          // window.location.href = "/settings"

          setTimeout(function () {
            getUser().then(
              (response) => {
                localStorage.setItem("user", JSON.stringify(response.data));
              },
              (error) => {
                console.log(error.message);
              }
            );
          }, 3000);
        } else {
          setBLoading(false);
          toast({
            title: "Unable to Change Password",
            description: `${response.message}`,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      },
      (error) => {
        setBLoading(false);
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  };

  const avatarUpload = useRef<HTMLInputElement>(null);

  const [image, setImage] = useState("");
  const [uploading, setUploading] = useState(false);
  const handleAvatarUpload = (file: any) => {
    setUploading(true);
    console.log("uploading image: " + file);
    uploadAvatar(file!.target!.files[0]).then(
      (response) => {
        if (response.success) {
          console.log(response.message);
          fetchUserData();
          setTimeout(function () {
            // window.location.href = "/dashboard";
            setUploading(false);
            toast({
              title: "Success!",
              description: `${response.message}`,
              status: "success",
              duration: 3000,
              isClosable: true,
            });
          }, 3000);
        } else {
          setUploading(false);
          console.log(response.message);
        }
      },
      (error) => {
        setUploading(false);
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        console.log(resMessage);
      }
    );
  };

  const [services, setServices] = useState([]);

  useEffect(() => {
    getServices().then(
      (response) => {
        setServices(response.data["data"]);
      },
      (error) => {
        const _services =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();

        setServices(_services);
      }
    );
  }, []);

  return (
    <Box>
      <Box
        bg={useColorModeValue("white", "gray.700")}
        rounded={"xl"}
        boxShadow={"lg"}
        p={6}
      >
        <Heading fontSize={{ base: "2xl", sm: "3xl" }}>Profile</Heading>
        <Stack direction={["column"]} spacing={6}>
          <Center>
            <input
              type="file"
              // value={image}
              onChange={(event) => {
                setImage(event.target.value);
                handleAvatarUpload(event);
              }}
              multiple={false}
              ref={avatarUpload}
              hidden
            />
            <Avatar
              size="xl"
              src={
                userData.avatar ??
                "https://static.vecteezy.com/system/resources/previews/019/896/008/original/male-user-avatar-icon-in-flat-design-style-person-signs-illustration-png.png"
              }
              onClick={() => avatarUpload!.current!.click()}
            >
              <AvatarBadge
                as={IconButton}
                size="sm"
                rounded="full"
                top="-10px"
                colorScheme="red"
                aria-label="remove Image"
                icon={<SmallCloseIcon />}
              />
            </Avatar>
          </Center>
          <Center>
            <Button onClick={() => avatarUpload!.current!.click()}>
              {uploading ? "Uploading..." : "Change Avatar"}
            </Button>
          </Center>
        </Stack>

        <SimpleGrid
          templateColumns={{ sm: "1fr", md: "1fr 1fr" }}
          spacing="10px"
        >
          <FormControl py={3} id="firstname">
            <FormLabel>Firstname</FormLabel>
            <Input
              placeholder="Firstname"
              value={fname}
              _placeholder={{ color: "gray.500" }}
              onChange={(event) => setFname(event.target.value)}
              type="text"
            />
          </FormControl>
          <FormControl py={3} id="lastname">
            <FormLabel>Lastname</FormLabel>
            <Input
              placeholder="Lastname"
              value={lname}
              _placeholder={{ color: "gray.500" }}
              onChange={(event) => setLname(event.target.value)}
              type="text"
            />
          </FormControl>
        </SimpleGrid>
        <SimpleGrid
          templateColumns={{ sm: "1fr", md: "1fr 1fr 1fr 1fr" }}
          spacing="10px"
        >
          <FormControl py={3} id="email">
            <FormLabel>Email</FormLabel>
            <Input
              placeholder="Email"
              value={userData.email}
              _placeholder={{ color: "gray.500" }}
              type="email"
              disabled
            />
          </FormControl>
          <FormControl py={3} id="gender">
            <FormLabel>Gender</FormLabel>
            <Input
              placeholder="Gender"
              value={sex}
              _placeholder={{ color: "gray.500" }}
              type="text"
              disabled={true}
            />
          </FormControl>
          <FormControl py={3} id="age">
            <FormLabel>Age</FormLabel>
            <Input
              placeholder="Age"
              value={age}
              _placeholder={{ color: "gray.500" }}
              type="text"
              disabled={true}
            />
          </FormControl>
          <FormControl py={3} id="telephone">
            <FormLabel>Telephone</FormLabel>
            <Input
              placeholder="Telephone"
              value={phone}
              _placeholder={{ color: "gray.500" }}
              onChange={(event) => setPhone(event.target.value)}
              type="text"
            />
          </FormControl>
        </SimpleGrid>
        <SimpleGrid
          templateColumns={{ sm: "1fr", md: "1fr 1fr" }}
          spacing="10px"
        >
          <FormControl py={3} id="service">
            <FormLabel>Sapio ID</FormLabel>
            <Input
              placeholder="Your Service"
              value={userData.sapio_id}
              _placeholder={{ color: "gray.500" }}
              type="text"
              disabled
            />
          </FormControl>
          <FormControl py={3} id="address">
            <FormLabel>Address</FormLabel>
            <Input
              placeholder="Address"
              value={address}
              _placeholder={{ color: "gray.500" }}
              onChange={(event) => setAddress(event.target.value)}
              type="text"
            />
          </FormControl>
        </SimpleGrid>
        <SimpleGrid
          templateColumns={{
            sm: "1fr",
            md: userData.service != null ? "1fr 1fr 1fr" : "1fr 1fr",
          }}
          spacing="10px"
        >
          {userData.service != null ? (
            <>
              <FormControl py={3} id="service">
                <FormLabel>Artisan Service Type</FormLabel>
                {/* <Input
            placeholder="Your Service"
            value={userData.service}
            _placeholder={{ color: "gray.500" }}
            type="text"
            disabled
          /> */}

                <Select
                  value={userData.service}
                  onChange={(event) => {
                    // setService2(event.target.value);
                  }}
                  disabled
                >
                  <option disabled selected>
                    --Select Service--
                  </option>
                  {services.map((service) => {
                    return (
                      <option value={service["slug"]}>
                        {service["title"]}
                      </option>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl py={3} id="service">
                <FormLabel>Additional Service 2</FormLabel>
                {/* <Input
            placeholder="Your Service"
            value={userData.service_two}
            _placeholder={{ color: "gray.500" }}
            onChange={(event) => setService2(event.target.value)}
            type="text"
          /> */}
                <Select
                  value={service_two}
                  onChange={(event) => {
                    setService2(event.target.value);
                  }}
                >
                  <option disabled selected>
                    --Select Service--
                  </option>
                  {services.map((service) => {
                    return (
                      <option value={service["slug"]}>
                        {service["title"]}
                      </option>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl py={3} id="service">
                <FormLabel>Additional Service 3</FormLabel>
                {/* <Input
            placeholder="Your Service"
            value={userData.service_three}
            _placeholder={{ color: "gray.500" }}
            onChange={(event) => setService3(event.target.value)}
            type="text"
          /> */}

                <Select
                  value={service_three}
                  onChange={(event) => {
                    setService3(event.target.value);
                  }}
                >
                  <option disabled selected>
                    --Select Service--
                  </option>
                  {services.map((service) => {
                    return (
                      <option value={service["slug"]}>
                        {service["title"]}
                      </option>
                    );
                  })}
                </Select>
              </FormControl>
            </>
          ) : (
            <></>
          )}
        </SimpleGrid>
        <SimpleGrid
          templateColumns={{ sm: "1fr", md: "1fr 1fr 1fr" }}
          spacing="10px"
        >
          <FormControl py={3} id="city">
            <FormLabel>City</FormLabel>
            <Input
              placeholder="City"
              value={city}
              _placeholder={{ color: "gray.500" }}
              onChange={(event) => setCity(event.target.value)}
              type="text"
            />
          </FormControl>
          <FormControl py={3} id="state">
            <FormLabel>State</FormLabel>
            <Input
              placeholder="State"
              value={state}
              _placeholder={{ color: "gray.500" }}
              onChange={(event) => setState(event.target.value)}
              type="text"
            />
          </FormControl>
          <FormControl py={3} id="country">
            <FormLabel>Country</FormLabel>
            <Input
              placeholder="Country"
              value={country}
              _placeholder={{ color: "gray.500" }}
              onChange={(event) => setCountry(event.target.value)}
              type="text"
            />
          </FormControl>
        </SimpleGrid>
        <SimpleGrid
          templateColumns={{ sm: "1fr", md: "1fr 1fr 1fr 1fr" }}
          spacing="10px"
        >
          <FormControl py={3} id="facebook">
            <FormLabel>Facebook</FormLabel>
            <Input
              placeholder="Facebook Handle"
              value={facebook}
              _placeholder={{ color: "gray.500" }}
              onChange={(event) => setFacebook(event.target.value)}
              type="text"
            />
          </FormControl>
          <FormControl py={3} id="twitter">
            <FormLabel>Twitter</FormLabel>
            <Input
              placeholder="Twitter Handle"
              value={twitter}
              _placeholder={{ color: "gray.500" }}
              onChange={(event) => setTwitter(event.target.value)}
              type="text"
            />
          </FormControl>
          <FormControl py={3} id="instagram">
            <FormLabel>Instagram</FormLabel>
            <Input
              placeholder="Instagram Handle"
              value={instagram}
              _placeholder={{ color: "gray.500" }}
              onChange={(event) => setInstagram(event.target.value)}
              type="text"
            />
          </FormControl>
          <FormControl py={3} id="tiktok">
            <FormLabel>Tiktok</FormLabel>
            <Input
              placeholder="Tiktok Handle"
              value={tiktok}
              _placeholder={{ color: "gray.500" }}
              onChange={(event) => setTiktok(event.target.value)}
              type="text"
            />
          </FormControl>
        </SimpleGrid>
        <Stack alignItems={"center"} spacing={6}>
          <Button
            bg={"yellow.600"}
            color={"white"}
            mt={5}
            width={{ base: "100%", md: "lg" }}
            _hover={{
              bg: "yellow.500",
            }}
            onClick={() => {
              updateUserDetails();
            }}
          >
            {prloading ? <Spinner color="white" /> : "Update Profile"}
          </Button>
        </Stack>
      </Box>

      <SimpleGrid
        templateColumns={{ sm: "1fr", md: "1fr 1fr" }}
        py={10}
        spacing={10}
      >
        {/* Bank Account */}
        <Box
          bg={useColorModeValue("white", "gray.700")}
          rounded={"xl"}
          boxShadow={"lg"}
          p={6}
        >
          <Heading lineHeight={1.1} fontSize={{ base: "2xl", md: "3xl" }}>
            Bank Account
          </Heading>
          <Text fontSize={14} fontWeight={500} color={"red"} mt={4}>
            Make sure your bank details match your ID details.
          </Text>
          <FormControl py={3} id="bank_name" isRequired>
            <FormLabel>Bank Name</FormLabel>
            <Input
              placeholder="Enter bank name"
              _placeholder={{ color: "gray.500" }}
              value={bank_name}
              onChange={(event) => setBankName(event.target.value)}
              type="text"
            />
          </FormControl>
          <FormControl py={3} id="acc_name" isRequired>
            <FormLabel>Account Name</FormLabel>
            <Input
              placeholder="Enter your name"
              _placeholder={{ color: "gray.500" }}
              value={acc_name}
              onChange={(event) => setAccName(event.target.value)}
              type="text"
            />
          </FormControl>
          <FormControl py={3} id="acc_no" isRequired>
            <FormLabel>Account Number</FormLabel>
            <Input
              type="text"
              _placeholder={{ color: "gray.500" }}
              value={acc_no}
              onChange={(event) => setAccNo(event.target.value)}
            />
          </FormControl>
          <Stack spacing={6}>
            <Button
              bg={"yellow.600"}
              color={"white"}
              mt={5}
              _hover={{
                bg: "yellow.500",
              }}
              onClick={() => {
                updateAccountDetails();
              }}
            >
              {bloading ? <Spinner color="white" /> : "Update Account"}
            </Button>
          </Stack>
        </Box>

        {/* Password Edit */}
        <Box
          bg={useColorModeValue("white", "gray.700")}
          rounded={"xl"}
          boxShadow={"lg"}
          p={6}
        >
          <Heading lineHeight={1.1} fontSize={{ base: "2xl", md: "3xl" }}>
            Change Password
          </Heading>
          <FormControl py={3} id="npassword" isRequired>
            <FormLabel>New Password</FormLabel>
            <Input
              type="password"
              value={npass}
              onChange={(event) => setNpass(event.target.value)}
            />
          </FormControl>
          <FormControl py={3} id="cpassword" isRequired>
            <FormLabel>Confirm New Password</FormLabel>
            <Input
              type="password"
              value={cpass}
              onChange={(event) => setCpass(event.target.value)}
            />
          </FormControl>
          <Stack spacing={6}>
            <Button
              bg={"yellow.600"}
              color={"white"}
              mt={5}
              _hover={{
                bg: "yellow.500",
              }}
              onClick={() => {
                changePassword();
              }}
            >
              {ploading ? <Spinner color="white" /> : "Update Password"}
            </Button>
          </Stack>
        </Box>
      </SimpleGrid>
    </Box>
  );
};

export default ArtisanSettings;
