import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Container,
  Badge,
  Grid,
  Text,
  Heading,
  Stack,
  Avatar,
  Button,
  useColorModeValue,
  Input,
  Select,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
  Textarea,
  Spinner
} from "@chakra-ui/react";
import { BsStar, BsStarFill, BsStarHalf } from "react-icons/bs";
import { FiShoppingCart } from "react-icons/fi";
import { getArtisanExplore } from "../../services/api.service";
import { applyTask } from "../../services/auth.service";
import { currencyFormat, dateFormat } from "../../utils/helper";

export default function ArtisanExplore() {

  const toast = useToast();

  
  const [filter, setFilter] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [loading, setLoading] = useState(false);

  const handleFilterChange = (event:any) => {
    setFilter(event.target.value);
  };

  const handleSortChange = (event:any) => {
    setSortBy(event.target.value);
  };


  const [jobs, setJobs] = useState([]);
  const [rate, setRate] = useState('');
  const [message, setMessage] = useState('');

  const [activeJob, setActiveJob] = useState<{id: number; title: string; brief: string, rate_p_hr: string}>({
    id: 0,
    title: '',
    brief: '',
    rate_p_hr: '',
  });
  
    useEffect(() => {
      getArtisanExplore().then(
        (response) => {
          setJobs(response.data['data']);
        },
        (error) => {
          const _jobs =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();

          setJobs(_jobs);
        }
      );
    }, []);

  


  const handleApply = (job_id: number, rate_p_hr: string,  artisan_message: string) => {

    setLoading(true);
    applyTask(job_id, rate_p_hr, artisan_message).then(
      (response) => {
        if(response.success){
        toast({
          title: 'Submitted!',
          description: `${response.message}`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        setTimeout(function () {
          setLoading(false);
          window.location.href = "/jobs";
      }, 3000);
      }else{
        toast({
          title: 'Unable to Submit Application',
          description: `${response.message}`,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        setLoading(false);
      }
      },
      (error) => {
        setLoading(false);
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  };




  const { isOpen, onOpen, onClose } = useDisclosure();

  const initialRef = useRef(null);
  const finalRef = useRef(null);


  return (
    <Container maxW={"12xl"}>


      {/* Modal Start */}

      <Modal
                initialFocusRef={initialRef}
                finalFocusRef={finalRef}
                isOpen={isOpen}
                onClose={onClose}
            >
                <ModalOverlay />
                <ModalContent>
                <ModalHeader>{activeJob.title}</ModalHeader>
                <Text m={5}>{activeJob.brief}</Text>
                <ModalCloseButton />
                <ModalBody pb={6}>
                    <FormControl>
                    <FormLabel>Date: {dateFormat(activeJob['task_date'])}</FormLabel>
                    <FormLabel>Time: {activeJob['task_time']} GMT+1</FormLabel>
                    <FormLabel>Approximate Duration: {activeJob['approx_duration']} Hours</FormLabel>
                    <FormLabel>Task Rate per Hour: ₦{activeJob.rate_p_hr} </FormLabel>
                    <FormLabel>Total Earning: ₦{parseInt(activeJob.rate_p_hr) * activeJob['approx_duration']} </FormLabel>
                    <FormLabel>Location: {activeJob['state']}</FormLabel>
                    <Input ref={initialRef} 
                      value={activeJob.rate_p_hr}
                      // onChange={(event) => setRate(event.target.value)}
                      disabled
                      placeholder='2000' hidden/>
                    </FormControl>

                    <FormControl mt={4}>
                    <FormLabel>Cover Message</FormLabel>
                    <Textarea 
                      value={message}
                      onChange={(event) => setMessage(event.target.value)} placeholder='Write something about your experience for the job owner to consider (sell yourself)' rows={5} />
                    </FormControl>
                </ModalBody>

                <ModalFooter>
                    <Button 
                      flex={1}
                      fontSize={"sm"}
                      rounded={"full"}
                      bg={"yellow.600"}
                      color={"white"}
                      _hover={{
                        bg: "yellow.500",
                      }}
                      _focus={{
                        bg: "yellow.500",
                      }}
                      onClick={()=>{
                        handleApply(activeJob.id, activeJob.rate_p_hr, message);
                      }}
                      >
                    
                    { loading ? <Spinner color='white' /> :  'Submit Application' }
                    </Button>
                </ModalFooter>
                </ModalContent>
            </Modal>

    {/* Modal End */}

    
      <Box >
        <Stack spacing={0} align={"center"} mb={10}>
          <Heading textAlign={"center"}>
            Explore and Discover Short-term job opportunities available
          </Heading>
          <Text align={"center"} mt={4}>
            Submit your proposals to job posts that match your skills and
            interests, increasing your chances of securing quick and rewarding
            employment.
          </Text>
        </Stack>


        {jobs.length == 0 ? 
      <>
      <Stack m={'10%'}>
            {/* <Image alignItems={'center'} width={400} src='https://qph.cf2.quoracdn.net/main-qimg-1334ec7bc09de71a2b40337f29c19f9f-lq' alt='No Jobs' mb={4} /> */}
            <Heading textAlign={'center'} color={"yellow.600"}>No Jobs Found</Heading>
            <Text textAlign={'center'} mb={4} mt={4}>
              You can check back later!
            </Text>
      </Stack>
      </> :

        <Box mb={10} width={'50%'} float={'right'}>
          {/* <Stack direction="row" spacing={4} align="center">
            <Input
              placeholder="Filter..."
              value={filter}
              onChange={handleFilterChange}
            />
            <Select
              placeholder="Sort By"
              value={sortBy}
              onChange={handleSortChange}
            >
              <option value="name">Name</option>
              <option value="date">Date Posted</option>
              <option value="budget">Budget</option>
            </Select>
          </Stack> */}
        </Box>}
        <Grid
          w="full"
          alignItems="center"
          justifyContent="center"
          templateColumns={{
            base: "1fr",
            md: "1fr 1fr 1fr",
            // xl: "1fr 1fr 1fr 1fr",
          }}
          gap={6}
        >
      {jobs.map((job, i) => {
              return (
                <Box
                  bg="white"
                  _dark={{ bg: "gray.800" }}
                  maxW="sm"
                  rounded="lg"
                  shadow="lg"
                  position="relative"
                  m={1}
                  boxShadow={"2xl"}
                  p={6}
                  textAlign={"center"}
                >
                  <Avatar
                    size={"xl"}
                    // src={
                    //   "https://static.vecteezy.com/system/resources/thumbnails/019/896/012/small_2x/female-user-avatar-icon-in-flat-design-style-person-signs-illustration-png.png"
                    // }
                    src={
                      "https://static.vecteezy.com/system/resources/previews/019/896/008/original/male-user-avatar-icon-in-flat-design-style-person-signs-illustration-png.png"
                    }
                    mb={4}
                    pos={"relative"}
                    _after={{
                      content: '""',
                      w: 4,
                      h: 4,
                      bg: "green.300",
                      border: "2px solid white",
                      rounded: "full",
                      pos: "absolute",
                      bottom: 0,
                      right: 3,
                    }}
                  />
                  <Heading fontSize={"2xl"} fontFamily={"body"}>
                  {job['title']}
                  </Heading>
                  <Text fontWeight={600} color={"gray.500"} mb={4}>
                    @{job['owner_name']}
                  </Text>
                  <Text textAlign={"center"} px={3}>
                    {job['brief']}
                  </Text>
                  <Text textAlign={"center"} mt={4} px={3}>
                    Location: {job['state']}
                  </Text>

                  <Stack
                    align={"center"}
                    justify={"center"}
                    direction={"row"}
                    mt={6}
                  >
                    <Badge px={2} py={1} fontWeight={"400"}>
                    {currencyFormat(parseInt(job['rate_p_hr']))}/hr 
                    </Badge>
                    <Badge px={2} py={1} fontWeight={"400"}>
                    {dateFormat(job['task_date'])}
                    </Badge>
                    <Badge px={2} py={1} fontWeight={"400"}>
                       {job['task_time']} GMT+1
                    </Badge>
                  </Stack>

                  <Stack mt={8} direction={"row"} spacing={4}>
                    {/* <Button
                      flex={1}
                      fontSize={"sm"}
                      rounded={"full"}
                      _focus={{
                        bg: "gray.200",
                      }}
                    >
                      View Job
                    </Button> */}
                    <Button
                      flex={1}
                      fontSize={"sm"}
                      rounded={"full"}
                      bg={"yellow.600"}
                      color={"white"}
                      //   boxShadow={
                      //     "0px 1px 25px -5px rgb(66 153 225 / 48%), 0 10px 10px -5px rgb(66 153 225 / 43%)"
                      //   }
                      _hover={{
                        bg: "yellow.500",
                      }}
                      _focus={{
                        bg: "yellow.500",
                      }}
                      onClick={()=>{
                        setActiveJob(job);
                        onOpen();
                      }}
                    >
                      Apply
                    </Button>
                  </Stack>
                </Box>
              );
            })}
        </Grid>
      </Box>
    </Container>
  );
}
