import moment from "moment";

export function currencyFormat(num: number) {
    return '₦' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
 }

 export function dateFormat(dateValue:  string){
    if(dateValue != null){
    if(dateValue.includes("/")){
    let m = dateValue.split("/")[0];
    let d = dateValue.split("/")[1];
    let y = dateValue.split("/")[2];
    const newDate = y+"-"+m+"-"+d;
    const date =  moment(newDate).format("DD MMM, YYYY");
    return date;
    }else{
        if(dateValue.split("-")[2] != null){
        if(dateValue.split("-")[2].length > 2){
            let d = dateValue.split("-")[0];
            let m = dateValue.split("-")[1];
            let y = dateValue.split("-")[2];
            const newDate = y+"-"+m+"-"+d;
            const date =  moment(newDate).format("DD MMM, YYYY");
            return date;
        }else{
            const date =  moment(dateValue).format("DD MMM, YYYY");
            return date;
        }
        }
    }
    }
};
