// export const baseUrl = "https://c204-212-219-27-53.ngrok-free.app/api/v1/";
export const baseUrl = "https://s-api.sapioworks.com/api/v1/";
// export const baseUrl = "https://api.eminentiagc.com/api/v1/";
// export const baseUrl = "http://localhost:8000/api/v1/";

export const paystackTestPKey = "pk_test_a842c66ae323671a5e629e8970958560b65d3ad6";
export const paystackTestSKey = "pk_test_a842c66ae323671a5e629e8970958560b65d3ad6";

export const paystackLivePKey = "pk_live_d49c929551d27563e6210b84edb8269818fcc75c";
export const paystackLiveSKey = "sk_live_0f1c1ad01621edd88bf907da11610a04cbc38283";
