import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Container,
  Badge,
  Grid,
  Text,
  Heading,
  Stack,
  Avatar,
  Button,
  useColorModeValue,
  Input,
  Select,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
  Textarea,
  Spinner,
  Flex
} from "@chakra-ui/react";
import { BsStar, BsStarFill, BsStarHalf } from "react-icons/bs";
import { FiShoppingCart } from "react-icons/fi";
import { getNotifications } from "../../services/api.service";
import { applyTask } from "../../services/auth.service";
import { currencyFormat, dateFormat } from "../../utils/helper";
import { MdNotifications } from "react-icons/md";

export default function Notifications() {

  const toast = useToast();

  
  const [filter, setFilter] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [loading, setLoading] = useState(false);

  const handleFilterChange = (event:any) => {
    setFilter(event.target.value);
  };

  const handleSortChange = (event:any) => {
    setSortBy(event.target.value);
  };


  const [notifications, setNotifiations] = useState([]);
  const [rate, setRate] = useState('');
  const [message, setMessage] = useState('');

  const [activeNotification, setActiveNotification] = useState<{user_id: string; title: string; message: string, status: number}>({
    user_id: '',
    title: '',
    message: '',
    status: 0
  });
  
    useEffect(() => {
      getNotifications().then(
        (response) => {
          setNotifiations(response.data['data']);
        },
        (error) => {
          const _notifications =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();

          setNotifiations(_notifications);
        }
      );
    }, []);

  


  const handleApply = (job_id: number, rate_p_hr: string,  artisan_message: string) => {

    setLoading(true);
    applyTask(job_id, rate_p_hr, artisan_message).then(
      (response) => {
        if(response.success){
        toast({
          title: 'Submitted!',
          description: `${response.message}`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        setTimeout(function () {
          setLoading(false);
          window.location.href = "/notifications";
      }, 3000);
      }else{
        toast({
          title: 'Unable to Submit Application',
          description: `${response.message}`,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        setLoading(false);
      }
      },
      (error) => {
        setLoading(false);
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  };




  const { isOpen, onOpen, onClose } = useDisclosure();

  const initialRef = useRef(null);
  const finalRef = useRef(null);


  return (
    <Container maxW={"12xl"}>


      {/* Modal Start */}

      <Modal
                initialFocusRef={initialRef}
                finalFocusRef={finalRef}
                isOpen={isOpen}
                onClose={onClose}
            >
                <ModalOverlay />
                <ModalContent>
                <ModalHeader>{activeNotification.title}</ModalHeader>
                <Text m={5}>{activeNotification.message}</Text>
                <ModalCloseButton />
                <ModalBody pb={6}>
                    
                </ModalBody>

                <ModalFooter>
                    <Button 
                      flex={1}
                      fontSize={"sm"}
                      rounded={"full"}
                      bg={"yellow.600"}
                      color={"white"}
                      _hover={{
                        bg: "yellow.500",
                      }}
                      _focus={{
                        bg: "yellow.500",
                      }}
                      onClick={()=>{
                        // handleApply(activeNotification.id, activeNotification.rate_p_hr, message);
                      }}
                      >
                    
                    { loading ? <Spinner color='white' /> :  'Submit Application' }
                    </Button>
                </ModalFooter>
                </ModalContent>
            </Modal>

    {/* Modal End */}

    
      <Box >
        <Stack spacing={0} align={"center"} mb={10}>
          <Heading textAlign={"center"}>
            Notifications
          </Heading>
          {/* <Text align={"center"} mt={4}>
            Stay up to date with information
          </Text> */}
        </Stack>


        {notifications.length == 0 ? 
      <>
      <Stack m={'10%'}>
            {/* <Image alignItems={'center'} width={400} src='https://qph.cf2.quoracdn.net/main-qimg-1334ec7bc09de71a2b40337f29c19f9f-lq' alt='No notifications' mb={4} /> */}
            <Heading textAlign={'center'} color={"yellow.600"}>No notifications found</Heading>
            <Text textAlign={'center'} mb={4} mt={4}>
              You don't have any notifications now!
            </Text>
      </Stack>
      </> :

        <Box mb={10} width={'50%'} float={'right'}></Box>}
        <Grid
          w="full"
          alignItems="center"
          justifyContent="center"
          templateColumns={{
            base: "1fr",
            md: "1fr",
            // xl: "1fr 1fr 1fr 1fr",
          }}
          gap={6}
        >
      {notifications.map((notification, i) => {
              return (
                <Box
                  bg="white"
                  _dark={{ bg: "gray.800" }}
                //   maxW="sm"
                  rounded="lg"
                  shadow="sm"
                  position="relative"
                  m={1}
                //   boxShadow={"2xl"}
                  p={6}
                >
                <Flex align={'center'} justifyContent={'space-between'}>
                <Flex>
                  <MdNotifications size={18} />
                  <Text textAlign={"center"} px={3}>
                  {notification['message']}<br/>
                  </Text>
                  </Flex>
                  <Text textAlign={"center"} px={3} fontSize={14}>
                  {dateFormat(notification['created_at'])}<br/>
                  </Text>

                    {/* <Button
                      flex={1}
                      fontSize={"sm"}
                      rounded={"full"}
                      bg={"yellow.600"}
                      color={"white"}
                      //   boxShadow={
                      //     "0px 1px 25px -5px rgb(66 153 225 / 48%), 0 10px 10px -5px rgb(66 153 225 / 43%)"
                      //   }
                      _hover={{
                        bg: "yellow.500",
                      }}
                      _focus={{
                        bg: "yellow.500",
                      }}
                      onClick={()=>{
                        setActiveNotification(notification);
                        onOpen();
                      }}
                    >
                      Apply
                    </Button> */}
                  </Flex>
                </Box>
              );
            })}
        </Grid>
      </Box>
    </Container>
  );
}
