import { Box, Container } from "@chakra-ui/react";
import Navbar from "../components/navbar/navbar";
import Hero from "../components/hero/hero-section";
import HowitWorks from "../components/howitworks/how";
import Top from "../components/top/top";
import Footer from "../components/footer/footer";
import Testimonials from "../components/testimonials/testimonials";
import Standards from "../components/standards/standards";
export default function Home() {
  return (
    <Box textAlign="center" fontSize="xl">
      <Navbar />
      <Box>
        <Hero />
        <Standards />
        {/* <Top /> */}
        <HowitWorks />
        <Testimonials />
      </Box>
      <Footer/>
    </Box>
  );
}
