import {
    Box,
    Flex,
    Icon,
    useColorModeValue,
    Text,
    useDisclosure,
    SimpleGrid,
    Card,
    StatLabel,
    Stat,
    StatNumber,
    Button,
    Checkbox,
    Heading,
    Stack,
    Image,
    useToast,
  } from "@chakra-ui/react";
  import {
    FiBarChart,
    FiArrowUp,
    FiCheckCircle,
  } from "react-icons/fi";
  import { FaIdBadge, FaUsers, FaWallet } from "react-icons/fa";
  import BarChart from '../../components/charts/BarChart';
  import { barChartDataConsumption, barChartDataDailyTraffic, barChartOptionsConsumption, barChartOptionsDailyTraffic, lineChartDataTotalSpent, lineChartOptionsTotalSpent } from "../../components/variables/charts";
  import LineChart from "../../components/charts/LineChart";
  
  
  import Logo from '../../assets/svg/logo.svg';
  import WLogo from '../../assets/svg/logo-white.svg';
  import { Link, useNavigate } from "react-router-dom";


  import CopyToClipboard from '@uiw/react-copy-to-clipboard';
  
  
  import {
      createColumnHelper
  } from '@tanstack/react-table';
  import { useEffect, useState } from "react";
  import tableDataCheck from "../../components/variables/tableDataCheck";
  import tableDataComplex from "../../components/variables/tableDataComplex";
  import ComplexTable from "../../components/tables/ComplexTable";
  import { fetchUserData, getArtisanDashboard, getArtisanJobs, getUserDashboard } from "../../services/api.service";
  import { getCurrentUser } from "../../services/auth.service";
  import { CopyIcon } from "@chakra-ui/icons";


  import EarningImage from '../../assets/earnings.png';
  
  
  type RowObj = {
      name: [string, boolean];
      progress: string;
      quantity: number;
      date: string;
      info: boolean;
  };
   
  const columnHelper = createColumnHelper<RowObj>();
  
  const ArtisanDashboard = () => {
    const toast = useToast();
    const navigate = useNavigate();
    const { isOpen, onOpen, onClose } = useDisclosure();
    // Chakra Color Mode
    const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  
  
    // total spent
      const textColorSecondary = useColorModeValue('secondaryGray.600', 'white');
  
    // card
      const textColor = useColorModeValue('secondaryGray.900', 'white');
      const iconColor = useColorModeValue('brand.500', 'white');
      const bgButton = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
      const bgHover = useColorModeValue({ bg: 'secondaryGray.400' }, { bg: 'whiteAlpha.50' });
      const bgFocus = useColorModeValue({ bg: 'secondaryGray.300' }, { bg: 'whiteAlpha.100' });


      const userData = getCurrentUser();
      const [jobs, setJobs] = useState([]);
      const [balance, setBalance] = useState(0);
      const [completed, setCompleted] = useState(0);
      const [applications, setApplications] = useState([]);
  
      useEffect(() => {
        fetchUserData();
        userData.user.user_level == 1 ?
        getArtisanDashboard().then(
          (response) => {
            setJobs(response.data['jobs']);
            setApplications(response.data['applications']);
            setBalance(response.data['wallet']['balance']);
            setCompleted(response.data['completed']);
          },
          (error) => {
            console.log(error.message);
          }
        ) : getUserDashboard().then(
          (response) => {
            setJobs(response.data['jobs']);
            setBalance(response.data['wallet']['balance']);
            setCompleted(response.data['completed']);
          },
          (error) => {
            console.log(error.message);
          }
        )
      }, []);
  
    return (
        <Box>
        <SimpleGrid templateColumns={{ sm: '1fr', md: '5fr 3fr' }} spacing="10px">
          <SimpleGrid templateColumns={{ sm: '1fr 1fr', md: '1fr 1fr' }} spacing="10px">
            <Box bg={useColorModeValue("white", "gray.800")} p={4} boxShadow={"none"} borderRadius={20}>
              <Flex>
                <Box color={useColorModeValue("gray.800", "gray.200")} mr={5}>
                <Flex alignItems={'center'} justifyContent={'center'} borderRadius={'50%'} bg={"yellow.100"} p={4}>
                    <Box color="yellow.500">
                      <FaIdBadge size={32} />
                    </Box>
                </Flex>
                </Box>
                <Box>
                  <Stat>
                    {/* <StatLabel>{ userData.user.user_level == 1 ?  'Total Applications' : 'Total Jobs'}</StatLabel>
                    <StatNumber>{userData.user.user_level == 1 ? applications.length : jobs.length}</StatNumber> */}
                    <StatLabel>Sapio ID</StatLabel>
                    <StatNumber>{userData.user.sapio_id}</StatNumber>
                    <CopyToClipboard text={userData.user.sapio_id}
                      onClick={() => {
                          toast({
                            title: 'Copy',
                            description: `Copied Sapio ID!`,
                            status: 'success',
                            duration: 3000,
                            isClosable: true,
                          });
                      }}>
                      <CopyIcon />copy
                    </CopyToClipboard>
                  </Stat>
                </Box>
              </Flex>
            </Box>
            <Box bg={useColorModeValue("white", "gray.800")} p={4} boxShadow={"none"} borderRadius={20}>
              <Flex>
                <Box color={useColorModeValue("gray.800", "gray.200")} mr={5}>
                <Flex alignItems={'center'} justifyContent={'center'} borderRadius={'50%'} bg={"yellow.100"} p={4}>
                    <Box color="yellow.500">
                      <FaWallet size={32} />
                    </Box>
                </Flex>
                </Box>
                <Box>
                  <Stat>
                    <StatLabel>My Jobs</StatLabel>
                    <StatNumber>{jobs.length}</StatNumber>
                  </Stat>
                </Box>
              </Flex>
            </Box>
          </SimpleGrid>
          <Box bg={useColorModeValue("white", "gray.800")} p={4} boxShadow={"none"} borderRadius={20}>
              <Flex>
                <Box color={useColorModeValue("gray.800", "gray.200")} mr={5}>
                <Flex alignItems={'center'} justifyContent={'center'} borderRadius={'50%'} bg={"yellow.100"} p={4}>
                  <Box color="yellow.500">
                    <FaUsers size={32} />
                    </Box>
                </Flex>
                </Box>
                <Box>
                  <Stat>
                    <StatLabel>Completed Jobs</StatLabel>
                    <StatNumber>{completed}</StatNumber>
                  </Stat>
                </Box>
              </Flex>
            </Box>
        </SimpleGrid>
        <SimpleGrid templateColumns={{ sm: '1fr', md: '1fr 1fr' }} spacing="30px" mt={6}>
            <Card justifyContent='center' alignItems='center' flexDirection='column' boxShadow={"none"} p={4} w='100%' mb='0px' borderRadius={20}>
            
            <Flex w='100%' flexDirection={{ base: 'column', lg: 'row' }}>
              <Flex flexDirection='column' me='20px'>
                <Text color={textColor} fontSize='34px' textAlign='start' fontWeight='700' lineHeight='100%'>
                ₦{balance.toFixed(2) ?? 0}
                </Text>
                <Flex align='center' mb='20px'>
                  <Text color='secondaryGray.600' fontSize='sm' fontWeight='500' mt='4px' me='12px'>
                    Total Inflow
                  </Text>
                </Flex>
  
                <Flex align='center' onClick={()=>{navigate('/wallet')}}>
                  <Icon as={FiCheckCircle} color='green.500' me='4px' />
                  <Text color='green.500' fontSize='md' fontWeight='700'>
                    Go to Wallet
                  </Text>
                </Flex>
              </Flex>
              <Box minH='200px' minW='25%' mt='auto'>
                  <Image
                    alt={"Earnings Image"}
                    fit={"cover"}
                    align={"center"}
                    src={EarningImage}
                  />
              </Box>
            </Flex>
          </Card>
  
  
          <Card alignItems='center' flexDirection='column' boxShadow={"none"} p={4} w='100%' borderRadius={20}>
            <Flex align='center' w='100%' px='15px' py='10px'>
              <Text me='auto' color={textColor} fontSize='xl' fontWeight='700' lineHeight='100%'>
                Earnings Chart
              </Text>
              <Button
                alignItems='center'
                justifyContent='center'
                bg={bgButton}
                _hover={bgHover}
                _focus={bgFocus}
                _active={bgFocus}
                w='37px'
                h='37px'
                lineHeight='100%'
                borderRadius='10px'>
                <Box color={useColorModeValue("gray.800", "gray.200")} mr={5}>
              <Flex alignItems={'center'} justifyContent={'center'} borderRadius={'50%'} bg={"yellow.100"} p={4}>
                  <Box color="yellow.500">
                      <FiBarChart size={32} />
                  </Box>
              </Flex>
              </Box>
              </Button>
            </Flex>
  
            <Box h='240px' mt='auto' w='100%'>
                <LineChart chartData={lineChartDataTotalSpent} chartOptions={lineChartOptionsTotalSpent} />
            </Box>
          </Card>
        </SimpleGrid>
        {
        userData.user.user_level == 1 ?
        applications.length == 0 ? 
      <>
      <Stack m={'10%'}>
            {/* <Image alignItems={'center'} width={400} src='https://qph.cf2.quoracdn.net/main-qimg-1334ec7bc09de71a2b40337f29c19f9f-lq' alt='No Jobs' mb={4} /> */}
            <Heading textAlign={'center'} color={"yellow.600"}>No Active Applications</Heading>
            <Text textAlign={'center'} mb={4} mt={4}>
              {userData.user.user_level == 1 ? 'Go to explore and start applying for jobs!' : 'Find Professional Artisans when you publish your task'}
            </Text>
      </Stack>
      </> : 
      <ComplexTable  tableData={applications}/> : <></>
        }
        </Box>
    );
  };
  
  export default ArtisanDashboard;
  