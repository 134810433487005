import {
  Box,
  Text,
  Heading,
  Stack,
  Card,
  Flex,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Progress,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  GridItem,
  Input,
  Select,
  SimpleGrid,
  Textarea,
  ButtonGroup,
  Container,
  useToast,
  Image,
  FormErrorMessage,
  HStack,
  PinInput,
  PinInputField,
  Center,
  Spinner,
} from "@chakra-ui/react";
import { flexRender } from "@tanstack/react-table";
import { table } from "console";
import { useState, useEffect } from "react";
import { getServices } from "../../services/api.service";
import {
  createTask,
  getCurrentUser,
  uploadFile,
  uploadImage,
  verifyUser,
} from "../../services/auth.service";
import { usePaystackPayment } from "react-paystack";
import { FaPlusCircle } from "react-icons/fa";
import { UploadDropzone } from "@bytescale/upload-widget-react";
import { paystackLivePKey, paystackTestPKey } from "../../utils/constants";

const Verification = () => {
  const cardBg = useColorModeValue("white", "gray.900");
  const inputBg = useColorModeValue("white", "gray.900");
  const inputColor = useColorModeValue("gray.900", "white");

  const toast = useToast();
  const [step, setStep] = useState(1);
  const [progress, setProgress] = useState(33.33);

  const [g1Name, setg1Name] = useState("");
  const [g1Address, setg1Address] = useState("");
  const [g1Telephone, setg1Telephone] = useState("");
  const [g2Name, setg2Name] = useState("");
  const [g2Address, setg2Address] = useState("");
  const [g2Telephone, setg2Telephone] = useState("");

  const [g1NameError, setg1NameError] = useState("");
  const [g1AddressError, setg1AddressError] = useState("");
  const [g1TelephoneError, setg1TelephoneError] = useState("");
  const [g2NameError, setg2NameError] = useState("");
  const [g2AddressError, setg2AddressError] = useState("");
  const [g2TelephoneError, setg2TelephoneError] = useState("");

  const [selectedId, setSelectedID] = useState("");
  const [address, setAddress] = useState("");

  const [successful, setSuccessful] = useState<boolean>(false);
  const [uploadingImage, setUploading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");



  const [idImg, setIdImg] = useState("");
  const [img1, setImg1] = useState("");
  const [img2, setImg2] = useState("");
  const [img3, setImg3] = useState("");



  const [facebook, setFacebook] = useState("");
  const [twitter, setTwitter] = useState("");
  const [instagram, setInstagram] = useState("");
  const [tiktok, setTiktok] = useState("");

  const userData = getCurrentUser();

  // Paystack
  const config = {
    reference: new Date().getTime().toString(),
    email: userData.user.email,
    amount: parseInt("100000"), //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
    // publicKey: paystackTestPKey,
    publicKey: paystackLivePKey,
  };

  // you can call this function anything
  const onSuccess = () => {
    // Implementation for whatever you want to do with reference and after success call.
    // console.log('payment complete');
    handleSubmit();
  };

  const initializePayment = usePaystackPayment(config);

  const handleSubmit = () => {
    verifyUser(g1Name,g1Telephone,g1Address,g2Name,g2Telephone,g2Address, idImg, img1, facebook, twitter, instagram, tiktok).then(
      (response) => {
        if (response.success) {
          toast({
            title: "Verification Request Submitted!",
            description: `${response.message}`,
            status: "success",
            duration: 10000,
            isClosable: true,
          });
          setSuccessful(response.success);
          setTimeout(function () {
            window.location.href = "/settings";
          }, 3000);
        } else {
          toast({
            title: "Unable to Process Verification!",
            description: `${response.message}`,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
          setSuccessful(response.success);
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setMessage(resMessage);
        setSuccessful(false);
      }
    );
  };

  const options = {
    apiKey: "free", // Get API keys from: www.bytescale.com
    maxFileCount: 10,
  };



  const handleUpload = (file: any, type: any) => {
    setUploading(true);
    uploadImage(file!.target!.files[0]).then(
      (response) => {
        if(response.success){
          console.log(response.message);
          setTimeout(function () {
            // window.location.href = "/dashboard";
            setUploading(false);
              type(response.imageUrl);
              toast({
                title: 'Success!',
                description: `${response.message}`,
                status: 'success',
                duration: 3000,
                isClosable: true,
              });
            }, 3000);
      }else{
        setUploading(false);
        console.log(response.message);
      }
      },
      (error) => {
        setUploading(false);
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
          console.log(resMessage);
      }
    );
  };

  const Form1 = () => {
    const [show, setShow] = useState(false);
    const handleClick = () => setShow(!show);

    const [services, setServices] = useState([]);

    useEffect(() => {
      getServices().then(
        (response) => {
          setServices(response.data["data"]);
        },
        (error) => {
          const _services =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();

          setServices(_services);
        }
      );
    }, []);

    return (
      <Box>
        <Heading fontSize={"lg"} textAlign={"center"} fontWeight="bold" mb="2%">
          Please confirm this is your address?
        </Heading>
        <Flex justifyContent={"center"} m={5}>
          <Image
            alt={"Location Image"}
            width={200}
            src="https://akm-img-a-in.tosshub.com/indiatoday/images/story/201808/map-1272165_960_720_0.png?size=1200:675"
          />
        </Flex>
        <Text textAlign={"center"}>
          {userData.user.address}, {userData.user.state}.
        </Text>
      </Box>
    );
  };

  return (
    <Box>
      <Stack spacing={0} align={"center"} mb={10}>
        <Heading textAlign={"center"}>Complete Account Verification</Heading>
        <Text align={"center"} mt={4}>
          Follow the steps below and provide necessary data to complete your
          verification for approval
        </Text>
      </Stack>

     {/*  <Box>
        <Container maxW={"7xl"} mt={10}>
          <Box m={{ md: 20 }} mb={{ sm: 20 }}> */}
            <Card
              maxW={"xl"}
              minH={"l"}
              mx={"auto"}
              mb={10}
              px={6}
              p={10}
              borderRadius={10}
            >
              <Progress
                colorScheme="yellow"
                hasStripe
                value={progress}
                mb="5%"
                isAnimated
              ></Progress>
              {step === 1 ? (
                <Form1 />
              ) : step === 2 ? (
                <>
                  <Heading
                    fontSize={"lg"}
                    textAlign={"center"}
                    fontWeight="bold"
                    mb="5%"
                  >
                    Upload the following documents
                  </Heading>
                  <Text fontSize={14} fontWeight={500} color={'red'} mt={4}>
                    {uploadingImage ? <><Spinner color='red.500' /> Uploading Image...</> : ''}
                  </Text>
                  <FormControl as={GridItem} colSpan={[6, 3]}>
                    <FormLabel
                      htmlFor="country"
                      fontSize="sm"
                      fontWeight="md"
                      color="gray.700"
                      _dark={{
                        color: "gray.50",
                      }}
                    >
                      Select ID Type
                    </FormLabel>
                    <Select
                      id="country"
                      name="country"
                      autoComplete="country"
                      placeholder="Select option"
                      focusBorderColor="brand.400"
                      shadow="sm"
                      w="full"
                      rounded="md"
                      value={selectedId}
                      onChange={(event) => setSelectedID(event.target.value)}
                    >
                      <option disabled selected>
                        --Select ID--
                      </option>
                      <option value="Int'l Passport">Int'l Passport</option>
                      <option value="Driver's License">Driver's License</option>
                      <option value="National Identification (NIN)">
                        National Identification (NIN)
                      </option>
                    </Select>
                  </FormControl>

                  {selectedId != "" ? (
                    <FormControl as={GridItem} colSpan={6}>
                      <FormLabel
                        fontSize="sm"
                        fontWeight="md"
                        color="gray.700"
                        _dark={{
                          color: "gray.50",
                        }}
                        mt="4%"
                      >
                        Upload {selectedId}
                      </FormLabel>
                      <Input
                        type="file"
                        name="id_img"
                        id="id_img"
                        focusBorderColor="brand.400"
                        shadow="sm"
                        w="full"
                        rounded="md"
                        // onChange={(event) => setAddress(event.target.value)}
                          onChange={(event)=>{
                            handleUpload(event, setIdImg);
                          }} 
                      />
                    </FormControl>
                  ) : (
                    <></>
                  )}

                  {idImg != "" ? <FormControl as={GridItem} colSpan={6}>
                    <FormLabel
                      fontSize="sm"
                      fontWeight="md"
                      color="gray.700"
                      _dark={{
                        color: "gray.50",
                      }}
                      mt="4%"
                    >
                      Upload Picture of you holding your ID to your face
                    </FormLabel>
                    <Input
                      type="file"
                      name="img1"
                      id="img1"
                      focusBorderColor="brand.400"
                      shadow="sm"
                      w="full"
                      rounded="md"
                      // onChange={(event) => setAddress(event.target.value)}
                        onChange={(event)=>{
                          handleUpload(event, setImg1);
                        }} 
                    />
                  </FormControl> : <></> }

                  <FormControl as={GridItem} colSpan={6}>
                    <FormLabel
                      fontSize="sm"
                      fontWeight="md"
                      color="gray.700"
                      _dark={{
                        color: "gray.50",
                      }}
                      mt="4%"
                    >
                      Add links of your social media business page of your offering service
                    </FormLabel>
                    <Text fontSize={10} color={"red"}>
                      (Clearly showing your face)
                    </Text>
                    <Box width={"80%"}>

            {/* Changed to social media */}

            <FormControl py={3} id="facebook">
              <FormLabel>Facebook</FormLabel>
              <Input
                placeholder="Facebook Handle"
                value={facebook}
                _placeholder={{ color: "gray.500" }}
                onChange={(event) => setFacebook(event.target.value)}
                type="text"
              />
            </FormControl>
            <FormControl py={3} id="twitter">
              <FormLabel>Twitter</FormLabel>
              <Input
                placeholder="Twitter Handle"
                value={twitter}
                _placeholder={{ color: "gray.500" }}
                onChange={(event) => setTwitter(event.target.value)}
                type="text"
              />
            </FormControl>
            <FormControl py={3} id="instagram">
              <FormLabel>Instagram</FormLabel>
              <Input
                placeholder="Instagram Handle"
                value={instagram}
                _placeholder={{ color: "gray.500" }}
                onChange={(event) => setInstagram(event.target.value)}
                type="text"
              />
            </FormControl>
            <FormControl py={3} id="tiktok">
              <FormLabel>Tiktok</FormLabel>
              <Input
                placeholder="Tiktok Handle"
                value={tiktok}
                _placeholder={{ color: "gray.500" }}
                onChange={(event) => setTiktok(event.target.value)}
                type="text"
              />
            </FormControl>
            {/* Disabled the upload image functions */}

                    {/* {img1 != "" ? <FormControl as={GridItem} colSpan={6}>
                      <FormLabel
                        fontSize="sm"
                        fontWeight="md"
                        color="gray.700"
                        _dark={{
                          color: "gray.50",
                        }}
                        mt="4%"
                      >
                        Upload Image 2
                      </FormLabel>
                      <Input
                        type="file"
                        name="img2"
                        id="img2"
                        focusBorderColor="brand.400"
                        shadow="sm"
                        w="full"
                        rounded="md"
                        // onChange={(event) => setAddress(event.target.value)}
                          onChange={(event)=>{
                            handleUpload(event, setImg2);
                          }} 
                      />
                    </FormControl> : <></> }

                    {img2 != "" ? <FormControl as={GridItem} colSpan={6}>
                      <FormLabel
                        fontSize="sm"
                        fontWeight="md"
                        color="gray.700"
                        _dark={{
                          color: "gray.50",
                        }}
                        mt="4%"
                      >
                        Upload Image 3
                      </FormLabel>
                      <Input
                        type="file"
                        name="img3"
                        id="img3"
                        focusBorderColor="brand.400"
                        shadow="sm"
                        w="full"
                        rounded="md"
                        // onChange={(event) => setAddress(event.target.value)}
                          onChange={(event)=>{
                            handleUpload(event, setImg3);
                          }} 
                      />
                    </FormControl> 
                    : <></> } */}
                    </Box>
                  </FormControl>
                </>
              ) : (
                <>
                  <Heading
                    fontSize={"lg"}
                    textAlign={"center"}
                    fontWeight="bold"
                    mb="10%"
                  >
                    Guarantors Details
                  </Heading>
                  <Box mb={10}>
                    <Text fontWeight="bold">Gurantor 1</Text>
                    <FormControl
                      id="g1Name"
                      className="mb-"
                      mb={4}
                      isInvalid={!!g1NameError}
                    >
                      <FormLabel fontWeight={"normal"}>Full Name</FormLabel>
                      <Input
                        type="text"
                        value={g1Name}
                        onChange={(event) => setg1Name(event.target.value)}
                        bg={inputBg}
                        color={inputColor}
                        // required
                      />
                      <FormErrorMessage>{g1NameError}</FormErrorMessage>
                    </FormControl>
                    <FormControl
                      id="g1Address"
                      className="mb-"
                      mb={4}
                      isInvalid={!!g1AddressError}
                    >
                      <FormLabel fontWeight={"normal"}>Address</FormLabel>
                      <Input
                        type="text"
                        value={g1Address}
                        onChange={(event) => setg1Address(event.target.value)}
                        bg={inputBg}
                        color={inputColor}
                        // required
                      />
                      <FormErrorMessage>{g1AddressError}</FormErrorMessage>
                    </FormControl>
                    <FormControl
                      id="g1Telephone"
                      className="mb-"
                      mb={4}
                      isInvalid={!!g1TelephoneError}
                    >
                      <FormLabel fontWeight={"normal"}>
                        Telephone Number
                      </FormLabel>
                      <Input
                        type="text"
                        value={g1Telephone}
                        onChange={(event) => setg1Telephone(event.target.value)}
                        bg={inputBg}
                        color={inputColor}
                        // required
                      />
                      <FormErrorMessage>{g1TelephoneError}</FormErrorMessage>
                    </FormControl>
                    <Text fontWeight="bold">Gurantor 2</Text>
                    <FormControl
                      id="g2Name"
                      className="mb-"
                      mb={4}
                      isInvalid={!!g2NameError}
                    >
                      <FormLabel fontWeight={"normal"}>Full Name</FormLabel>
                      <Input
                        type="text"
                        value={g2Name}
                        onChange={(event) => setg2Name(event.target.value)}
                        bg={inputBg}
                        color={inputColor}
                        // required
                      />
                      <FormErrorMessage>{g2NameError}</FormErrorMessage>
                    </FormControl>
                    <FormControl
                      id="g2Address"
                      className="mb-"
                      mb={4}
                      isInvalid={!!g2AddressError}
                    >
                      <FormLabel fontWeight={"normal"}>Address</FormLabel>
                      <Input
                        type="text"
                        value={g2Address}
                        onChange={(event) => setg2Address(event.target.value)}
                        bg={inputBg}
                        color={inputColor}
                        // required
                      />
                      <FormErrorMessage>{g2AddressError}</FormErrorMessage>
                    </FormControl>
                    <FormControl
                      id="g2Telephone"
                      className="mb-"
                      mb={4}
                      isInvalid={!!g2TelephoneError}
                    >
                      <FormLabel fontWeight={"normal"}>
                        Telephone Number
                      </FormLabel>
                      <Input
                        type="text"
                        value={g2Telephone}
                        onChange={(event) => setg2Telephone(event.target.value)}
                        bg={inputBg}
                        color={inputColor}
                        // required
                      />
                      <FormErrorMessage>{g2TelephoneError}</FormErrorMessage>
                    </FormControl>

                    <Text fontSize="xs" color="red.600">
                      NOTE: Ensure your provide all necessary details before
                      clicking complete, verification fee is non refundable and
                      applies per verification request.
                    </Text>
                  </Box>
                </>
              )}
              <ButtonGroup mt="5%" w="100%">
                <Flex w="100%" justifyContent="space-between">
                  <Flex>
                    <Button
                      onClick={() => {
                        setStep(step - 1);
                        setProgress(progress - 33.33);
                      }}
                      isDisabled={step === 1}
                      colorScheme="gray"
                      variant="solid"
                      w="7rem"
                      mr="5%"
                    >
                      Back
                    </Button>
                    <Button
                      w="7rem"
                      isDisabled={step === 3 || step === 2 && idImg === ""}
                      display={{
                        base: step === 3 ? "none" : "block",
                        md: "block",
                      }}
                      onClick={() => {
                        setStep(step + 1);
                        if (step === 3) {
                          setProgress(100);
                        } else {
                          setProgress(progress + 33.33);
                        }
                      }}
                      colorScheme="yellow"
                      variant="outline"
                    >
                      Next
                    </Button>
                  </Flex>
                  {step === 3 ? (
                    <Button
                      // w="7rem"
                      bgColor={"yellow.600"}
                      color={"white"}
                      variant="solid"
                      onClick={() => {
                        //   handleSubmit();
                        initializePayment(onSuccess);
                      }}
                    >
                      Complete
                    </Button>
                  ) : null}
                </Flex>
              </ButtonGroup>
            </Card>
          {/* </Box>
        </Container>
      </Box> */}
    </Box>
  );
};

export default Verification;
