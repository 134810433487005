import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Home from "../views/home";
import Signup from "../views/auth/signup";
import { PrivateRoutes } from "./PrivateRoutes";
import { PublicRoutes } from "./PublicRoutes";
import { getCurrentUser } from "../services/auth.service";

// Add your own authentication on the below line.
const isLoggedIn = localStorage["appState"]
  ? JSON.parse(localStorage["appState"]).isLoggedIn
  : "";

type Status = "checking" | "authenticated" | "no-authenticated";

let status: Status = "no-authenticated";

export const AppRouter = () => {
  if (status === "checking")
    return <div className="loading">Checking credentials...</div>;

  return (
    <BrowserRouter>
      <Routes>
        {getCurrentUser() != null ? (
          <Route path="/*" element={<PrivateRoutes />} />
        ) : (
            <Route path="/*" element={<PublicRoutes />} />
        )}

        <Route path="*" element={<Navigate to="/signup-artisan" replace />} />
      </Routes>
    </BrowserRouter>
  );
};
