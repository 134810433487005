import React, { useState } from "react";
import {
  Box,
  CloseButton,
  Flex,
  Icon,
  useColorModeValue,
  Drawer,
  DrawerContent,
  useDisclosure,
  BoxProps,
  FlexProps,
  Image,
  HStack,
  useToast,
} from "@chakra-ui/react";
import {
  FiTrendingUp,
  FiCompass,
  FiSettings,
  FiMenu,
  FiCreditCard,
  FiBell,
  FiMessageCircle,
} from "react-icons/fi";
import { IconType } from "react-icons";

import Logo from "../../assets/svg/logo.svg";
import WLogo from "../../assets/svg/logo-white.svg";
import { Link, NavLink } from "react-router-dom";
import { getCurrentUser } from "../../services/auth.service";

interface LinkItemProps {
  page: string;
  name: string;
  icon: IconType;
}

interface NavItemProps extends FlexProps {
  page: string;
  icon: IconType;
  children: React.ReactNode;
}

interface MobileProps extends FlexProps {
  onOpen: () => void;
}

interface SidebarProps extends BoxProps {
  onClose: () => void;
  routes: RoutesType[];
}

const userData = getCurrentUser();

const ArtisanItems: Array<LinkItemProps> = [
  { page: "dashboard", name: "Dashboard", icon: FiMenu },
  { page: "jobs", name: "My Jobs", icon: FiTrendingUp },
  { page: "explore", name: "Explore", icon: FiCompass },
  { page: "wallet", name: "My Wallet", icon: FiCreditCard },
  { page: "notifications", name: "Notifications", icon: FiBell },
  // { page: "Messages", name: "Messages", icon: FiMessageCircle },
  { page: "settings", name: "Settings", icon: FiSettings },
];

const UserItems: Array<LinkItemProps> = [
  { page: "dashboard", name: "Dashboard", icon: FiMenu },
  { page: "my-jobs", name: "My Jobs", icon: FiTrendingUp },
  { page: "wallet", name: "My Wallet", icon: FiCreditCard },
  { page: "notifications", name: "Notifications", icon: FiBell },
  // { page: "Messages", name: "Messages", icon: FiMessageCircle },
  { page: "settings", name: "Settings", icon: FiSettings },
];

export function ArtisanSidebar(props: { routes: RoutesType[] }) {
  const toast = useToast();
  
  let sidebarBackgroundColor = useColorModeValue("white", "navy.800");
  let menuColor = useColorModeValue("gray.400", "white");
  // // SIDEBAR
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();

  const { routes } = props;
  // let isWindows = navigator.platform.startsWith("Win");
  //  BRAND

  const [active, setActive] = useState("dashboard");

  const SidebarContent = ({ onClose, routes, ...rest }: SidebarProps) => {
    return (
      <Box
        transition="3s ease"
        bg={useColorModeValue("white", "gray.900")}
        borderRight="1px"
        borderRightColor={useColorModeValue("gray.200", "gray.700")}
        w={{ base: "full", md: 60 }}
        pos="fixed"
        h="full"
        {...rest}
      >
        <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
          <Link to={"/"}>
            <Image
              alt={"Logo"}
              align={"center"}
              src={useColorModeValue(Logo, WLogo)}
            />
          </Link>
          <CloseButton
            display={{ base: "flex", md: "none" }}
            onClick={onClose}
          />
        </Flex>
        {userData.user.user_level == 1 ? ArtisanItems.map((link) => (
          <NavItem key={link.name} page={link.page} icon={link.icon}>
            {link.name}
          </NavItem>
        )) : UserItems.map((link) => (
          <NavItem key={link.name} page={link.page} icon={link.icon}>
            {link.name}
          </NavItem>
        ))}
      </Box>
    );
  };

  const NavItem = ({ page, icon, children, ...rest }: NavItemProps) => {
    return (
      <NavLink onClick={() => {
        if(page != 'settings'){
        if(userData.user.verification_complete == 1){
          setActive(page);
        }else{
          setActive('dashboard');
          toast({
            title: 'Verification',
            description: `You need to complete your verification!`,
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
        }}else{
          setActive(page);
        }
      }}
      to={page != 'settings' ? userData.user.verification_complete == 1 ? page : 'dashboard' : page}
      >
        <Box>
          <HStack>
            <Flex
            width={"full"}
              align="center"
              p="4"
              mx="4"
              role="group"
              cursor="pointer"
              _hover={{
                color: "yellow.600",
              }}
              color={active == page ? "yellow.600" : ""}
              {...rest}
              fontWeight={active == page ? "600" : ""}
            >
              {icon && (
                <Icon
                  mr="4"
                  fontSize="16"
                  _groupHover={{
                    color: "yellow.600",
                  }}
                  as={icon}
                  color={active == page ? "yellow.600" : ""}
                />
              )}
              {children}
            </Flex>
            <Box
              h="26px"
              w="4px"
              mr={2}
              bg={active == page ? "yellow.600" : "transparent"}
              borderRadius="5px"
            />
          </HStack>
        </Box>
      </NavLink>
    );
  };

  return (
    <Box>
      <SidebarContent
        onClose={() => onClose}
        display={{ base: "none", md: "block" }}
        routes={routes}
      />
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent routes={routes} onClose={onClose} />
        </DrawerContent>
      </Drawer>
    </Box>
  );
}

export default ArtisanSidebar;
