import {
  Box,
  Container,
  Heading,
  Text,
  Stack,
  Highlight,
  Flex,
  Grid,
  StackDivider,
  useColorModeValue,
  Image,
  VStack,
} from "@chakra-ui/react";
import Navbar from "../components/navbar/navbar";
import Hero from "../components/hero/hero-section";
import HowitWorks from "../components/howitworks/how";
import Top from "../components/top/top";
import Footer from "../components/footer/footer";
import Testimonials from "../components/testimonials/testimonials";
import Standards from "../components/standards/standards";
import { Link } from "react-router-dom";
export default function Terms() {
  return (
    <Box fontSize="xl">
      <Navbar />
      <Box mt={{ md: 10 }}>
        <Box p={10} m={{ md: 10 }}>
          <VStack paddingTop="40px" spacing="2" alignItems="flex-start">
            <Heading>Terms of Service</Heading>
            <Text as="b" fontSize="lg">
              Last Updated: 20th February, 2024
            </Text>
            <Text as="p" fontSize="lg">
              Welcome to Sapioworks!
            </Text>
            <Text as="p" fontSize="lg">
              Please read these terms carefully before using our platform.
            </Text>
            <Text as="b" fontSize="lg">
              1. Acceptance of Terms
            </Text>
            <Text as="p" fontSize="lg">
              By accessing or using Sapioworks, you agree to be bound by these
              Terms of Service. If you do not agree to all the terms and
              conditions, you may not access or use the services.
            </Text>
            <Text as="b" fontSize="lg">
              2. Registration and Accounts
            </Text>
            <Text as="p" fontSize="lg">
              2.1 To use certain features of the platform, you may need to
              register and create an account. You are responsible for
              maintaining the confidentiality of your account information and
              for all activities that occur under your account.
            </Text>
            <Text as="p" fontSize="lg">
              2.2 You must provide accurate and complete information during the
              registration process. You agree to update your information to keep
              it accurate and current.
            </Text>
            <Text as="b" fontSize="lg">
              3. User Conduct
            </Text>
            <Text as="p" fontSize="lg">
              3.1 You agree not to use Sapioworks for any unlawful or prohibited
              purpose. You are solely responsible for your interactions with
              other users.
            </Text>
            <Text as="p" fontSize="lg">
              3.2 You agree not to post content that is defamatory, abusive,
              harassing, threatening, or otherwise violates any law or right of
              any third party.
            </Text>
            <Text as="b" fontSize="lg">
              4. Artisan Services
            </Text>
            <Text as="p" fontSize="lg">
              4.1 Sapioworks connects artisans with clients seeking their
              services. Artisans are responsible for the quality and completion
              of their work.
            </Text>
            <Text as="p" fontSize="lg">
              4.2 Artisans must provide accurate information about their skills
              and qualifications. Sapioworks may verify this information.
            </Text>
            <Text as="b" fontSize="lg">
              5. Client Responsibilities
            </Text>
            <Text as="p" fontSize="lg">
              5.1 Clients are responsible for providing accurate project details
              and ensuring a safe working environment for artisans.
            </Text>
            <Text as="p" fontSize="lg">
              5.2 Clients must make payments for services through the platform's
              designated payment system.
            </Text>
            <Text as="b" fontSize="lg">
              6. Communication
            </Text>
            <Text as="p" fontSize="lg">
              6.1 Sapioworks provides a messaging system for communication
              between artisans and clients. Users are encouraged to communicate
              through the platform for transparency.
            </Text>
            <Text as="p" fontSize="lg">
              6.2 Any attempt to circumvent the platform's communication system
              may result in the suspension or termination of your account.
            </Text>
            <Text as="p" fontSize="lg">
              7. Reviews and Feedback
            </Text>
            <Text as="b" fontSize="lg">
              7.1 Users are encouraged to leave honest and constructive reviews
              after the completion of a project.
            </Text>
            <Text as="p" fontSize="lg">
              7.2 Sapioworks reserves the right to moderate and remove reviews
              that violate our guidelines.
            </Text>
            <Text as="b" fontSize="lg">
              8. Insurance
            </Text>
            <Text as="p" fontSize="lg">
              8.1 Artisans on Sapioworks are covered by insurance, providing
              protection for both artisans and clients in case of accidents or
              damages during a project. (Subject to active insurance members only)
            </Text>
            <Text as="b" fontSize="lg">
              9. Payments
            </Text>
            <Text as="p" fontSize="lg">
              9.1 Payments for artisan services are conducted securely through
              the platform. Clients agree to pay for services as agreed upon
              with the artisan.
            </Text>
            <Text as="p" fontSize="lg">
              9.2 Sapioworks may charge fees for certain services. Fee details
              are available on the platform.
            </Text>
            <Text as="b" fontSize="lg">
              10. Termination
            </Text>
            <Text as="p" fontSize="lg">
              Sapioworks reserves the right to terminate or suspend accounts for
              violation of these terms or for any other reason deemed necessary.
            </Text>
            <Text as="b" fontSize="lg">
              11. Changes to Terms
            </Text>
            <Text as="p" fontSize="lg">
              Sapioworks may update these terms at any time. Users will be
              notified of significant changes, and continued use of the platform
              constitutes acceptance of the updated terms.
            </Text>
            <Text as="p" fontSize="lg">
              12. Governing Law
            </Text>
            <Text as="p" fontSize="lg" fontWeight={'bold'}>
              These terms are governed by and construed in accordance with the laws of the Federal Republic of Nigeria. Any unresolved disputes  will be subject to the exclusive jurisdiction of the courts.
            </Text><br/><br/>
            <Text as="p" fontSize="lg">
              Thank you for using Sapioworks! If you have any questions or
              concerns, please contact us at: <Link to={'mailto:admin@sapioworks.com'}><Text color={'yellow.800'}>admin@sapioworks.com</Text></Link>.
            </Text>
          </VStack>
        </Box>

        <HowitWorks />
        <Testimonials />
      </Box>
      <Footer />
    </Box>
  );
}
