import {
  Box,
  Container,
  Heading,
  Text,
  Stack,
  Highlight,
  Flex,
  Grid,
  StackDivider,
  useColorModeValue,
  Image,
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
} from "@chakra-ui/react";
import Navbar from "../components/navbar/navbar";
import Hero from "../components/hero/hero-section";
import HowitWorks from "../components/howitworks/how";
import Top from "../components/top/top";
import Footer from "../components/footer/footer";
import Testimonials from "../components/testimonials/testimonials";
import Standards from "../components/standards/standards";
import { ChevronDownIcon } from "@chakra-ui/icons";
export default function Help() {
  return (
    <Box textAlign="justify">
      <Navbar />
      <Box mt={{ md: 20 }}>
        <Stack spacing={0} align={"center"} mb={20}>
          <Heading>Help Center</Heading>

          <Box p={10}>
            <Box  m={{ md: 10 }}>
              
            <Accordion maxW="xl" rounded="lg">
          <AccordionItem>
            <AccordionButton
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              p={4}>
              <Text fontSize="md">What is Sapioworks?</Text>
              <ChevronDownIcon fontSize="24px" />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <Text color="gray.600" textAlign={'left'}>
              Sapioworks is a platform that connects skilled artisans with individuals or businesses in need of their services. It serves as a marketplace for artisans to showcase their expertise and for clients to find and hire skilled professionals for various projects.
              </Text>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionButton
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              p={4}>
              <Text fontSize="md">How can I join Sapioworks as an artisan?</Text>
              <ChevronDownIcon fontSize="24px" />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <Text color="gray.600" textAlign={'left'}>
              To join as an artisan, simply sign up on our platform, complete your profile with relevant information about your skills and experience, and submit any required verification documents. Once approved, you can start browsing and applying for available projects.
              </Text>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionButton
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              p={4}>
              <Text fontSize="md">How does Sapioworks match artisans with projects?</Text>
              <ChevronDownIcon fontSize="24px" />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <Text color="gray.600" textAlign={'left'}>
              Sapioworks employs advanced algorithms that consider the skills, experience, and preferences of artisans. When clients post projects, our system matches them with suitable artisans, ensuring a more efficient and accurate connection between the two parties.
              </Text>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionButton
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              p={4}>
              <Text fontSize="md">Is there a verification process for artisans on Sapioworks?</Text>
              <ChevronDownIcon fontSize="24px" />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <Text color="gray.600" textAlign={'left'}>
              Yes, we have a thorough verification process to ensure the authenticity and qualifications of our artisans. This includes checking credentials, certifications, and other relevant documents to maintain a high standard of professionalism on our platform.
              </Text>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionButton
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              p={4}>
              <Text fontSize="md">What types of projects can I find on Sapioworks?</Text>
              <ChevronDownIcon fontSize="24px" />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <Text color="gray.600" textAlign={'left'}>
              Sapioworks caters to a wide range of projects, from home renovations and repairs to specialized craftsmanship. Our platform accommodates various skills, including carpentry, plumbing, electrical work, painting, and more. Whatever your project needs, you're likely to find a skilled artisan on Sapioworks.
              </Text>
            </AccordionPanel>
          </AccordionItem>
          {/* <AccordionItem>
            <AccordionButton
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              p={4}>
              <Text fontSize="md">How can I communicate with artisans or clients on Sapioworks?</Text>
              <ChevronDownIcon fontSize="24px" />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <Text color="gray.600" textAlign={'left'}>
              Sapioworks provides a user-friendly messaging system within the platform, allowing seamless communication between artisans and clients. This ensures clear discussions about project details, timelines, and expectations.
              </Text>
            </AccordionPanel>
          </AccordionItem> */}
          <AccordionItem>
            <AccordionButton
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              p={4}>
              <Text fontSize="md">Is there a feedback system on Sapioworks?</Text>
              <ChevronDownIcon fontSize="24px" />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <Text color="gray.600" textAlign={'left'}>
              Yes, we encourage both artisans and clients to provide feedback after the completion of a project. This system promotes accountability and helps maintain a high standard of quality on our platform. Reviews also assist other users in making informed decisions when choosing collaborators.
              </Text>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionButton
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              p={4}>
              <Text fontSize="md">How does insurance work on Sapioworks?</Text>
              <ChevronDownIcon fontSize="24px" />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <Text color="gray.600" textAlign={'left'}>
              Sapioworks ensures that artisans on the platform are covered by insurance. This coverage protects both the artisans and clients in the event of accidents, property damage, or injuries during a project. It adds an extra layer of security and peace of mind for everyone involved.
              </Text>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionButton
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              p={4}>
              <Text fontSize="md">How do I pay for services on Sapioworks?</Text>
              <ChevronDownIcon fontSize="24px" />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <Text color="gray.600" textAlign={'left'}>
              Payment processes may vary depending on the project. Sapioworks provides a secure payment system within the platform, allowing clients to pay for services easily and conveniently. Specific payment details are discussed and agreed upon between the artisan and client during the project negotiation phase.
              </Text>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionButton
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              p={4}>
              <Text fontSize="md">How do I report an issue or seek assistance on Sapioworks?</Text>
              <ChevronDownIcon fontSize="24px" />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <Text color="gray.600" textAlign={'left'}>
              If you encounter any issues or need assistance, you can contact our customer support team through the designated channels on the platform. We are committed to ensuring a smooth experience for both artisans and clients and are here to help resolve any concerns promptly.
              </Text>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>

            </Box>
          </Box>

          
        </Stack>
        <HowitWorks />
        <Testimonials />
      </Box>
      <Footer />
    </Box>
  );
}
