import {
  Box,
  Flex,
  Icon,
  useColorModeValue,
  Text,
  useDisclosure,
  SimpleGrid,
  Card,
  StatLabel,
  Stat,
  StatNumber,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
  useToast,
  Image,
  Heading,
  Stack,
} from "@chakra-ui/react";
import {
  FiBarChart,
  FiArrowUp,
  FiCheckCircle,
  FiDownload,
  FiPlusCircle,
  FiMinusCircle,
} from "react-icons/fi";
import { FaUsers, FaWallet } from "react-icons/fa";
import BarChart from '../../components/charts/BarChart';
import { barChartDataConsumption, barChartDataDailyTraffic, barChartOptionsConsumption, barChartOptionsDailyTraffic, lineChartDataTotalSpent, lineChartOptionsTotalSpent } from "../../components/variables/charts";
import LineChart from "../../components/charts/LineChart";


import Logo from '../../assets/svg/logo.svg';
import WLogo from '../../assets/svg/logo-white.svg';
import { Link } from "react-router-dom";


import {
    createColumnHelper
} from '@tanstack/react-table';
import { useEffect, useRef, useState } from "react";
import tableDataCheck from "../../components/variables/tableDataCheck";
import tableDataComplex from "../../components/variables/tableDataComplex";
import ComplexTable from "../../components/tables/ComplexTable";
import { getArtisanDashboard, getArtisanJobs, getTransactions, getUserDashboard } from "../../services/api.service";
import { fundWallet, getCurrentUser, requestWithdrawal } from "../../services/auth.service";
import TransactionTable from "../../components/tables/TransactionTable";


import { usePaystackPayment } from 'react-paystack';
import { paystackLivePKey, paystackTestPKey } from "../../utils/constants";
import EarningImage from '../../assets/earnings.png';
  


type RowObj = {
    name: [string, boolean];
    progress: string;
    quantity: number;
    date: string;
    info: boolean;
};
 
const columnHelper = createColumnHelper<RowObj>();

const ArtisanWallet = () => {

  const toast = useToast();

  
  // Chakra Color Mode
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");


  // total spent
    const textColorSecondary = useColorModeValue('secondaryGray.600', 'white');

  // card
    const textColor = useColorModeValue('secondaryGray.900', 'white');
    const iconColor = useColorModeValue('brand.500', 'white');
    const bgButton = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
    const bgHover = useColorModeValue({ bg: 'secondaryGray.400' }, { bg: 'whiteAlpha.50' });
    const bgFocus = useColorModeValue({ bg: 'secondaryGray.300' }, { bg: 'whiteAlpha.100' });


    const userData = getCurrentUser();
    const [jobs, setJobs] = useState([]);
    const [balance, setBalance] = useState(0);
    const [amount, setAmount] = useState('');

    useEffect(() => {
      userData.user.user_level == 1 ?
      getArtisanDashboard().then(
        (response) => {
          setJobs(response.data['jobs']);
          setBalance(response.data['wallet']['balance']);
        },
        (error) => {
          console.log(error.message);
        }
      ) : getUserDashboard().then(
        (response) => {
          setJobs(response.data['jobs']);
          setBalance(response.data['wallet']['balance']);
        },
        (error) => {
          console.log(error.message);
        }
      )
    }, []);



    const handleFund = (amount: string) => {
      fundWallet(amount).then(
        (response) => {
          if(response.success){
          toast({
            title: 'Wallet Funded!',
            description: `${response.message}`,
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
          setTimeout(function () {
            window.location.href = "/dashboard";
        }, 3000);
        }else{
          toast({
            title: 'Unable to Fund Wallet',
            description: `${response.message}`,
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
        }
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    };


    const handleWithdraw = (amount: string) => {
      if(balance < parseInt(amount)){
        toast({
          title: 'Insufficient Balance!',
          description: `Your balance is too low, enter another amout`,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }else{
        if(parseInt(amount) < 1000){
          toast({
            title: 'Minimum Wthdrawal Amount!',
            description: `You can only withdraw 1,000 above!`,
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
        }
        else{
      requestWithdrawal(amount).then(
        (response) => {
          if(response.success){
          toast({
            title: 'Withdrawal Request Submitted!',
            description: `${response.message}`,
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
          setTimeout(function () {
            window.location.href = "/wallet";
        }, 3000);
        }else{
          toast({
            title: 'Unable to Request Withdrawal',
            description: `${response.message}`,
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
        }
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
      }
      }
    };
  


  const { isOpen, onOpen, onClose } = useDisclosure();

  const initialRef = useRef(null);
  const finalRef = useRef(null);


  const [transactions, setTransactions] = useState([]);

  const [action, setAction] = useState('');
  
  useEffect(() => {
    getTransactions().then(
      (response) => {
        setTransactions(response.data['transactions']);
      },
      (error) => {
        console.log(error.message);
      }
    )
  }, []);

  
  // Paystack
  const config = {
    reference: (new Date()).getTime().toString(),
    email: userData.user.email,
    amount: parseInt(amount+"00"), //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
    publicKey: paystackTestPKey,
    // publicKey: paystackLivePKey,
  };

  // you can call this function anything
  const onSuccess = () => {
    // Implementation for whatever you want to do with reference and after success call.
    // console.log('payment complete');
    handleFund(amount);
  };

  const initializePayment = usePaystackPayment(config);

  return (
      <Box>

      {/* Modal Start */}

      <Modal
                initialFocusRef={initialRef}
                finalFocusRef={finalRef}
                isOpen={isOpen}
                onClose={onClose}
            >
                <ModalOverlay />
                <ModalContent>
                <ModalHeader>{action == 'fund' ? 'Fund Wallet' : 'Request Withdrawal'}</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                    <FormControl>
                    <FormLabel>{action == 'fund' ? 'Enter Amount to Fund Wallet (₦)' : 'Enter Amount to Withdraw (Min. ₦1,000)'}</FormLabel>
                    <Input ref={initialRef} 
                      value={amount}
                      onChange={(event) => setAmount(event.target.value)} placeholder='1000' />
                    </FormControl>
                </ModalBody>

                <ModalFooter>
                    <Button 
                      flex={1}
                      fontSize={"sm"}
                      rounded={"full"}
                      bg={"yellow.600"}
                      color={"white"}
                      _hover={{
                        bg: "yellow.500",
                      }}
                      _focus={{
                        bg: "yellow.500",
                      }}
                      onClick={()=>{
                        action == 'fund' ?
                        initializePayment(onSuccess) : handleWithdraw(amount);
                      }}
                      >
                    {action == 'fund' ? 'Fund Wallet' : 'Withdraw'}
                    </Button>
                </ModalFooter>
                </ModalContent>
            </Modal>

    {/* Modal End */}

    <SimpleGrid templateColumns={{ sm: '1fr', md: '1fr 1fr' }} spacing="30px" mt={6}>
            <Card justifyContent='center' alignItems='center' flexDirection='column' boxShadow={"none"} p={4} w='100%' mb='0px' borderRadius={20}>
            
            <Flex w='100%' flexDirection={{ base: 'column', lg: 'row' }}>
              <Flex flexDirection='column' me='20px'>
                <Text color={textColor} fontSize='34px' textAlign='start' fontWeight='700' lineHeight='100%'>
                ₦{balance.toFixed(2) ?? 0}
                </Text>
                <Flex align='center' mb='20px'>
                  <Text color='secondaryGray.600' fontSize='sm' fontWeight='500' mt='4px' me='12px'>
                    Total Inflow
                  </Text>
                </Flex>
                
              <Flex align='center' 
                      onClick={()=>{
                        setAction('fund');
                        onOpen();
                      }}>
                <Icon as={FiPlusCircle} color='green.500' me='4px' />
                <Text color='green.500' fontSize='md' fontWeight='700'>
                  Fund Wallet
                </Text>
              </Flex>
              <Flex mt={4} align='center' 
                      onClick={()=>{
                        setAction('withdraw');
                        onOpen();
                      }}>
                <Icon as={FiMinusCircle} color='red.500' me='4px' />
                <Text color='red.500' fontSize='md' fontWeight='700'>
                  Withdraw
                </Text>
              </Flex>
              </Flex>
              <Box minH='200px' minW='25%' mt='auto'>
                  <Image
                    alt={"Earnings Image"}
                    fit={"cover"}
                    align={"center"}
                    src={EarningImage}
                  />
              </Box>
            </Flex>
          </Card>
  
  
          <Card alignItems='center' flexDirection='column' boxShadow={"none"} p={4} w='100%' borderRadius={20}>
            <Flex align='center' w='100%' px='15px' py='10px'>
              <Text me='auto' color={textColor} fontSize='xl' fontWeight='700' lineHeight='100%'>
                Earnings Chart
              </Text>
              <Button
                alignItems='center'
                justifyContent='center'
                bg={bgButton}
                _hover={bgHover}
                _focus={bgFocus}
                _active={bgFocus}
                w='37px'
                h='37px'
                lineHeight='100%'
                borderRadius='10px'>
                <Box color={useColorModeValue("gray.800", "gray.200")} mr={5}>
              <Flex alignItems={'center'} justifyContent={'center'} borderRadius={'50%'} bg={"yellow.100"} p={4}>
                  <Box color="yellow.500">
                      <FiBarChart size={32} />
                  </Box>
              </Flex>
              </Box>
              </Button>
            </Flex>
  
            <Box h='240px' mt='auto' w='100%'>
                <LineChart chartData={lineChartDataTotalSpent} chartOptions={lineChartOptionsTotalSpent} />
            </Box>
          </Card>
        </SimpleGrid>
      {transactions.length == 0 ? 
      <>
      <Stack m={'10%'}>
            <Heading textAlign={'center'} color={"yellow.600"}>No Transactions</Heading>
            <Text textAlign={'center'} mb={4} mt={4}>
              No Transactions Found.
            </Text>
      </Stack>
      </> : 
      <TransactionTable  tableData={transactions}/>
        }
      </Box>
  );
};

export default ArtisanWallet;
