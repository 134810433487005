import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Container,
  Badge,
  Grid,
  Text,
  Heading,
  Stack,
  Avatar,
  Button,
  useColorModeValue,
  Input,
  Select,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
  Textarea,
  Spinner,
  Flex,
  VStack,
} from "@chakra-ui/react";
import { BsStar, BsStarFill, BsStarHalf } from "react-icons/bs";
import { FiShoppingCart } from "react-icons/fi";
import { getMessages } from "../../services/api.service";
import { applyTask } from "../../services/auth.service";
import { currencyFormat, dateFormat } from "../../utils/helper";
import { MdNotifications } from "react-icons/md";

export default function Messages() {
  const toast = useToast();

  const [filter, setFilter] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [loading, setLoading] = useState(false);

  const handleFilterChange = (event: any) => {
    setFilter(event.target.value);
  };

  const handleSortChange = (event: any) => {
    setSortBy(event.target.value);
  };

  const [messages, setMessages] = useState([]);
  const [rate, setRate] = useState("");
  const [message, setMessage] = useState("");

  const [activeMessage, setActiveMessage] = useState<{
    user_id: string;
    title: string;
    message: string;
    status: number;
  }>({
    user_id: "",
    title: "",
    message: "",
    status: 0,
  });

  useEffect(() => {
    getMessages().then(
      (response) => {
        setMessages(response.data["data"]);
      },
      (error) => {
        const _messages =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();

        setMessages(_messages);
      }
    );
  }, []);

  const handleApply = (
    job_id: number,
    rate_p_hr: string,
    artisan_message: string
  ) => {
    setLoading(true);
    applyTask(job_id, rate_p_hr, artisan_message).then(
      (response) => {
        if (response.success) {
          toast({
            title: "Submitted!",
            description: `${response.message}`,
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          setTimeout(function () {
            setLoading(false);
            window.location.href = "/messages";
          }, 3000);
        } else {
          toast({
            title: "Unable to Submit Application",
            description: `${response.message}`,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
          setLoading(false);
        }
      },
      (error) => {
        setLoading(false);
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  };

  const { isOpen, onOpen, onClose } = useDisclosure();

  const initialRef = useRef(null);
  const finalRef = useRef(null);

  return (
    <Container maxW={"12xl"}>
      {/* Modal Start */}

      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
        size={"4xl"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Flex>
              <Avatar
                src={
                  "https://static.vecteezy.com/system/resources/previews/019/896/008/original/male-user-avatar-icon-in-flat-design-style-person-signs-illustration-png.png"
                }
              />
              <Text textAlign={"left"} px={3}>
                {activeMessage.title}
              </Text>
            </Flex>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Box
              maxH={"40vh"}
              width={"100%"}
              style={{ overflow: "hidden", overflowY: "scroll" }}
            >
              {/* {disputes.map((dispute, i) => {
                          return (
                            <>
                            <Text  m={4} mb={8} style={{fontStyle:'italic'}}>{dispute['message']} - {dispute['fname']} {dispute['lname']} ({dispute['date']}) </Text>
                            </>)})} */}

              <Flex align={"flex-start"} mb={2}>
                <Avatar
                  src={
                    "https://static.vecteezy.com/system/resources/previews/019/896/008/original/male-user-avatar-icon-in-flat-design-style-person-signs-illustration-png.png"
                  }
                  width={8}
                  height={8}
                />
                <Text textAlign={"left"} px={3} fontSize={13}>
                  Hello sir
                  <br />
                  <span style={{ fontSize: 10 }}>11:20am</span>
                  <br />
                </Text>
              </Flex>

              {/* <Flex align={"flex-start"} float={"right"} mb={2}>
                <Avatar
                  src={
                    "https://static.vecteezy.com/system/resources/previews/019/896/008/original/male-user-avatar-icon-in-flat-design-style-person-signs-illustration-png.png"
                  }
                  width={8}
                  height={8}
                />
                <Text textAlign={"left"} px={3} fontSize={13}>
                  Hi
                  <br />
                  <span style={{ fontSize: 10 }}>11:20am</span>
                  <br />
                </Text>
              </Flex> */}

              <Flex align={"flex-start"} float={"right"} mb={2}>
                <Avatar
                  src={
                    "https://static.vecteezy.com/system/resources/previews/019/896/008/original/male-user-avatar-icon-in-flat-design-style-person-signs-illustration-png.png"
                  }
                  width={8}
                  height={8}
                />
                <Text textAlign={"left"} px={3} fontSize={13}>
                  I am close by
                  <br />
                  <span style={{ fontSize: 10 }}>11:20am</span>
                  <br />
                </Text>
              </Flex>
            </Box>
            <FormControl mt={4}>
              <FormLabel>Send Message</FormLabel>
              <Textarea
                value={message}
                onChange={(event) => setMessage(event.target.value)}
                placeholder="Wrtie your message"
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button
              rounded={"full"}
              bg={"yellow.600"}
              color={"white"}
              _hover={{
                bg: "yellow.500",
              }}
              _focus={{
                bg: "yellow.500",
              }}
              mr={3}
              onClick={() => {
                // setOpenDispute(false);
                // submitDispute();
              }}
            >
              {loading ? <Spinner color="white" /> : "Send Message"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Modal End */}

      <Box>
        <Stack spacing={0} align={"center"} mb={10}>
          <Heading textAlign={"center"}>Messages</Heading>
          {/* <Text align={"center"} mt={4}>
            Stay up to date with information
          </Text> */}
        </Stack>

        {messages.length == 0 ? (
          <>
            <Stack m={"10%"}>
              {/* <Image alignItems={'center'} width={400} src='https://qph.cf2.quoracdn.net/main-qimg-1334ec7bc09de71a2b40337f29c19f9f-lq' alt='No messages' mb={4} /> */}
              <Heading textAlign={"center"} color={"yellow.600"}>
                No conversations found
              </Heading>
              <Text textAlign={"center"} mb={4} mt={4}>
                You don't have any messages now!
              </Text>
            </Stack>
          </>
        ) : (
          <Box mb={10} width={"50%"} float={"right"}></Box>
        )}
        <Grid
          w="full"
          alignItems="center"
          justifyContent="center"
          templateColumns={{
            base: "1fr",
            md: "1fr",
            // xl: "1fr 1fr 1fr 1fr",
          }}
          gap={6}
        >
          {messages.map((notification, i) => {
            return (
              <Box
                bg="white"
                _dark={{ bg: "gray.800" }}
                //   maxW="sm"
                rounded="lg"
                shadow="sm"
                position="relative"
                m={1}
                //   boxShadow={"2xl"}
                p={6}
              >
                <Flex align={"center"} justifyContent={"space-between"}>
                  <Flex>
                    <Avatar
                      src={
                        "https://static.vecteezy.com/system/resources/previews/019/896/008/original/male-user-avatar-icon-in-flat-design-style-person-signs-illustration-png.png"
                      }
                    />
                    <Text textAlign={"left"} px={3}>
                      <span style={{ fontWeight: "bold" }}>
                        {notification["title"]}
                      </span>
                      <br />
                      {notification["message"]}
                      <br />
                      <span style={{ fontSize: 12 }}>
                        {dateFormat(notification["created_at"])}
                      </span>
                      <br />
                    </Text>
                  </Flex>

                  <Button
                    fontSize={"sm"}
                    rounded={"full"}
                    bg={"yellow.600"}
                    color={"white"}
                    _hover={{
                      bg: "yellow.500",
                    }}
                    _focus={{
                      bg: "yellow.500",
                    }}
                    onClick={() => {
                      setActiveMessage(notification);
                      onOpen();
                    }}
                  >
                    View
                  </Button>
                </Flex>
              </Box>
            );
          })}
        </Grid>
      </Box>
    </Container>
  );
}
