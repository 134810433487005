import { useEffect, useState } from "react";
import { Flex, Spinner, useToast } from "@chakra-ui/react";
import {
  Box,
  Container,
  Heading,
  Stack,
  Button,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  useColorModeValue,
  Select,
  HStack,
  Text,
  PinInput,
  PinInputField,
} from "@chakra-ui/react";
import Navbar from "../../components/navbar/navbar";
import Top from "../../components/top/top";
import Footer from "../../components/footer/footer";
import {
  checkEmail,
  checkPhone,
  registerArtisan,
  sendOtp,
  verifyOtp,
} from "../../services/auth.service";
import { Link, useNavigate } from "react-router-dom";
import { getServices } from "../../services/api.service";
import { CheckBox } from "@mui/icons-material";
import { currencyFormat } from "../../utils/helper";


import bg from '../../assets/a-bg.jpg';

const StepIndicator = ({ currentStep, totalSteps, stepTitle }: any) => {
  return (
    <Box textAlign="center" fontSize="xl" mb={10}>
      <Heading as="h2" size="md" mb={4}>
        {stepTitle}
      </Heading>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="100%"
      >
        {[...Array(totalSteps)].map((_, index) => (
          <Box
            key={index}
            bg={index < currentStep ? "green.500" : "gray.200"}
            borderRadius="full"
            height="8px"
            width="8px"
            mx={1}
          />
        ))}
      </Box>
    </Box>
  );
};

const Signup = () => {
  const toast = useToast();
  const navigate = useNavigate();

  const [step, setStep] = useState(1);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [referral, setReferral] = useState("");
  const [email, setEmail] = useState("");
  const [telephone, setTelephone] = useState("");
  const [password, setPassword] = useState("");
  const [service, setService] = useState("AC_Repairs/5000.00");
  const [rate_p_hr, setHrRate] = useState("5000.00");
  const [state, setState] = useState("");
  const [sex, setSex] = useState("");
  const [age, setAge] = useState("");
  const [address, setAddress] = useState("");
  const [emailOtp, setEmailOtp] = useState("");
  const [numberOtp, setNumberOtp] = useState("");

  const [confirmPassword, setConfirmPassword] = useState("");

  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [referralError, setReferralError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [telephoneError, setTelephoneError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [serviceError, setServiceError] = useState("");
  const [stateError, setStateError] = useState("");
  const [addressError, setAddressError] = useState("");
  const [emailOtpError, setEmailOtpError] = useState("");
  const [numberOtpError, setNumberOtpError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const handleNext = () => {
    if (step === 1 && validateName()) {
      setStep(step + 1);
    } else if (step === 2 && validateEmail()) {
      setStep(step + 1);
    } else if (step === 3) {
      setStep(step + 1);
    } else if (step === 4) {
      setStep(step + 1);
    } else if (step < 7) {
      setStep(step + 1);
    }
  };

  const handleBack = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const validateName = () => {
    if (!firstName) {
      setFirstNameError("First name is required");
      return false;
    } else {
      setFirstNameError("");
    }
    if (!lastName) {
      setLastNameError("Last name is required");
      return false;
    } else {
      setLastNameError("");
    }
    return true;
  };

  const validateEmail = () => {
    if (!email) {
      setEmailError("Email is required");
      return false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError("Invalid email address");
      return false;
    } else {
      setEmailError("");
      return true;
    }
  };

  const validatePassword = () => {
    if (!password) {
      setPasswordError("Password is required");
      return false;
    } else if (password.length < 8) {
      setPasswordError("Password must be at least 8 characters long");
      return false;
    } else {
      setPasswordError("");
      return true;
    }
  };

  const validateConfirmPassword = () => {
    if (!confirmPassword) {
      setConfirmPasswordError("Confirm password is required");
      return false;
    } else if (password !== confirmPassword) {
      setConfirmPasswordError("Passwords do not match");
      return false;
    } else {
      setConfirmPasswordError("");
      return true;
    }
  };

  const cardBg = useColorModeValue("white", "gray.900");
  const inputBg = useColorModeValue("white", "gray.900");
  const inputColor = useColorModeValue("gray.900", "white");

  const [successful, setSuccessful] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");

  const [emailTaken, setEmailTaken] = useState(false);
  const [phoneTaken, setPhoneTaken] = useState(false);


  const [services, setServices] = useState([]);

  useEffect(() => {
    getServices().then(
      (response) => {
        setServices(response.data['data']);
      },
      (error) => {
        const _services =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();

        setServices(_services);
      }
    );
  }, []);
  

  const emailCheck = () => {
    checkEmail(email).then(
      (response) => {
        if (response.success) {
          console.log(response.message);
          setEmailTaken(true);
          setEmailError(response.message);
          toast({
            title: "Error!",
            description: `${response.message}`,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        } else {
          console.log(response.message);
          checkPhone(telephone).then((response) => {
            if (response.success) {
              console.log(response.message);
              setPhoneTaken(true);
              setTelephoneError(response.message);
              toast({
                title: "Error!",
                description: `${response.message}`,
                status: "error",
                duration: 3000,
                isClosable: true,
              });
            } else {
              if(email != ""){
              // initiateOtp();
              handleNext();
              }else{
                toast({
                  title: "Error!",
                  description: `Please fill all necessary fields`,
                  status: "error",
                  duration: 3000,
                  isClosable: true,
                });
              }
            }
          });
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        console.log(resMessage);
      }
    );
  };

  const initiateOtp = () => {
    sendOtp(email, telephone).then(
      (response) => {
        if (response.success) {
          console.log(response.message);
        } else {
          console.log(response.message);
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        console.log(resMessage);
      }
    );
  };





  const confirmOtp = () => {
    console.log('verify OTP '+ emailOtp);
    if(emailOtp != "") {
    verifyOtp(email, emailOtp).then(
      (response) => {
        if(response.success){
            console.log(response.message);
            toast({
              title: 'Success!',
              description: `${response.message}`,
              status: 'success',
              duration: 3000,
              isClosable: true,
            });
            setStep(step + 1);
        }else{
          setEmailOtp("");
          console.log(response.message);
          toast({
            title: 'Error!',
            description: `${response.message}`,
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
          console.log(resMessage);
      }
    );
    }else{
      toast({
        title: 'Error!',
        description: `Please enter token sent to your email`,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };


  const [loading, setLoading] = useState(false);
  const handleRegister = () => {
    setLoading(true);

    // 1 is the user level for Artisan
    registerArtisan(
      firstName,
      lastName,
      referral,
      1,
      service.split("/")[0],
      email,
      sex,
      age,
      telephone,
      state,
      address,
      password
    ).then(
      (response) => {
        if (response.success) {
          setLoading(false);
          toast({
            title: "Account Created!",
            description: `${response.message}`,
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          setSuccessful(response.success);
          // navigate('/artisan/dashboard');
          setTimeout(function () {
            window.location.href = "/dashboard";
          }, 3000);
        } else {
          setLoading(false);
          toast({
            title: "Unable to Create Account",
            description: `${response.message}`,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
          setSuccessful(response.success);
        }
      },
      (error) => {
        setLoading(false);
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setMessage(resMessage);
        setSuccessful(false);
      }
    );
  };

  return (
    <Box
      textAlign="center"
      backgroundImage={bg}
      fontSize="xl"
      backgroundSize="cover"
      backgroundPosition="center"
    >
      <Navbar />
      <Box>
        <Container maxW={"7xl"} mt={10}>
          <Box m={{ md: 20 }} mb={{ sm: 20 }}>
            <Box
              maxW={"xl"}
              minH={"l"}
              mx={"auto"}
              mb={10}
              px={6}
              bgColor={cardBg}
              p={10}
              borderRadius={10}
            >
              <Stack spacing={0} align={"center"} mb={5}>
                <Heading color={"yellow.600"}>Become a SapioArtisan</Heading>
              </Stack>
              <StepIndicator
                currentStep={step}
                totalSteps={5}
                stepTitle={
                  step === 2
                    ? "Provide Details"
                    : step === 1
                    ? "Select a Service"
                    : step === 3
                    ? "More Details"
                    // : step === 4
                    // ? "Verify Email"
                    : 
                    // : step === 5
                    // ? "Verify Phone Number"
                      "Confirm Details"
                }
              />
              {step === 2 && (
                <Box>
                  <FormControl
                    id="firstName"
                    className="mb-"
                    mb={4}
                    isInvalid={!!firstNameError}
                  >
                    <FormLabel fontWeight={"normal"}>First Name</FormLabel>
                    <Input
                      type="text"
                      value={firstName}
                      onChange={(event) => setFirstName(event.target.value)}
                      bg={inputBg}
                      color={inputColor}
                      // required
                    />
                    <FormErrorMessage>{firstNameError}</FormErrorMessage>
                  </FormControl>
                  <FormControl
                    id="lastName"
                    className="mb-"
                    mb={4}
                    isInvalid={!!lastNameError}
                  >
                    <FormLabel fontWeight={"normal"}>Last Name</FormLabel>
                    <Input
                      type="text"
                      value={lastName}
                      onChange={(event) => setLastName(event.target.value)}
                      bg={inputBg}
                      color={inputColor}
                      // required
                    />
                    <FormErrorMessage>{lastNameError}</FormErrorMessage>
                  </FormControl>
                  <FormControl
                    id="email"
                    className="mb-"
                    mb={4}
                    isInvalid={!!emailError}
                  >
                    <FormLabel fontWeight={"normal"}>Email Address</FormLabel>
                    <Input
                      type="email"
                      value={email}
                      onChange={(event) => setEmail(event.target.value)}
                      bg={inputBg}
                      color={inputColor}
                      // required
                      pattern="\S+@\S+\.\S+"
                    />
                    <FormErrorMessage>{emailError}</FormErrorMessage>
                  </FormControl>
                  <FormControl
                    id="telephone"
                    className="mb-"
                    mb={4}
                    isInvalid={!!telephoneError}
                  >
                    <FormLabel fontWeight={"normal"}>Phone Number</FormLabel>
                    <Input
                      type="telephone"
                      value={telephone}
                      onChange={(event) => setTelephone(event.target.value)}
                      bg={inputBg}
                      color={inputColor}
                      // required
                      // pattern="\S+@\S+\.\S+"
                    />
                    <FormErrorMessage>{telephoneError}</FormErrorMessage>
                  </FormControl>
                  <FormControl
                    id="referral"
                    className="mb-"
                    mb={4}
                    isInvalid={!!referralError}
                  >
                    <FormLabel fontWeight={"normal"}>
                      Referral ID (SW-X123456)
                    </FormLabel>
                    <Input
                      type="text"
                      value={referral}
                      onChange={(event) => setReferral(event.target.value)}
                      bg={inputBg}
                      color={inputColor}
                      // required
                    />
                    <FormErrorMessage>{referralError}</FormErrorMessage>
                  </FormControl>
                </Box>
              )}
              {step === 1 && (
                <Box>
                  <FormControl id="service" isInvalid={!!serviceError}>

                    { rate_p_hr != "" ? <Text fontWeight={'bold'} fontSize={'28'} mb={4}>approx. {currencyFormat(parseInt(rate_p_hr))}/hr</Text> : <></>}

                    <FormLabel fontWeight={"normal"}>
                      What service are you offering?
                    </FormLabel>
                    <Select
                                value={service}
                                onChange={(event) =>{
                                  setService(event.target.value);
                                  // console.log(event.target.value);
                                  // setService(event.target.value.split("/")[0]);
                                  setHrRate(event.target.value.split("/")[1]);
                                  // setRate(
                                  //   (
                                  //     parseInt(approx_duration) * parseInt(event.target.value.split("/")[1])
                                  //   )?.toString()
                                  // );
                                }}>
                        <option disabled selected>--Select Service--</option>
                      {services.map((service) => {
                        return (
                                <option value={service["slug"]+"/"+service["max_rate"]}>{service['title']}</option>
                        )})}
                    </Select>
                    <FormErrorMessage>{serviceError}</FormErrorMessage>
                  </FormControl>
                </Box>
              )}
              {step === 3 && (
                <Box>
                  <FormControl mb={4}>
                    <FormLabel
                      htmlFor="country"
                      fontSize="sm"
                      fontWeight="md"
                      color="gray.700"
                      _dark={{
                        color: "gray.50",
                      }}
                    >
                      Select Gender
                    </FormLabel>
                    <Select
                      id="sex"
                      name="sex"
                      autoComplete="sex"
                      placeholder="Select option"
                      focusBorderColor="brand.400"
                      shadow="sm"
                      w="full"
                      rounded="md"
                      value={sex}
                      onChange={(event) => setSex(event.target.value)}
                    >
                      <option disabled selected>
                        --Select Gender--
                      </option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                    </Select>
                  </FormControl>
                  <FormControl>
                    <FormLabel fontWeight={"normal"}>Enter Age</FormLabel>
                    <Input
                      type="text"
                      bg={inputBg}
                      color={inputColor}
                      value={age}
                      onChange={(event) => setAge(event.target.value)}
                      // required
                    />
                  </FormControl>
                  <FormControl mb={4}>
                    <FormLabel
                      htmlFor="country"
                      fontSize="sm"
                      fontWeight="md"
                      color="gray.700"
                      _dark={{
                        color: "gray.50",
                      }}
                    >
                      Select State
                    </FormLabel>
                    <Select
                      id="state"
                      name="state"
                      autoComplete="state"
                      placeholder="Select option"
                      focusBorderColor="brand.400"
                      shadow="sm"
                      w="full"
                      rounded="md"
                      value={state}
                      onChange={(event) => setState(event.target.value)}
                    >
                      <option disabled selected>
                        --Select State--
                      </option>
                      <option value="Abia">Abia</option>
                      <option value="Adamawa">Adamawa</option>
                      <option value="Akwa Ibom">Akwa Ibom</option>
                      <option value="Anambra">Anambra</option>
                      <option value="Bauchi">Bauchi</option>
                      <option value="Bayelsa">Bayelsa</option>
                      <option value="Benue">Benue</option>
                      <option value="Borno">Borno</option>
                      <option value="Cross River">Cross River</option>
                      <option value="Delta">Delta</option>
                      <option value="Ebonyi">Ebonyi</option>
                      <option value="Edo">Edo</option>
                      <option value="Ekiti">Ekiti</option>
                      <option value="Enugu">Enugu</option>
                      <option value="FCT">Federal Capital Territory</option>
                      <option value="Gombe">Gombe</option>
                      <option value="Imo">Imo</option>
                      <option value="Jigawa">Jigawa</option>
                      <option value="Kaduna">Kaduna</option>
                      <option value="Kano">Kano</option>
                      <option value="Katsina">Katsina</option>
                      <option value="Kebbi">Kebbi</option>
                      <option value="Kogi">Kogi</option>
                      <option value="Kwara">Kwara</option>
                      <option value="Lagos">Lagos</option>
                      <option value="Nasarawa">Nasarawa</option>
                      <option value="Niger">Niger</option>
                      <option value="Ogun">Ogun</option>
                      <option value="Ondo">Ondo</option>
                      <option value="Osun">Osun</option>
                      <option value="Oyo">Oyo</option>
                      <option value="Plateau">Plateau</option>
                      <option value="Rivers">Rivers</option>
                      <option value="Sokoto">Sokoto</option>
                      <option value="Taraba">Taraba</option>
                      <option value="Yobe">Yobe</option>
                      <option value="Zamfara">Zamfara</option>
                    </Select>
                  </FormControl>
                  <FormControl>
                    <FormLabel fontWeight={"normal"}>Enter Address</FormLabel>
                    <Input
                      type="text"
                      bg={inputBg}
                      color={inputColor}
                      value={address}
                      onChange={(event) => setAddress(event.target.value)}
                      // required
                    />
                  </FormControl>
                </Box>
              )}
              {/* {step === 4 && (
                <Box>
                  <FormControl id="emailOtp" isInvalid={!!emailOtpError}>
                    <FormLabel fontWeight={"normal"}>
                      Enter 6-digit code sent to your email
                    </FormLabel>
                    <Flex justifyContent={"center"}>
                      <HStack>
                        <PinInput onComplete={(value)=>setEmailOtp(value)} otp>
                          <PinInputField />
                          <PinInputField />
                          <PinInputField />
                          <PinInputField />
                          <PinInputField />
                          <PinInputField />
                        </PinInput>
                      </HStack>
                    </Flex>
                    <FormErrorMessage>{emailOtpError}</FormErrorMessage>
                  </FormControl>
                </Box>
              )} */}
              {/* {step === 5 && (
                <Box>
                  <FormControl id="numberOtp" isInvalid={!!numberOtpError}>
                    <FormLabel fontWeight={'normal'}>Enter 6-digit code sent to your phone number</FormLabel>
                    <Flex justifyContent={'center'}>
        <HStack>
            <PinInput otp>
            <PinInputField />
            <PinInputField />
            <PinInputField />
            <PinInputField />
            <PinInputField />
            <PinInputField />
            </PinInput>
        </HStack>
            </Flex>
                    <FormErrorMessage>{numberOtpError}</FormErrorMessage>
                  </FormControl>
                </Box>
              )} */}
              {step === 4 && (
                <Box>
                  <FormControl mb={4}>
                    <FormLabel fontWeight={"normal"}>Your Email</FormLabel>
                    <Input type="email" value={email} disabled />
                  </FormControl>
                  <Box>
                    <FormControl id="password" isInvalid={!!passwordError}>
                      <FormLabel fontWeight={"normal"}>
                        Create your password
                      </FormLabel>
                      <Input
                        type="password"
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}
                        bg={inputBg}
                        color={inputColor}
                        // required
                      />
                      <FormErrorMessage>{passwordError}</FormErrorMessage>
                    </FormControl>
                    <Text fontSize={'14'} align={'left'} mt={4}>
                    By continuing you agree to the Sapioworks <Link to={'/terms-of-service'} target="_blank"><span style={{color:'red'}}>Terms & Conditions</span></Link>
                    </Text>
                  </Box>
                  {/* <FormControl>
                    <FormLabel fontWeight={'normal'}>Password</FormLabel>
                    <Input type="password" value={password} />
                  </FormControl> */}
                </Box>
              )}
              <Box mt={8}>
                {step > 1 && step < 4 && (
                  <Button
                    colorScheme="gray"
                    mt={5}
                    mb={2}
                    mr={3}
                    fontSize={"sm"}
                    minWidth={"20%"}
                    fontWeight={600}
                    onClick={handleBack}
                    size="md"
                  >
                    Back
                  </Button>
                )}
                {step < 4 ? (
                  <Button
                    mt={5}
                    mb={2}
                    fontSize={"sm"}
                    fontWeight={600}
                    color={"white"}
                    minWidth={step === 1 ? "100%" : "75%"}
                    bg={"yellow.600"}
                    _hover={{
                      bg: "black",
                    }}
                    onClick={() => {
                      if(step == 2){
                        emailCheck();
                      // } else if(step == 4){
                      //   confirmOtp();
                      }
                      else{
                        handleNext();
                      }
                    }}
                    size="md"
                  >
                    Next
                  </Button>
                ) : (
                  <Button
                    mt={5}
                    mb={2}
                    fontSize={"sm"}
                    fontWeight={600}
                    color={"white"}
                    minWidth={"100%"}
                    _hover={{
                      bg: "black",
                    }}
                    colorScheme="green"
                    size="md"
                    onClick={() => {
                      if (!loading) handleRegister();
                    }}
                  >
                    {loading ? <Spinner color="white" /> : "Complete Signup!"}
                  </Button>
                )}
                {step > 3 && (
                  <Button
                    colorScheme="gray"
                    mt={5}
                    mb={2}
                    mr={3}
                    fontSize={"sm"}
                    minWidth={"20%"}
                    fontWeight={600}
                    onClick={handleBack}
                    size="md"
                  >
                    Not Sure? Go Back
                  </Button>
                )}
              </Box>
            </Box>
            <Stack style={{ justifyContent: "center", fontSize: 16 }} my={5}>
              <Text>Already have an account?</Text>
              <Link
                to={"/signin"}
                color="yellow.500"
                style={{marginLeft: 10, color:"brown", fontWeight: 'bold'}}
              >
                Sign In
              </Link>
              <Text ml={2} > or</Text>
              <Link
                to={"/signup-user"}
                color="yellow.500"
                style={{marginLeft: 10, color:"brown", fontWeight: 'bold'}}
              >
                Sign Up as a User
              </Link>
            </Stack>
          </Box>
        </Container>
      </Box>
      <Footer />
    </Box>
  );
};

export default Signup;
