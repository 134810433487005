import {
  Box,
  Container,
  Heading,
  Text,
  Stack,
  Highlight,
  Flex,
  Grid,
  StackDivider,
  useColorModeValue,
  Image,
} from "@chakra-ui/react";
import Navbar from "../components/navbar/navbar";
import Hero from "../components/hero/hero-section";
import HowitWorks from "../components/howitworks/how";
import Top from "../components/top/top";
import Footer from "../components/footer/footer";
import Testimonials from "../components/testimonials/testimonials";
import Standards from "../components/standards/standards";
export default function Partners() {
  return (
    <Box textAlign="center" fontSize="xl">
      <Navbar />
      <Box mt={{ md: 20 }}>
        <Stack spacing={0} align={"center"} mb={20}>
          <Heading>Our Partners</Heading>

          <Box p={10}>
            <Box m={{ md: 20 }}>
              

            </Box>
          </Box>

          
        </Stack>
        <HowitWorks />
        <Testimonials />
      </Box>
      <Footer />
    </Box>
  );
}
