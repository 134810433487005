import {
    IconButton,
    Avatar,
    Box,
    Flex,
    HStack,
    VStack,
    useColorModeValue,
    Text,
    useDisclosure,
    FlexProps,
    Menu,
    MenuButton,
    MenuDivider,
    MenuItem,
    MenuList,
    Image,
    Button,
    Icon,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Stack
  } from "@chakra-ui/react";
  import {
    FiMenu,
    FiBell,
    FiChevronDown,
  } from "react-icons/fi";
  
  
  import Logo from '../../assets/svg/logo.svg';
  import WLogo from '../../assets/svg/logo-white.svg';
  import { Link, NavLink, Route } from "react-router-dom";
  
  import { ColorModeSwitcher } from "../../ColorModeSwitcher";
import { useEffect, useState } from "react";
import MobileSideBar from "../sidebar/mobile_sidebar";
import { getCurrentUser, logout } from "../../services/auth.service";
import { getUser } from "../../services/api.service";
import moment from "moment";
import { MdCheckCircle, MdCancel, MdErrorOutline } from "react-icons/md";

import routes from "../../routes/routes";


  interface MobileProps extends FlexProps {
    onOpen: () => void;
  }


  
  const MobileNav = ({ onOpen, ...rest }: MobileProps) => {
  const [toggleSidebar, setToggleSidebar] = useState(false);


  const { isOpen,  onClose } = useDisclosure();
  const [openVerification, setOpenVerification] = useState(false);

  const userData = getCurrentUser();


  const getRoutes = (routes: RoutesType[]): any => {
    return routes.map((route: RoutesType, key: any) => {
      return <Route path={route.path} Component={route.component} key={key} />;
    });
  };

    return (
      <>
      {/* Verification Modal */}
      {/* Application Modal */}
        <Modal onClose={onClose} size={"xl"} isOpen={openVerification}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader textAlign={'center'}>Account Verification</ModalHeader>
              <ModalCloseButton
                onClick={() => {
                  onClose();
                  setOpenVerification(false);
                }}
              />
            <ModalBody>
              <Text fontSize={14} fontWeight={600} color={'red'} mb={10}>
                Please ensure you have the following handy before proceeding, as the process will be restarted if you stop in between.
              </Text>
              <Text fontSize={14} my={2}>1. You are at the same location of your address</Text>
              <Text fontSize={14} my={2}>2. Govt. Approved ID (Int'l Passport / Driver's License / NIN)</Text>
              <Text fontSize={14} my={2}>3. Picture of you holding your ID to your face</Text>
              {/* <Text fontSize={14} my={2}>4. 3-5 Pictures of you offering your service (Clearly showing your face)</Text> */}
              <Text fontSize={14} my={2}>4. Add links of your social media business page of your offering service</Text>
              <Text fontSize={14} my={2}>5. Your registered phone number to receive OTP</Text>
              <Text fontSize={14} my={2}>6. You will be required to pay Verification Fee</Text>
              <Text fontSize={14} my={2}>7. Make sure your name and surname is the exact same with your means of identification and bank details</Text>
              <Text fontSize={14} fontWeight={500} color={'red'} mt={4}>
                Note that verification is subject to providing all the right documents accurately! Failure to do so will barn you from the system.
              </Text>
              <Text fontSize={14} fontWeight={500} color={'red'} mt={4}>
                Note that if an artisan is caught committing a crime that ruins the reputation of the company, details and all informations would be handed to the police.
              </Text>
              <Text fontSize={14} fontWeight={500} color={'red'} mt={4}>
                At Sapio works, we tolorate a zero acceptance to domestic abuse, violence, degrading or any kind of abuse, theft and lack of respect on both parties.
              </Text>
              <Text fontSize={14} fontWeight={500} color={'red'} mt={4}>
              Make sure you add the correct details of your guarantor. Once a guarantor fails to respond, your verification will be terminated. All guarantors must know the artisans address for verification to be completed.
              </Text>
            </ModalBody>
            <ModalFooter mt={10}>
              <Button
                fontSize={"sm"}
                rounded={"full"}
                mr={2}
                onClick={() => {
                  onClose();
                  setOpenVerification(false);
                }}
              >
                Close
              </Button>
              <Link to={'/account-verification'}>
              <Button
                fontSize={"sm"}
                rounded={"full"}
                bg={"yellow.600"}
                color={"white"}
                _hover={{
                  bg: "yellow.500",
                }}
                _focus={{
                  bg: "yellow.500",
                }}
                onClick={() => {
                  setOpenVerification(false);
                }}
              >
                Proceed to Verification
                </Button>
              </Link>
            </ModalFooter>
          </ModalContent>
        </Modal>
      {/* End Verification Modal */}
      <Flex
        position="sticky"
        top="0"
        zIndex="sticky"
        ml={{ base: 0, md: 60 }}
        px={{ base: 4, md: 4 }}
        height="20"
        alignItems="center"
        bg={useColorModeValue("white", "gray.900")}
        borderBottomWidth="1px"
        borderBottomColor={useColorModeValue("gray.200", "gray.700")}
        justifyContent={{ base: "space-between", md: "flex-end" }}
        {...rest}
      >
        <IconButton
          display={{ base: "flex", md: "none" }}
          onClick={()=>setToggleSidebar(!toggleSidebar)}
          variant="outline"
          aria-label="open menu"
          icon={<FiMenu />}
        />
        <MobileSideBar routes={routes} mOpen={toggleSidebar} />
        <Link to={'/'}>
          <Image
              alt={"Logo"}
              display={{ base: "flex", md: "none" }}
              align={"center"}
              src={useColorModeValue(Logo, WLogo)}
            />
        </Link>
        <HStack spacing={{ base: "0", md: "6" }}>
          {/* <IconButton
            size="lg"
            variant="ghost"
            aria-label="open menu"
            icon={<FiBell />}
          /> */}
          <ColorModeSwitcher />
          <Flex alignItems={"center"}>
            <Menu>
              <MenuButton
                py={2}
                transition="all 0.3s"
                _focus={{ boxShadow: "none" }}
              >
                <HStack>
                  <Avatar
                    size={"sm"}
                    src={
                      userData.user.avatar ?? "https://static.vecteezy.com/system/resources/previews/019/896/008/original/male-user-avatar-icon-in-flat-design-style-person-signs-illustration-png.png"
                    }
                  />
                  <VStack
                    display={{ base: "none", md: "flex" }}
                    alignItems="flex-start"
                    spacing="1px"
                    ml="2"
                  >
                    <Text fontSize="sm">{userData.user.fname ?? ''}</Text>
                    <Text fontSize="xs" color="gray.600">
                      {userData.user.user_level == 1 ? 'SapioArtisan' : userData.user.user_level == 0 ? '' : 'Admin' ?? ''}
                    </Text>
                  </VStack>
                  <Box display={{ base: "none", md: "flex" }}>
                    <FiChevronDown />
                  </Box>
                </HStack>
              </MenuButton>
              <MenuList
                bg={useColorModeValue("white", "gray.900")}
                borderColor={useColorModeValue("gray.200", "gray.700")}
              >
                <Link to={"settings"}><MenuItem>Profile</MenuItem></Link>
                {/* <Link to={""}><MenuItem>Settings</MenuItem></Link> */}
                <MenuDivider />
                <MenuItem onClick={()=>logout()}>Sign out</MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        </HStack>
      </Flex>
      {userData.user.verification_complete == 0 ? <Flex 
        position="sticky"
        top="20"
        zIndex="sticky"
        ml={{ base: 0, md: 60 }}
        px={{ base: 4, md: 4 }}
        height="10"
        alignItems="center"
        bg={"orange.200"}
        justifyContent={"center"}>
          <Flex >
            <Text fontSize="xs" color="red.600">
              Your account is in test mode. Make sure your bank details match your ID details. 
            </Text>
          <NavLink onClick={() => { setOpenVerification(true); } } to={""}>
            <Text fontSize="xs" decoration={'underline'} ml={2} color="gray.600">
                (Complete Account Verification)
            </Text>
          </NavLink>
          </Flex>
      </Flex> :  userData.user.verification_complete == 2 ? <Flex 
        position="sticky"
        top="20"
        zIndex="sticky"
        ml={{ base: 0, md: 60 }}
        px={{ base: 4, md: 4 }}
        height="10"
        alignItems="center"
        bg={"orange.200"}
        justifyContent={"center"}>
          <Flex >
            <Text fontSize="xs" color="red.600">
                Verification in progress... This may take up to 5 days
            </Text>
          </Flex>
      </Flex> : <></>}
      </>
    );
  };
  
  
  const ArtisanNavbar = () => {
    const { onOpen } = useDisclosure();
  
    return (
        <MobileNav onOpen={onOpen} />
    );
  };
  
  export default ArtisanNavbar;
  