import {
  Box,
  Container,
  Heading,
  Text,
  Stack,
  Highlight,
  Flex,
  Grid,
  StackDivider,
  useColorModeValue,
  Image,
  Button,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Textarea,
  VStack,
  Center,
} from "@chakra-ui/react";
import Navbar from "../components/navbar/navbar";
import Hero from "../components/hero/hero-section";
import HowitWorks from "../components/howitworks/how";
import Top from "../components/top/top";
import Footer from "../components/footer/footer";
import Testimonials from "../components/testimonials/testimonials";
import Standards from "../components/standards/standards";
import { BsPerson } from "react-icons/bs";
import { MdEmail } from 'react-icons/md';
import { FaWhatsapp } from "react-icons/fa";
import { Link } from "react-router-dom";

export default function Contact() {
  return (
    <Box textAlign="justify">
      <Navbar />
      <Box mt={{ md: 20 }}>
        <Stack spacing={0} align={"center"} mb={20}>

          <Box p={10} textAlign={'center'}>
          <Heading>Contact Us</Heading>
          {/* <Text mt={4}>Let's know what you think or help you with that issue as quickly as possible</Text> */}
            <Box mt={10} m={{ md: 10 }}>

            <Link to={"https://wa.me/+2349155212648"} target='_blank'>
              <Center>
                  <FaWhatsapp color='green' size={'86px'} />
              </Center>
                  <Text fontSize={14}>Talk to us on Whatsapp</Text>
            </Link>

            {/* <Box
            // width=""
                bg={useColorModeValue('white', 'gray.700')}
                borderRadius="lg"
                p={8}
                color={useColorModeValue('gray.700', 'whiteAlpha.900')}
                shadow="xl">
                <VStack spacing={5}>
                  <FormControl isRequired>
                    <FormLabel>Name</FormLabel>

                    <InputGroup>
                      <InputLeftElement>
                        <BsPerson />
                      </InputLeftElement>
                      <Input type="text" name="name" placeholder="Your Name" />
                    </InputGroup>
                  </FormControl>

                  <FormControl isRequired>
                    <FormLabel>Email</FormLabel>

                    <InputGroup>
                      <InputLeftElement>
                        <MdEmail />
                      </InputLeftElement>
                      <Input type="email" name="email" placeholder="Your Email" />
                    </InputGroup>
                  </FormControl>

                  <FormControl isRequired>
                    <FormLabel>Message</FormLabel>

                    <Textarea
                      name="message"
                      placeholder="Your Message"
                      rows={6}
                      resize="none"
                    />
                  </FormControl>

                  <Button
                    colorScheme="blue"
                    bg="yellow.600"
                    color="white"
                    _hover={{
                      bg: 'yellow.500',
                    }}
                    width="full">
                    Send Message
                  </Button>
                </VStack>
              </Box> */}

            </Box>
          </Box>
        </Stack>
        <HowitWorks />
        <Testimonials />
      </Box>
      <Footer />
    </Box>
  );
}
