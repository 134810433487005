import { Icon } from '@chakra-ui/react';
import { MdHome, MdMessage, MdNotifications } from 'react-icons/md';
import ArtisanDashboard from '../views/dashboard/dashboard';
import ArtisanExplore from '../views/dashboard/explore';
import ArtisanSettings from '../views/dashboard/settings';
import ArtisanWallet from '../views/dashboard/wallet';
import ArtisanJobs from '../views/dashboard/jobs';
import NewTask from '../views/dashboard/new-task';
import UserJobs from '../views/dashboard/my-jobs';
import Verification from '../views/dashboard/verification';
import Notifications from '../views/dashboard/notifications';
import Messages from '../views/dashboard/messages';
import { IoMdClock } from 'react-icons/io';
import TaskTimer from '../views/dashboard/timer';

const routes = [
	{
		name: 'Artisan Dashboard',
		layout: '/',
		path: '/',
		icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
		component: ArtisanDashboard
	},
	{
		name: 'Artisan Dashboard',
		layout: '/',
		path: '/dashboard',
		icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
		component: ArtisanDashboard
	},
	{
		name: 'Artisan Explore',
		layout: '/',
		path: '/explore',
		icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
		component: ArtisanExplore
	},
	{
		name: 'User Jobs',
		layout: '/',
		path: '/my-jobs',
		icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
		component: UserJobs
	},
	{
		name: 'Artisan Jobs',
		layout: '/',
		path: '/jobs',
		icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
		component: ArtisanJobs
	},
	{
		name: 'Verification',
		layout: '/',
		path: '/account-verification',
		icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
		component: Verification
	},
	{
		name: 'New Task',
		layout: '/',
		path: '/new-task',
		icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
		component: NewTask
	},
	{
		name: 'Artisan Wallet',
		layout: '/',
		path: '/wallet',
		icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
		component: ArtisanWallet
	},
	{
		name: 'Notifications',
		layout: '/',
		path: '/notifications',
		icon: <Icon as={MdNotifications} width='20px' height='20px' color='inherit' />,
		component: Notifications
	},
	{
		name: 'Messages',
		layout: '/',
		path: '/messages',
		icon: <Icon as={MdMessage} width='20px' height='20px' color='inherit' />,
		component: Messages
	},
	{
		name: 'Artisan Settings',
		layout: '/',
		path: '/settings',
		icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
		component: ArtisanSettings
	},
	{
		name: 'Task Timer',
		layout: '/',
		path: '/timer',
		icon: <Icon as={IoMdClock} width='20px' height='20px' color='inherit' />,
		component: TaskTimer
	}
];

export default routes;
