export default function authHeader() {
    const userStr = localStorage.getItem("user");
    let user = null;
    if (userStr)
      user = JSON.parse(userStr);
  
    if (user && user.token) {
      // console.log(user.token.token);
      return { "Authorization": 'Bearer ' + user.token.token, "Content-Type": 'application/json', "ngrok-skip-browser-warning": true }; // for Spring Boot back-end
      // return { 'x-access-token': user.accessToken };       // for Node.js Express back-end
    } else {
      return { "Authorization": '' , "Content-Type": 'application/json', "ngrok-skip-browser-warning": true}; // for Spring Boot back-end
      // return { 'x-access-token': null }; // for Node Express back-end
    }
}

export   function uploadHeader() {
  const userStr = localStorage.getItem("user");
  let user = null;
  if (userStr)
    user = JSON.parse(userStr);

  if (user && user.token) {
    // console.log(user.token.token);
    return { "Authorization": 'Bearer ' + user.token.token, "Content-Type": 'multipart/form-data', "ngrok-skip-browser-warning": true }; // for Spring Boot back-end
    // return { 'x-access-token': user.accessToken };       // for Node.js Express back-end
  } else {
    return { "Authorization": '' , "Content-Type": 'multipart/form-data', "ngrok-skip-browser-warning": true}; // for Spring Boot back-end
    // return { 'x-access-token': null }; // for Node Express back-end
  }
}