import { useEffect, useState } from 'react'
import {
  Progress,
  Box,
  ButtonGroup,
  Button,
  Heading,
  Flex,
  FormControl,
  GridItem,
  FormLabel,
  Input,
  Select,
  SimpleGrid,
  InputLeftAddon,
  InputGroup,
  Textarea,
  FormHelperText,
  InputRightElement,
  Container,
  useColorModeValue,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
} from '@chakra-ui/react'

import { useToast } from '@chakra-ui/react'
import Navbar from '../../components/navbar/navbar'
import Footer from '../../components/footer/footer'
import { getService, getServices } from '../../services/api.service'
import { createTask } from '../../services/auth.service'


export default function NewTask() {
  

  const cardBg = useColorModeValue("white", "gray.900");
  const inputBg = useColorModeValue("white", "gray.900");
  const inputColor = useColorModeValue("gray.900", "white");


  const toast = useToast()
  const [step, setStep] = useState(1)
  const [progress, setProgress] = useState(33.33)

  const [title, setTitle] = useState("");
  const [service, setService] = useState("");
  const [time, setTime] = useState("");
  const [date, setDate] = useState("");
  const [state, setState] = useState("");
  const [address, setAddress] = useState("");
  const [telephone, setTelephone] = useState("");
  const [brief, setBrief] = useState("");
  const [rate_p_hr, setHrRate] = useState("");
  const [total_rate, setRate] = useState("");
  const [approx_duration, setDuration] = useState("");
  const [newRate, setNewRate] = useState(0);

  const [serviceError, setServiceError] = useState("");
  const [dateError, setDateError] = useState("");
  const [stateError, setStateError] = useState("");
  const [addressError, setAddressError] = useState("");
  const [telephoneError, setTelephoneError] = useState("");
  const [briefError, setBriefError] = useState("");


const [successful, setSuccessful] = useState<boolean>(false);
const [message, setMessage] = useState<string>("");

const handleSubmit = () => {


  createTask(title,brief,date,time,service,telephone,state,address,rate_p_hr,approx_duration).then(
    (response) => {
      if(response.success){
      toast({
        title: 'Task Published!',
        description: `${response.message}`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      setSuccessful(response.success);
      // navigate('/artisan/dashboard');
          setTimeout(function () {
            window.location.href = "/my-jobs";
        }, 3000);
    }else{
      toast({
        title: 'Unable to Create Task',
        description: `${response.message}`,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      setSuccessful(response.success);
    }
    },
    (error) => {
      const resMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      setMessage(resMessage);
      setSuccessful(false);
    }
  );
};


const Form1 = () => {
  const [show, setShow] = useState(false)
  const handleClick = () => setShow(!show)

  const [services, setServices] = useState([]);

  useEffect(() => {
    getServices().then(
      (response) => {
        setServices(response.data['data']);
      },
      (error) => {
        const _services =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();

        setServices(_services);
      }
    );
  }, []);

  return (
    <>
      <Heading fontSize={'lg'} textAlign={'center'} fontWeight="bold" mb="10%">
        What do you need to get done?
      </Heading>
        <FormControl mb="10%">
          <FormLabel htmlFor="last-name" fontWeight={'normal'}>
            Select service required
          </FormLabel>
        <Select
                    value={service}
                    onChange={(event) =>{
                      setService(event.target.value);

                    getService(event.target.value).then(
                      (response) => {
                        // console.log('done');
                        setHrRate(response.data['data']['max_rate']);
                      },
                      (error) => {
                        const _services =
                          (error.response && error.response.data) ||
                          error.message ||
                          error.toString();
                      }
                    );
                    }}>
            <option disabled selected>--Select Task--</option>
           {services.map((service) => {
            return (
                    <option value={service['slug']}>{service['title']}</option>
            )})}
        </Select>
        </FormControl>

      <SimpleGrid columns={2} spacing={6}>
      <FormControl mb="10%">
      <FormLabel htmlFor="last-name" fontWeight={'normal'}>
          Date Needed?
      </FormLabel>
      <Input
          type="date"
          name="date"
          id="date"
          autoComplete="date"
          focusBorderColor="brand.400"
          shadow="sm"
          w="full"
          rounded="md"
          value={date}
          onChange={(event) => setDate(event.target.value)}
      />
      </FormControl>
      <FormControl mb="10%">
      <FormLabel htmlFor="last-name" fontWeight={'normal'}>
          Preferred Time
      </FormLabel>
      <Input
          type="time"
          name="time"
          id="time"
          autoComplete="time"
          focusBorderColor="brand.400"
          shadow="sm"
          w="full"
          rounded="md"
          value={time}
          onChange={(event) => setTime(event.target.value)}
      />
      </FormControl>
      </SimpleGrid>
    </>
  )
}

  return (
    <Box textAlign="center" backgroundImage="url('https://images.ctfassets.net/vwt5n1ljn95x/2knWEYZ5EsnAj7w4xPBTSZ/7407519ad8d4728688b41d4f24b40b14/IKEA_Assembly.jpg')" fontSize="xl"
    backgroundSize="cover"
  backgroundPosition="center"
    >
      <Navbar />
      <Box>
        <Container maxW={"7xl"} mt={10}>
          <Box m={{ md: 20}} mb={{ sm: 20}}>
            <Box maxW={"xl"} minH={"l"} mx={"auto"} mb={10} px={6} bgColor={cardBg} p={10} borderRadius={10}>

        <Progress colorScheme='yellow' hasStripe value={progress} mb="5%" isAnimated></Progress>
        {step === 1 ? <Form1 /> : step === 2 ? <>
        
        <Heading fontSize={'lg'} textAlign={'center'} fontWeight="bold" mb="5%">
          Task Brief
        </Heading>
        <FormControl as={GridItem} colSpan={[6, 3, null, 2]}>
          <FormLabel
            htmlFor="title"
            fontSize="sm"
            fontWeight="md"
            color="gray.700"
            _dark={{
              color: 'gray.50',
            }}>
            Title
          </FormLabel>
          <Input
            type="text"
            name="title"
            id="title"
            autoComplete="title"
            focusBorderColor="brand.400"
            shadow="sm"
            w="full"
            rounded="md"
            placeholder="Task Headline Title"
            mb={4}
            value={title}
            onChange={(event) => setTitle(event.target.value)}
          />
        </FormControl>
          <FormControl id="email" mt={1}>
            <FormLabel
              fontSize="sm"
              fontWeight="md"
              color="gray.700"
              _dark={{
                color: 'gray.50',
              }}>
              About
            </FormLabel>
            <Textarea
              placeholder="Write a brief..."
              rows={6}
              shadow="sm"
              focusBorderColor="brand.400"
              fontSize={{
                sm: 'sm',
              }}
              value={brief}
              onChange={(event) => setBrief(event.target.value)}
              mb={4}
            />
          </FormControl>
        <SimpleGrid columns={2} spacing={6}>
        <FormControl >
          <FormLabel
            htmlFor="duration"
            fontSize="sm"
            fontWeight="md"
            color="gray.700"
            _dark={{
              color: 'gray.50',
            }}>
            Approx. Task Duration (Min. 1hr)
          </FormLabel>
          <NumberInput defaultValue={1} min={1} max={100}
          name="duration"
          id="duration"
          focusBorderColor="brand.400"
          shadow="sm"
          w="full"
          rounded="md"
          mb={4}
          // value={approx_duration}
          onChange={(valueAsNumber) => {
            // setDuration(event.target.value);
            setRate((parseInt(valueAsNumber)*parseInt(rate_p_hr))?.toString());
          }}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </FormControl>
        <FormControl >
          <FormLabel
            htmlFor="rate"
            fontSize="sm"
            fontWeight="md"
            color="gray.700"
            _dark={{
              color: 'gray.50',
            }}>
            Rate
          </FormLabel>
          <Input
            type="text"
            name="rate"
            id="rate"
            autoComplete="rate"
            focusBorderColor="brand.400"
            shadow="sm"
            w="full"
            disabled
            rounded="md"
            placeholder="5000"
            mb={4}
            value={total_rate}
            // onChange={(event) => setRate(event.target.value)}
          />
        </FormControl>
        </SimpleGrid>
      </> : <>
      <Heading fontSize={'lg'} textAlign={'center'} fontWeight="bold" mb="10%">
        Your Details
      </Heading>
        <FormControl as={GridItem} colSpan={[6, 3]}>
          <FormLabel
            htmlFor="country"
            fontSize="sm"
            fontWeight="md"
            color="gray.700"
            _dark={{
              color: 'gray.50',
            }}>
            State
          </FormLabel>
          <Select
            id="country"
            name="country"
            autoComplete="country"
            placeholder="Select option"
            focusBorderColor="brand.400"
            shadow="sm"
            w="full"
            rounded="md"
            value={state}
            onChange={(event) => setState(event.target.value)}>
            <option disabled selected>--Select State--</option>
            <option value="Abia">Abia</option>
            <option value="Adamawa">Adamawa</option>
            <option value="Akwa Ibom">Akwa Ibom</option>
            <option value="Anambra">Anambra</option>
            <option value="Bauchi">Bauchi</option>
            <option value="Bayelsa">Bayelsa</option>
            <option value="Benue">Benue</option>
            <option value="Borno">Borno</option>
            <option value="Cross River">Cross River</option>
            <option value="Delta">Delta</option>
            <option value="Ebonyi">Ebonyi</option>
            <option value="Edo">Edo</option>
            <option value="Ekiti">Ekiti</option>
            <option value="Enugu">Enugu</option>
            <option value="FCT">Federal Capital Territory</option>
            <option value="Gombe">Gombe</option>
            <option value="Imo">Imo</option>
            <option value="Jigawa">Jigawa</option>
            <option value="Kaduna">Kaduna</option>
            <option value="Kano">Kano</option>
            <option value="Katsina">Katsina</option>
            <option value="Kebbi">Kebbi</option>
            <option value="Kogi">Kogi</option>
            <option value="Kwara">Kwara</option>
            <option value="Lagos">Lagos</option>
            <option value="Nasarawa">Nasarawa</option>
            <option value="Niger">Niger</option>
            <option value="Ogun">Ogun</option>
            <option value="Ondo">Ondo</option>
            <option value="Osun">Osun</option>
            <option value="Oyo">Oyo</option>
            <option value="Plateau">Plateau</option>
            <option value="Rivers">Rivers</option>
            <option value="Sokoto">Sokoto</option>
            <option value="Taraba">Taraba</option>
            <option value="Yobe">Yobe</option>
            <option value="Zamfara">Zamfara</option>
          </Select>
        </FormControl>
  
        <FormControl as={GridItem} colSpan={6}>
          <FormLabel
            htmlFor="street_address"
            fontSize="sm"
            fontWeight="md"
            color="gray.700"
            _dark={{
              color: 'gray.50',
            }}
            mt="2%">
            Address
          </FormLabel>
          <Input
            type="text"
            name="street_address"
            id="street_address"
            autoComplete="street-address"
            focusBorderColor="brand.400"
            shadow="sm"
            w="full"
            rounded="md"
            value={address}
            onChange={(event) => setAddress(event.target.value)}
          />
        </FormControl>
  
        <FormControl as={GridItem} colSpan={[6, 3, null, 2]}>
          <FormLabel
            htmlFor="state"
            fontSize="sm"
            fontWeight="md"
            color="gray.700"
            _dark={{
              color: 'gray.50',
            }}
            mt="2%">
            Contact
          </FormLabel>
          <Input
            type="text"
            name="state"
            id="state"
            autoComplete="state"
            focusBorderColor="brand.400"
            shadow="sm"
            w="full"
            rounded="md"
            value={telephone}
            onChange={(event) => setTelephone(event.target.value)}
          />
        </FormControl>
      </>}
        <ButtonGroup mt="5%" w="100%">
          <Flex w="100%" justifyContent="space-between">
            <Flex>
              <Button
                onClick={() => {
                  setStep(step - 1)
                  setProgress(progress - 33.33)
                }}
                isDisabled={step === 1}
                colorScheme="gray"
                variant="solid"
                w="7rem"
                mr="5%">
                Back
              </Button>
              <Button
                w="7rem"
                isDisabled={step === 3}
                display={{ base: step === 3 ? "none" : "block", md: "block" }}
                onClick={() => {
                  setStep(step + 1)
                  if (step === 3) {
                    setProgress(100)
                  } else {
                    setProgress(progress + 33.33)
                  }
                }}
                colorScheme="yellow"
                variant="outline">
                Next
              </Button>
            </Flex>
            {step === 3 ? (
              <Button
                w="7rem"
                bgColor={'yellow.600'}
                color={'white'}
                variant="solid"
                onClick={() => {
                  handleSubmit();
                }}>
                Submit
              </Button>
            ) : null}
          </Flex>
        </ButtonGroup>
      </Box>
      </Box>
      </Container>
      </Box>
      <Footer />
    </Box>
      )
}