import axios from "axios";
import authHeader from "./auth-header";
import { baseUrl } from "../utils/constants";

export const fetchUserData = () => {
  axios.get(baseUrl + "user",{ headers: authHeader() })
  .then((response) => {
    if (response.data.token) {
      localStorage.setItem("user", JSON.stringify(response.data));
    }
    return response.data;
  });
};

export const getServices = () => {
  return axios.get(baseUrl + "services",{ headers: authHeader() });
};

export const getUserJobs = () => {
  return axios.get(baseUrl + "my-tasks",{ headers: authHeader() });
};

export const getArtisanJobs = () => {
  return axios.get(baseUrl + "my-jobs",{ headers: authHeader() });
};


export const getArtisanExplore = () => {
  return axios.get(baseUrl + "explore",{ headers: authHeader() });
};

export const getNotifications = () => {
  return axios.get(baseUrl + "notifications",{ headers: authHeader() });
};

export const getMessages = () => {
  return axios.get(baseUrl + "messages",{ headers: authHeader() });
};

export const getTopArtisans = () => {
  return axios.get(baseUrl + "top-artisans", { headers: authHeader() });
};

export const getArtisans = () => {
  return axios.get(baseUrl + "artisans", { headers: authHeader() });
};

export const getArtisan = (id: string) => {
  return axios.get(baseUrl + "artisan/"+id, { headers: authHeader() })
  .then((response) => {
    return response.data;
  });
};

export const getArtisanDashboard = () => {
  return axios.get(baseUrl + "artisan-dashboard", { headers: authHeader() });
};

export const getUserDashboard = () => {
  return axios.get(baseUrl + "user-dashboard", { headers: authHeader() });
};

export const getTransactions = () => {
  return axios.get(baseUrl + "transactions", { headers: authHeader() });
};

export const getService = (service: string) => {
  return axios.get(baseUrl + `service/${service}`, { headers: authHeader() });
};


export const getUser = () => {
  return axios.get(baseUrl + "user", { headers: authHeader() });
};

export const getApplication = (id: string) => {
  return axios.get(baseUrl + `application/${id}`, { headers: authHeader() });
};

export const getReviews = (id: number) => {
  return axios.get(baseUrl + `reviews/${id}`,{ headers: authHeader() });
};

export const getDisputes = (id: number) => {
  return axios.get(baseUrl + `disputes/${id}`,{ headers: authHeader() });
};

export const getChatMessages = (id: number) => {
  return axios.get(baseUrl + `messages/${id}`,{ headers: authHeader() });
};