import Navbar from "../../components/navbar/navbar";
import { Spinner, useToast } from '@chakra-ui/react'
import Top from "../../components/top/top";
import Footer from "../../components/footer/footer";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  useColorModeValue,
  Container,
  FormErrorMessage,
  Text,
  Flex
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { url } from "inspector";
import { getCurrentUser, signinUser } from "../../services/auth.service";
import { Link } from "react-router-dom";


import bg from '../../assets/s-bg.jpg';

/**
 * Props for the SignIn component.
 * @interface SignInProps
 */
/**
 * Props for the SignIn component.
 */
interface SignInProps {
  /**
   * Function to be called when the form is submitted.
   * @param {string} email - The email entered by the user.
   * @param {string} password - The password entered by the user.
   * @returns {void}
   */
  onSubmit: (email: string, password: string) => void;
}

const Signin = () => {
  const toast = useToast();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const validateEmail = () => {
    if (!email) {
      setEmailError("Email is required");
      return false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError("Invalid email address");
      return false;
    } else {
      setEmailError("");
      return true;
    }
  };

  const validatePassword = () => {
    if (!password) {
      setPasswordError("Password is required");
      return false;
    } else {
      setPasswordError("");
      return true;
    }
  };

  const cardBg = useColorModeValue("white", "gray.900");
  const inputBg = useColorModeValue("white", "gray.900");
  const inputColor = useColorModeValue("gray.900", "white");


  const [successful, setSuccessful] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");

  const [loading, setLoading] = useState(false);
  const handleSignin = () => {
    setLoading(true);
    signinUser(email, password).then(
      (response) => {
        if(response.success){ 
          setLoading(false);
        toast({
          title: 'User Sign In',
          description: `${response.message}`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        setSuccessful(response.success);
        // navigate('/artisan/dashboard');
        setTimeout(function () {
          window.location.href = "/dashboard";
      }, 3000);
      }else{
        setLoading(false);
        toast({
          title: 'User Sign In',
          description: `${response.message}`,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        setSuccessful(response.success);
      }
      },
      (error) => {
        setLoading(false);
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setMessage(resMessage);
        setSuccessful(false);
      }
    );
  };


  return (
    <Box textAlign="center" backgroundImage={bg} fontSize="xl"
    backgroundSize="cover"
    backgroundPosition="center"
    >
      <Navbar />
      <Box>
        <Container maxW={"7xl"} mt={10}>
          <Box m={{ md: 20}} mb={{ sm: 20}}>
            <Box maxW={"md"} mx={"auto"} mb={10} px={6} bgColor={cardBg} p={10} borderRadius={10}>
            <Stack spacing={0} align={"center"} mb={10}>
              <Heading color={"yellow.600"}>Account Sign In</Heading>
            </Stack>
            
                <Stack spacing={4}>
                  <FormControl id="email" isInvalid={!!emailError}>
                    <FormLabel>Email address</FormLabel>
                    <Input
                      type="email"
                      value={email}
                      onChange={(event) => setEmail(event.target.value)}
                      bg={inputBg}
                      color={inputColor}
                      pattern="\S+@\S+\.\S+"
                    />
                    <FormErrorMessage>{emailError}</FormErrorMessage>
                  </FormControl>
                  <FormControl id="password" isInvalid={!!passwordError}>
                    <FormLabel>Password</FormLabel>
                    <Input
                      type="password"
                      value={password}
                      onChange={(event) => setPassword(event.target.value)}
                      bg={inputBg}
                      color={inputColor}
                    />
                    <FormErrorMessage>{passwordError}</FormErrorMessage>
                  </FormControl>
                  <Link
                  to={"/forgot-password"}
                    color="black.500"
                  >
                    <Text style={{fontSize: 16}}>Forgot password?</Text>
                  </Link>
                  <Button
                    type="submit"
                    mt={5}
                    mb={2}
                    fontSize={"sm"}
                    fontWeight={600}
                    color={"white"}
                    bg={"yellow.600"}
                    _hover={{
                      bg: "black",
                    }}
                    onClick={()=>{
                      if(!loading)
                      handleSignin()
                    }}
                    // onClick={()=>{navigate('/new-task');}}
                  >
                    { loading ? <Spinner color='white' /> :  'Submit' }
                  </Button>
                </Stack>
            </Box>
            <Flex style={{justifyContent: 'center', fontSize: 16}}>
                <Text>Don't have an account?</Text>
                <Link
                to={"/signup-user"}
                style={{marginLeft: 10, color:"brown", fontWeight: 'bold'}}
                >
                  Register now
                </Link>
            </Flex>
          </Box>
        </Container>
      </Box>
      <Footer />
    </Box>
  );
};

export default Signin;
