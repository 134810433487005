import {
  Box,
  Text,
  Heading,
  Stack,
  Card,
  Flex,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Progress,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  GridItem,
  Input,
  Select,
  SimpleGrid,
  Textarea,
  ButtonGroup,
  Container,
  useToast,
  Spinner,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
} from "@chakra-ui/react";
import { flexRender } from "@tanstack/react-table";
import { table } from "console";
import { useState, useEffect } from "react";
import { getService, getServices } from "../../services/api.service";
import { createTask } from "../../services/auth.service";
import { duration } from "moment";
import TimePicker from 'reactjs-timepicker'

const NewTask = () => {
  const cardBg = useColorModeValue("white", "gray.900");
  const inputBg = useColorModeValue("white", "gray.900");
  const inputColor = useColorModeValue("gray.900", "white");

  const toast = useToast();
  const [step, setStep] = useState(1);
  const [progress, setProgress] = useState(33.33);

  const [title, setTitle] = useState("");
  const [service, setService] = useState("");
  const [time, setTime] = useState("");
  const [date, setDate] = useState("");
  const [state, setState] = useState("");
  const [address, setAddress] = useState("");
  const [telephone, setTelephone] = useState("");
  const [brief, setBrief] = useState("");
  const [rate_p_hr, setHrRate] = useState("");
  const [total_rate, setRate] = useState("");
  const [approx_duration, setDuration] = useState("1");
  const [newRate, setNewRate] = useState(0);

  const [serviceError, setServiceError] = useState("");
  const [dateError, setDateError] = useState("");
  const [stateError, setStateError] = useState("");
  const [addressError, setAddressError] = useState("");
  const [telephoneError, setTelephoneError] = useState("");
  const [briefError, setBriefError] = useState("");

  const [successful, setSuccessful] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");

  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    setLoading(true);

    createTask(
      title,
      brief,
      date,
      time,
      service.split("/")[0],
      telephone,
      state,
      address,
      rate_p_hr,
      approx_duration
    ).then(
      (response) => {
        if (response.success) {
          toast({
            title: "Task Published!",
            description: `${response.message}`,
            status: "success",
            duration: 3000,
            isClosable: true,
          });

          setSuccessful(response.success);
          // navigate('/artisan/dashboard');
          setTimeout(function () {
            setLoading(false);
            window.location.href = "/my-jobs";
          }, 3000);
        } else {
          toast({
            title: "Unable to Create Task",
            description: `${response.message}`,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
          setSuccessful(response.success);
          setLoading(false);
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setMessage(resMessage);
        setLoading(false);
        setSuccessful(false);
      }
    );
  };

  const [show, setShow] = useState(false);
    const handleClick = () => setShow(!show);

    const [services, setServices] = useState([]);

    useEffect(() => {
      getServices().then(
        (response) => {
          setServices(response.data["data"]);
        },
        (error) => {
          const _services =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();

          setServices(_services);
        }
      );
    }, []);


  return (
    <Box>
      <Stack spacing={0} align={"center"} mb={10}>
        <Heading textAlign={"center"}>Create New Task</Heading>
        <Text align={"center"} mt={4}>
          Publish a new task and find a professional easily.
        </Text>
      </Stack>

      {/* <Box>
        <Container maxW={"7xl"} mt={10}>
          <Box m={{ md: 20 }} mb={{ sm: 20 }}> */}
            <Card
              maxW={"xl"}
              minH={"l"}
              mx={"auto"}
              mb={10}
              px={6}
              p={10}
              borderRadius={10}
            >
              <Progress
                colorScheme="yellow"
                hasStripe
                value={progress}
                mb="5%"
                isAnimated
              ></Progress>
              {step === 1 ? (
                <>
                <Heading
                  fontSize={"lg"}
                  textAlign={"center"}
                  fontWeight="bold"
                  mb="10%"
                >
                  What do you need to get done?
                </Heading>
                <FormControl mb="10%">
                  <FormLabel htmlFor="last-name" fontWeight={"normal"}>
                    Select service required
                  </FormLabel>
                  <Select
                    value={service}
                    onChange={(event) => {
                      event.preventDefault();
                      console.log(event.target.value);
                      setService(event.target.value);
                      setHrRate(event.target.value.split("/")[1]);
                      setRate(
                        (
                          parseInt(approx_duration) * parseInt(event.target.value.split("/")[1])
                        )?.toString()
                      );
        
                      // getService(event.target.value).then(
                      //   (response) => {
                      //     // console.log('done');
                      //     setHrRate(response.data["data"]["max_rate"]);
                      //     setRate(
                      //       (
                      //         parseInt(approx_duration) * parseInt(response.data["data"]["max_rate"])
                      //       )?.toString()
                      //     );
                      //   },
                      //   (error) => {
                      //     const _services =
                      //       (error.response && error.response.data) ||
                      //       error.message ||
                      //       error.toString();
                      //   }
                      // );
                    }}
                  >
                    <option disabled selected>
                      --Select Task--
                    </option>
                    <option value="">Choose Service</option>
                    {services.map((s) => {
                      return (
                        <option value={s["slug"]+"/"+s["max_rate"]}>{s["title"]}</option>
                      );
                    })}
                  </Select>
                </FormControl>
        
                <SimpleGrid templateColumns={{ sm: '1fr', md: '1fr 1fr' }} spacing={6}>
                  <FormControl mb="10%">
                    <FormLabel htmlFor="last-name" fontWeight={"normal"}>
                      Date Needed?
                    </FormLabel>
                    <Input
                      type="date"
                      name="date"
                      id="date"
                      min={new Date().toISOString().split('T')[0]}
                      focusBorderColor="brand.400"
                      shadow="sm"
                      w="full"
                      rounded="md"
                      value={date}
                      onChange={(event) => {
                        event.preventDefault();
                        setDate(event.target.value)
                      }}
                    />
                  </FormControl>
                  <FormControl mb="10%">
                    <FormLabel htmlFor="last-name" fontWeight={"normal"}>
                      Preferred Time
                    </FormLabel>
                    <Input
                      type="time"
                      name="time"
                      id="time"
                      focusBorderColor="brand.400"
                      shadow="sm"
                      w="full"
                      rounded="md"
                      placeholder="12:00"
                      value={time}
                      onChange={(event) => {
                        event.preventDefault();
                        setTime(event.target.value);
                      }}
                    />
                      {/* <TimePicker
                        defaultTime={'00:00'}
                        inputVisible={true}
                        color={'#ffffff'}
                        onChange={(time)=>setTime(time)}
                      /> */}
                  </FormControl>
                </SimpleGrid>
              </>
              ) : step === 2 ? (
                <>
                  <Heading
                    fontSize={"lg"}
                    textAlign={"center"}
                    fontWeight="bold"
                    mb="5%"
                  >
                    Task Details
                  </Heading>
                  <FormControl as={GridItem} colSpan={[6, 3, null, 2]}>
                    <FormLabel
                      htmlFor="title"
                      fontSize="sm"
                      fontWeight="md"
                      color="gray.700"
                      _dark={{
                        color: "gray.50",
                      }}
                    >
                      Title
                    </FormLabel>
                    <Input
                      type="text"
                      name="title"
                      id="title"
                      focusBorderColor="brand.400"
                      shadow="sm"
                      w="full"
                      rounded="md"
                      placeholder="Task Title"
                      mb={4}
                      value={title}
                      onChange={(event) => {
                        event.preventDefault();
                        setTitle(event.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl id="email" mt={1}>
                    <FormLabel
                      fontSize="sm"
                      fontWeight="md"
                      color="gray.700"
                      _dark={{
                        color: "gray.50",
                      }}
                    >
                    Task Description
                  </FormLabel>
                  <Textarea
                    placeholder="Write a brief or specific requirement here..."
                      rows={6}
                      shadow="sm"
                      focusBorderColor="brand.400"
                      fontSize={{
                        sm: "sm",
                      }}
                      value={brief}
                      onChange={(event) => {
                        event.preventDefault();
                        setBrief(event.target.value);
                      }}
                      mb={4}
                    />
                  </FormControl>
                  <SimpleGrid templateColumns={{ sm: '1fr', md: '1fr 1fr' }} spacing={6}>
                    <FormControl>
                      <FormLabel
                        htmlFor="duration"
                        fontSize="sm"
                        fontWeight="md"
                        color="gray.700"
                        _dark={{
                          color: "gray.50",
                        }}
                      >
                        Approx. Task Duration (Min. 1hr)
                      </FormLabel>
                      <NumberInput
                        defaultValue={1}
                        min={1}
                        max={100}
                        name="duration"
                        id="duration"
                        focusBorderColor="brand.400"
                        shadow="sm"
                        w="full"
                        rounded="md"
                        mb={4}
                        // value={approx_duration}
                        onChange={(valueAsNumber) => {
                          setDuration(valueAsNumber.toString());
                          setRate(
                            (
                              parseInt(valueAsNumber) * parseInt(rate_p_hr)
                            )?.toString()
                          );
                        }}
                      >
                        <NumberInputField />
                        <NumberInputStepper>
                          <NumberIncrementStepper />
                          <NumberDecrementStepper />
                        </NumberInputStepper>
                      </NumberInput>
                    </FormControl>
                    <FormControl>
                      <FormLabel
                        htmlFor="rate"
                        fontSize="sm"
                        fontWeight="md"
                        color="gray.700"
                        _dark={{
                          color: "gray.50",
                        }}
                      >
                        Rate (P/Hr)
                      </FormLabel>
                      <Input
                        type="text"
                        name="rate"
                        id="rate"
                        focusBorderColor="brand.400"
                        shadow="sm"
                        w="full"
                        disabled
                        rounded="md"
                        placeholder="5000"
                        mb={4}
                        value={total_rate}
                        // onChange={(event) => setRate(event.target.value)}
                      />
                    </FormControl>
                  </SimpleGrid>
                </>
              ) : (
                <>
                  <Heading
                    fontSize={"lg"}
                    textAlign={"center"}
                    fontWeight="bold"
                    mb="10%"
                  >
                    Your Details
                  </Heading>
                  <FormControl as={GridItem} colSpan={[6, 3]}>
                    <FormLabel
                      htmlFor="country"
                      fontSize="sm"
                      fontWeight="md"
                      color="gray.700"
                      _dark={{
                        color: "gray.50",
                      }}
                    >
                      State
                    </FormLabel>
                    <Select
                      id="country"
                      name="country"
                      placeholder="Select option"
                      focusBorderColor="brand.400"
                      shadow="sm"
                      w="full"
                      rounded="md"
                      value={state}
                      onChange={(event) => {
                        event.preventDefault();
                        setState(event.target.value);
                      }}
                    >
                      <option disabled selected>
                        --Select State--
                      </option>
                      <option value="Abia">Abia</option>
                      <option value="Adamawa">Adamawa</option>
                      <option value="Akwa Ibom">Akwa Ibom</option>
                      <option value="Anambra">Anambra</option>
                      <option value="Bauchi">Bauchi</option>
                      <option value="Bayelsa">Bayelsa</option>
                      <option value="Benue">Benue</option>
                      <option value="Borno">Borno</option>
                      <option value="Cross River">Cross River</option>
                      <option value="Delta">Delta</option>
                      <option value="Ebonyi">Ebonyi</option>
                      <option value="Edo">Edo</option>
                      <option value="Ekiti">Ekiti</option>
                      <option value="Enugu">Enugu</option>
                      <option value="FCT">Federal Capital Territory</option>
                      <option value="Gombe">Gombe</option>
                      <option value="Imo">Imo</option>
                      <option value="Jigawa">Jigawa</option>
                      <option value="Kaduna">Kaduna</option>
                      <option value="Kano">Kano</option>
                      <option value="Katsina">Katsina</option>
                      <option value="Kebbi">Kebbi</option>
                      <option value="Kogi">Kogi</option>
                      <option value="Kwara">Kwara</option>
                      <option value="Lagos">Lagos</option>
                      <option value="Nasarawa">Nasarawa</option>
                      <option value="Niger">Niger</option>
                      <option value="Ogun">Ogun</option>
                      <option value="Ondo">Ondo</option>
                      <option value="Osun">Osun</option>
                      <option value="Oyo">Oyo</option>
                      <option value="Plateau">Plateau</option>
                      <option value="Rivers">Rivers</option>
                      <option value="Sokoto">Sokoto</option>
                      <option value="Taraba">Taraba</option>
                      <option value="Yobe">Yobe</option>
                      <option value="Zamfara">Zamfara</option>
                    </Select>
                  </FormControl>

                  <FormControl as={GridItem} colSpan={6}>
                    <FormLabel
                      htmlFor="street_address"
                      fontSize="sm"
                      fontWeight="md"
                      color="gray.700"
                      _dark={{
                        color: "gray.50",
                      }}
                      mt="2%"
                    >
                      Address
                    </FormLabel>
                    <Input
                      type="text"
                      name="street_address"
                      id="street_address"
                      focusBorderColor="brand.400"
                      shadow="sm"
                      w="full"
                      rounded="md"
                      value={address}
                      onChange={(event) => {
                        event.preventDefault();
                        setAddress(event.target.value)
                      }}
                    />
                  </FormControl>

                  <FormControl as={GridItem} colSpan={[6, 3, null, 2]}>
                    <FormLabel
                      htmlFor="state"
                      fontSize="sm"
                      fontWeight="md"
                      color="gray.700"
                      _dark={{
                        color: "gray.50",
                      }}
                      mt="2%"
                    >
                      Phone Number
                    </FormLabel>
                    <Input
                      type="text"
                      name="state"
                      id="state"
                      focusBorderColor="brand.400"
                      shadow="sm"
                      w="full"
                      rounded="md"
                      value={telephone}
                      onChange={(event) => {
                        event.preventDefault();
                        setTelephone(event.target.value);
                      }}
                    />
                  </FormControl>
                </>
              )}
              <ButtonGroup mt="5%" w="100%">
                <Flex w="100%" justifyContent="space-between">
                  <Flex>
                    <Button
                      onClick={() => {
                        setStep(step - 1);
                        setProgress(progress - 33.33);
                      }}
                      isDisabled={step === 1}
                      colorScheme="gray"
                      variant="solid"
                      w="7rem"
                      mr="5%"
                    >
                      Back
                    </Button>
                    <Button
                      w="7rem"
                      isDisabled={step === 1 && service === "" || step === 1 && date === "" || step === 1 && time === "" || step === 2 && brief === "" || step === 3}
                      display={{
                        base: step === 3 ? "none" : "block",
                        md: "block",
                      }}
                      onClick={() => {
                        setStep(step + 1);
                        if (step === 3) {
                          setProgress(100);
                        } else {
                          setProgress(progress + 33.33);
                        }
                      }}
                      colorScheme="yellow"
                      variant="outline"
                    >
                      Next
                    </Button>
                  </Flex>
                  {step === 3 ? (
                    <Button
                      // w="7rem"
                      bgColor={"yellow.600"}
                      color={"white"}
                      variant="solid"
                      onClick={() => {
                        if (!loading) handleSubmit();
                      }}
                    >
                      {loading ? <Spinner color="white" /> : "Create Task"}
                    </Button>
                  ) : null}
                </Flex>
              </ButtonGroup>
            </Card>
          {/* </Box>
        </Container>
      </Box> */}
    </Box>
  );
};

export default NewTask;
