import * as React from "react"
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import {
  ChakraProvider,
  extendTheme,
} from "@chakra-ui/react"
import { AppRouter } from "./routes/AppRouter";

const theme = extendTheme({
  fonts: {
    body: "Poppins, sans-serif",
    // heading: "Poppins, sans-serif",
  },
});


export const App = () => (
  <ChakraProvider theme={theme}>
    <AppRouter />
  </ChakraProvider>
)
